import React, { useState } from "react";
import { BsFillTriangleFill } from "react-icons/bs";
import { FaSquare, FaCircle, FaStar } from "react-icons/fa";
import { BiSolidRectangle } from "react-icons/bi";

const element = {
  icon: {
    // rectangle: BiSolidRectangle,
    square: FaSquare,
    circle: FaCircle,
    triangle: BsFillTriangleFill,
    star: FaStar,
  },
};

const ListData = { element };
export default ListData;
