import React, { useState, useEffect } from "react";
import "./layout.css";
import { useUser } from "../config/userProvider";
import { IoHelp, IoPowerSharp, IoSettingsOutline } from "react-icons/io5";
import { RxHamburgerMenu } from "react-icons/rx";
import { removeCookie } from "../config/webStorage.js";
import { useNavigate, NavLink } from "react-router-dom";
import { BiError } from "react-icons/bi";

const Header = ({ className }) => {
  const { openProfile, setCloseProfile, userData, isMenuOpen, setIsMenuOpen } =
    useUser();
  const [openSearch, setOpenSearch] = useState(false);
  const navigate = useNavigate();
  const logOutHandler = () => {
    removeCookie("sctoken");
    window.location.reload();
  };
  const [message, setMessage] = useState({ type: "", message: "" });

  const verificationDetails = userData?.verificationDetails;
  useEffect(() => {
    if (userData?.userType === "admin" || userData?.userType === "user") {
      if (userData?.storeDetails?.storeObjId) {
        if (
          !verificationDetails?.isEmailVerified &&
          !verificationDetails?.isPhoneVerified
        ) {
          setMessage({
            type: "error",
            message: "Email & Phone Verification is pending...",
          });
        } else if (
          !verificationDetails?.isEmailVerified &&
          verificationDetails?.isPhoneVerified
        ) {
          setMessage({
            type: "error",
            message: "Email Verification is pending...",
          });
        } else if (
          verificationDetails?.isEmailVerified &&
          !verificationDetails?.isPhoneVerified
        ) {
          setMessage({
            type: "error",
            message: "Phone Verification is pending...",
          });
        }
      } else if (!userData?.storeDetails?.storeObjId) {
        setMessage({
          type: "error",
          message: "Please verify the store from the admin",
        });
      }
    }
  }, [userData]);

  const Verification =
    ((userData?.userType === "admin" || userData?.userType === "user") &&
      !userData?.storeDetails?.storeObjId) ||
    (userData?.storeDetails?.storeObjId &&
      verificationDetails?.isEmailVerified);

  console.log("userData", Verification);
  return (
    <div
      className={` px-3 py-2 flex items-center justify-between w-full border-b ${className}`}
    >
      <div className="py-2 md:hidden block cursor-pointer">
        <RxHamburgerMenu onClick={() => setIsMenuOpen(true)} />
      </div>
      <div className="flex items-end gap-2">
        <h1 className="text-xs md:text-sm lg:text-xl font-medium capitalize hidden md:block">
          Welcome Back, {userData?.name}
        </h1>
        <NavLink to="/dashboard">
          <img
            className="logoImage h-10 block sm:block md:hidden "
            src="/logo.png"
            alt="logo"
          />
        </NavLink>
        <div className="bg-blue-50 border-[1px] border-blue-200 rounded-md hidden md:block py-1 px-2 md:px-4 text-blue-500 text-xs">
          {userData?.userType}
        </div>
      </div>
      {message?.type === "error" && (
        <div className="bg-red-50 border border-red-300 text-red-600 px-5 rounded-md flex items-center gap-1">
          <span className="text-xl">
            <BiError />
          </span>
          {message?.message}
        </div>
      )}
      <div className="flex items-center gap-2 lg:gap-4 relative">
        <div
          className="bg-black rounded-full w-7 h-7  lg:w-9 lg:h-9 overflow-hidden cursor-pointer "
          onClick={() => setCloseProfile(!openProfile)}
        >
          <img src="https://gravatar.com/avatar/55b6e64a75f866cc007f4c7376c4368c?s=400&d=robohash&r=x" />
        </div>

        {openProfile && (
          <div className="absolute  shadow-sm right-0 top-[100%] bg-white/100  z-20 p-2 px-4 mt-2 rounded-md capitalize whitespace-pre">
            <NavLink to="/account">
              <div className="  flex items-center  gap-2 cursor-pointer border-b border-transparent  hover:border-zinc-100 py-1 ">
                <IoSettingsOutline />
                account & settings
              </div>
            </NavLink>
            {/* <div className="  flex items-center  gap-2 cursor-pointer border-b border-transparent  hover:border-zinc-50 py-1 ">
              <IoHelp />
              help
            </div> */}
            <div
              className="mt-3 text-red-500 flex items-center  gap-2 cursor-pointer"
              onClick={logOutHandler}
            >
              <IoPowerSharp />
              logout
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
