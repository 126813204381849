import React from "react";

const AddPopUp = ({ children, removePopUp, Background, ClassName, width }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Background overlay */}
      <div
        className="absolute inset-0 bg-black/50" // Increased opacity for better overlay
        onClick={removePopUp}
      ></div>

      {/* Popup container */}
      <div
        className={`relative z-60   h-[90%]  w-11/12 rounded-xl p-4 overflow-auto ${
          Background ? Background : "bg-white shadow"
        } ${ClassName}   ${width ? width : " md:max-w-[50rem]"}`} //apply "md" when change Width
      >
        {children}
      </div>
    </div>
  );
};

const Card = { AddPopUp };

export default Card;
