import React, { useState, useEffect } from "react";
import Layout, { Container } from '../components/layout';
import { Link } from 'react-router-dom';
import { IoMdArrowRoundBack } from "react-icons/io";
import { useParams } from "react-router-dom";
import axios from "axios";
import BackHeader from "../components/backHeader";
import moment from "moment";
import { apiurl } from "../config/config";

function OfferDetails() {
  const { id } = useParams();
  const [detailData, setDetailData] = useState();

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${apiurl}/admin/v1/offer/get/${id}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        if (response.data?.success === true) {
          setDetailData(response.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [showProductsPopup, setShowProductsPopup] = useState(false);

  const showProducts = () => {
    setShowProductsPopup((prevState) => !prevState);
  };

  console.log("detailData", detailData);

  const FormatDate = (date) => {
    return moment(date).format("MMMM Do YYYY");
  };

  return (
    <Layout>
      <Container>
        <div className="w-full h-full p-1 md:p-6 bg-gray-50 overflow-scroll">
          <div className="p-6  border-gray-300 shadow-sm rounded-md bg-white w-full mx-auto">
            {/* <div className="flex items-center justify-between mb-8 w-full">
              <div className="flex items-center">
                <Link to="/offers">
                  <div className="flex items-center justify-center border border-slate-300 rounded-md w-8 h-8 cursor-pointer">
                    <IoMdArrowRoundBack className="text-slate-500 text-lg" />
                  </div>
                </Link>
              </div>

              <div className="flex-1 text-center">
                <h1 className="text-xl font-bold text-gray-700"></h1>
              </div>
            </div> */}
            <BackHeader
              backButton={true}
              link="/offers"
              title="Offer Details"
              rightSide={
                <div className="flex justify-between">
                  <span className="text-green-600 font-semibold">
                    {detailData?.status}
                  </span>
                </div>
              }
            />

            <div className="grid grid-col-1 md:grid-cols-2 gap-x-8 gap-y-6 mt-10">
              <div className="flex justify-between">
                <span className="text-gray-500 font-semibold">
                  Offer Details:
                </span>
                <span className="text-gray-900">{detailData?.id}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-500 font-semibold">Offer Name:</span>
                <span className="text-gray-900 text-right">
                  {detailData?.name}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-500 font-semibold">Type:</span>
                <span className="text-gray-900">{detailData?.type}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-500 font-semibold">Value:</span>
                <span className="text-gray-900">{detailData?.value}</span>
              </div>

              {/* Column 2 */}
              {/* <div className="flex justify-between">
                <span className="text-gray-500 font-semibold">Created By:</span>
                <span className="text-gray-900">Blackberry</span>
              </div> */}
              <div className="flex justify-between">
                <span className="text-gray-500 font-semibold">Created At:</span>
                <span className="text-gray-900">
                  {FormatDate(detailData?.startDate)}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-500 font-semibold">Valid:</span>
                <span className="text-gray-900">
                  {FormatDate(detailData?.endDate)}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-500 font-semibold">
                  Description:
                </span>
                <span className="text-gray-900">{detailData?.description}</span>
              </div>
            </div>

            {/* Footer */}
            <div className="mt-8 flex justify-end">
              <button
                className="bg-zinc-900 hover:bg-zinc-700 text-white font-semibold py-2 px-6 rounded-md"
                onClick={showProducts}
              >
                {showProductsPopup ? "Don't Show" : "Show"} Products assign with
                this offer
              </button>
            </div>
          </div>
          {showProductsPopup && (
            <>
              <div className="overflow-x-auto sm:rounded-lg h-[590px] mt-4  border-4 border-white">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500  h-96">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50   sticky top-0">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        SR No.
                      </th>
                      <th scope="col" className="px-6 py-3 ">
                        Name
                      </th>
                      <th scope="col" className="px-6 py-3 ">
                        Status
                      </th>
                      <th scope="col" className="px-6 py-3 ">
                        Product_type
                      </th>
                      <th scope="col" className="px-6 py-3 ">
                        Description
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {detailData?.products.map((product, index) => (
                      <tr
                        key={index}
                        className={`${
                          index % 2 === 0 ? "odd:bg-white " : "even:bg-gray-50 "
                        } border-b `}
                      >
                        <th
                          scope="row"
                          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                        >
                          {index + 1}
                        </th>
                        <th
                          scope="row"
                          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                        >
                          {product.title}
                        </th>
                        <td className="px-6 py-4 capitalize">
                          {product.status === "active" ? (
                            <span className="text-green-600">
                              {product.status}
                            </span>
                          ) : product.status === "draft" ? (
                            <span className="text-yellow-600">
                              {product.status}
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                        <td className="px-6 py-4">{product.product_type}</td>
                        <td className="px-6 py-4">{product.handle}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </Container>
    </Layout>
  );
}

export default OfferDetails