import React, { useState, useRef, useEffect } from "react";
import "../components/layout.css";
import "./imageEditor.css";
import Layout, { Container } from "../components/layout";
import { IoCloseOutline } from "react-icons/io5";
import styled, { css } from "styled-components";
import { LuCopy } from "react-icons/lu";
import { GoPaste } from "react-icons/go";
import { MdOutlineSpaceDashboard, MdSpaceDashboard } from "react-icons/md";
import { TbIcons } from "react-icons/tb";
import { PiTextTBold } from "react-icons/pi";
import { LuPencilLine } from "react-icons/lu";
import container from "./imageeditor/container";
import ListData from "./imageeditor/listData";
import svg from "../components/svg";

const Input = ({ className, ...props }) => (
  <input
    className={`bg-white w-full p-1 rounded-lg outline-none text-base px-3 ${className} `}
    {...props}
  />
);
const Label = ({ children, title, ...props }) => (
  <label className="flex flex-col" {...props}>
    <span className="text-xs font-semibold">{title}</span>
    {children}
  </label>
);

const ImageEditor = () => {
  const [contrast, setContrast] = useState(100);
  const [brightness, setBrightness] = useState(100);
  const [scale, setScale] = useState(1);
  const [imageSrc, setImageSrc] = useState(null);
  const [frameSrc, setFrameSrc] = useState(null);
  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);
  const [selectedFrame, setSelectedFrame] = useState(null);
  const [canvasBorderSize, setCanvasBorderSize] = useState(0);
  const [canvasBorderColor, setCanvasBorderColor] = useState("black");
  const [canvasWidth, setCanvasWidth] = useState(0);
  const [canvasHeight, setCanvasHeight] = useState(0);
  const [imageX, setImageX] = useState(0);
  const [imageY, setImageY] = useState(0);
  const [canvasDragging, setCanvasDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [text, setText] = useState("");
  const [textColor, setTextColor] = useState("#000000");
  const [fontStyle, setFontStyle] = useState("Arial");
  const [fontSize, setFontSize] = useState(24);
  const [textX, setTextX] = useState(20);
  const [textY, setTextY] = useState(20);
  const [textDragging, setTextDragging] = useState(false);
  const [textStartX, setTextStartX] = useState(0);
  const [textStartY, setTextStartY] = useState(0);
  const [viewFrames, setviewFrames] = useState(false);

  const canvasRef = useRef(null);
  const frameRef = useRef(null);
  const textRef = useRef(null);

  let W_innerWidth = window.innerWidth;
  let H_innerHeight = window.innerHeight;

  const frameOptions = [
    { name: "Frame 1", url: "/frames/frame.png", category: "birthday" },
    { name: "Frame 2", url: "/frames/frame2.png", category: "anniversary" },
    { name: "Frame 3", url: "/frames/frame3.png", category: "birthday" },
    { name: "Frame 4", url: "/frames/frame4.jpg", category: "anniversary" },
    { name: "Frame 5", url: "/frames/frame5.png", category: "birthday" },
    { name: "Frame 6", url: "/frames/frame6.png", category: "all" },
  ];

  const fontStyles = ["Arial", "Verdana", "Times New Roman", "Georgia"];

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    if (imageSrc) {
      const img = new Image();
      img.src = imageSrc;

      img.onload = () => {
        // Determine canvas dimensions based on the fixed canvas size
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Calculate image dimensions and position for scaling and cropping
        const scaledWidth = img.width * scale;
        const scaledHeight = img.height * scale;

        let sourceX = 0;
        let sourceY = 0;
        let sourceWidth = img.width;
        let sourceHeight = img.height;

        // Determine cropping dimensions based on the scaled image
        if (scaledWidth < canvas.width) {
          sourceX = (img.width - canvas.width / scale) / 2;
          sourceWidth = canvas.width / scale;
          console.log(sourceWidth);
        }

        if (scaledHeight < canvas.height) {
          sourceY = (img.height - canvas.height / scale) / 2;
          sourceHeight = canvas.height / scale;
          console.log(sourceHeight);
        }

        // Apply brightness and contrast adjustments
        ctx.filter = `contrast(${contrast}%) brightness(${brightness}%)`;

        // Draw the image within the canvas dimensions
        ctx.drawImage(
          img,
          sourceX,
          sourceY,
          sourceWidth,
          sourceHeight,
          imageX,
          imageY,
          scaledWidth,
          scaledHeight
        );

        // Draw the frame (if available)
        if (frameSrc) {
          const frameImage = new Image();
          frameImage.src = frameSrc;
          ctx.drawImage(frameImage, 0, 0, canvas.width, canvas.height);
        }

        // Draw canvas border
        ctx.lineWidth = canvasBorderSize;
        ctx.strokeStyle = canvasBorderColor;
        ctx.strokeRect(0, 0, canvas.width, canvas.height);

        // Draw text overlay
        ctx.font = `${fontSize}px ${fontStyle}`;
        ctx.fillStyle = textColor;
        ctx.fillText(text, textX, textY);

        const newImageWidth = scaledWidth;
        const newImageHeight = scaledHeight;
        setImageWidth(newImageWidth);
        setCanvasWidth(newImageWidth);
        setCanvasHeight(newImageHeight);
        setImageHeight(newImageHeight);
      };
    }
  }, [
    contrast,
    brightness,
    scale,
    frameSrc,
    imageSrc,
    canvasBorderSize,
    canvasBorderColor,
    canvasWidth,
    canvasHeight,
    imageX,
    imageY,
    text,
    textColor,
    fontStyle,
    fontSize,
    textX,
    textY,
  ]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageSrc(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleContrastChange = (e) => {
    setContrast(e.target.value);
  };

  const handleBrightnessChange = (e) => {
    setBrightness(e.target.value);
  };

  const handleScaleChange = (e) => {
    setScale(e.target.value);
  };

  const handleDownload = () => {
    const canvas = canvasRef.current;
    const downloadLink = document.createElement("a");
    downloadLink.href = canvas.toDataURL("image/jpeg");
    downloadLink.download = "edited_image.jpeg";
    downloadLink.click();
  };

  const handleFrameChange = (e) => {
    const selectedFrameUrl = e.target.value;
    setSelectedFrame(selectedFrameUrl);
    setFrameSrc(selectedFrameUrl);
  };

  const handleCanvasWidthChange = (e) => {
    setCanvasWidth(Number(e.target.value));
  };

  const handleCanvasHeightChange = (e) => {
    setCanvasHeight(Number(e.target.value));
  };

  const handleCanvasBorderSizeChange = (e) => {
    setCanvasBorderSize(Number(e.target.value));
  };

  const handleCanvasBorderColorChange = (e) => {
    setCanvasBorderColor(e.target.value);
  };

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleTextColorChange = (e) => {
    setTextColor(e.target.value);
  };

  const handleFontStyleChange = (e) => {
    setFontStyle(e.target.value);
  };

  const handleFontSizeChange = (e) => {
    setFontSize(Number(e.target.value));
  };

  const handleCanvasMouseDown = (e) => {
    const mouseX = e.nativeEvent.offsetX;
    const mouseY = e.nativeEvent.offsetY;

    if (
      mouseX >= textX &&
      mouseX <= textX + textRef.current.width &&
      mouseY >= textY - fontSize &&
      mouseY <= textY
    ) {
      setTextDragging(true);
      setTextStartX(mouseX - textX);
      setTextStartY(mouseY - textY);
    } else {
      setCanvasDragging(true);
      setStartX(mouseX);
      setStartY(mouseY);
    }
  };

  const handleCanvasMouseMove = (e) => {
    const mouseX = e.nativeEvent.offsetX;
    const mouseY = e.nativeEvent.offsetY;

    if (canvasDragging) {
      setImageX(imageX + mouseX - startX);
      setImageY(imageY + mouseY - startY);
      setStartX(mouseX);
      setStartY(mouseY);
    }

    if (textDragging) {
      setTextX(mouseX - textStartX);
      setTextY(mouseY - textStartY);
    }
  };

  const handleCanvasMouseUp = () => {
    setCanvasDragging(false);
    setTextDragging(false);
  };

  // console.log("imageSrc", imageSrc);

  const selectedCategories = ["all", "birthday", "anniversary"];

  const [selectedCategory, setSelectedCategory] = useState(
    selectedCategories[0]
  );

  console.warn(selectedCategory);

  const handleCategoryChange = (item) => {
    setSelectedCategory(item);
  };
  console.warn("canvasBorderSize", canvasBorderSize);

  const [imageConfig, setimageConfig] = useState(false);
  const [selectFrameSection, setSelectFrameSection] = useState("all");
  const editorWidth = 100;
  const toolsWidth = 100 - editorWidth;

  // const icon = {
  //   design ={<TbLayoutDashboard />}
  // }

  // const [clicked, setClicked] = useState(false);

  // const [points, setPoints] = useState({
  //   x: 0,
  //   y: 0,
  // });
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const menuRef = useRef(null);

  const handleContextMenu = (event) => {
    event.preventDefault();
    // Set position directly at cursor coordinates
    setPosition({ x: event.clientX, y: event.clientY });
    setIsVisible(true);
  };

  // Hide menu when clicking outside
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const ContextMenu = styled.div`
    position: fixed;
    width: 250px;
    border-radius: 10px;
    box-sizing: border-box;
    z-index: 99;

    background-color: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    ${({ top, left }) => css`
      top: ${top}px;
      left: ${left}px;
    `}
  `;

  const MenuItem = ({ icon, text, shortcut }) => {
    return (
      <div className="flex items-center justify-between px-3 py-2 hover:bg-gray-100 rounded cursor-pointer">
        <div className="flex items-center">
          <span className="text-xl mr-3">{icon}</span>
          <span>{text}</span>
        </div>
        {shortcut && (
          <span className="text-gray-900 text-xs bg-gray-100 rounded-md px-3 py-1  ">
            {shortcut}
          </span>
        )}
      </div>
    );
  };

  const [currentMenuTab, setCurrentMenuTab] = useState("");

  return (
    <Layout>
      <Container onContextMenu={handleContextMenu} className="box-border">
        {isVisible && (
          <ContextMenu ref={menuRef} top={position.y} left={position.x}>
            <div className="w-full bg-white shadow-lg rounded-lg text-gray-800 p-2">
              <MenuItem icon={<LuCopy />} text="Copy" shortcut="Ctrl+C" />
              <MenuItem icon={<GoPaste />} text="Paste" shortcut="Ctrl+V" />
              <MenuItem icon="📝" text="Duplicate" shortcut="Ctrl+D" />
              <MenuItem icon="🗑️" text="Delete" shortcut="DELETE" />
              {/* <div className="border-t my-2"></div> */}
              {/* <MenuItem icon="⏱️" text="Show element timing" /> */}
              {/* <MenuItem icon="💬" text="Comment" shortcut="Ctrl+Alt+N" /> */}
              {/* <MenuItem icon="🔒" text="Lock" shortcut="Alt+Shift+L" /> */}
              {/* <MenuItem icon="🖼️" text="Alternative text" /> */}
              {/* <div className="border-t my-2"></div> */}
              {/* <MenuItem text="Set image as background" /> */}
              {/* <MenuItem text="Apply colors to page" /> */}
            </div>
          </ContextMenu>
        )}

        <div className="flex flex-row-reverse  relative w-full h-full  text-sm ">
          {/* <div className="flex flex-col gap-2 bg-green-500"></div> */}

          {/* -------------------------------- Float Menu's -------------------------------- */}

          <div className="flex text-xl items-center gap-4 bg-white shadow drop-shadow-lg p-2 px-3 rounded-md absolute top-0 left-1/2 -translate-x-1/2">
            <LuCopy /> <span className="bg-zinc-400 w-0.5 h-5 "></span>
            <GoPaste />
          </div>

          {imageSrc && (
            <div
              className={`flex flex-col gap-2 `}
              // style={{ width: `${toolsWidth}%` }}
            >
              <div className="bg-zinc-50 border rounded-lg flex flex-col gap-1 p-1.5 px-3 ">
                <div className="flex flex-col rounded-lg bg-zinc-50 p-2">
                  <div className="relative">
                    <label
                      for="labels-range-input"
                      className="border px-3  rounded-full bg-zinc-200 font-semibold"
                    >
                      Contrast:
                    </label>
                    <div className="relative mb-4">
                      <input
                        className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer  range-sm"
                        type="range"
                        min="0"
                        max="200"
                        id="Contrast"
                        value={contrast}
                        onChange={handleContrastChange}
                      />
                      <span class="text-sm text-gray-500  absolute start-0 -bottom-5">
                        0
                      </span>
                      <span class="text-sm text-gray-500  absolute start-1/2 -translate-x-1/2 rtl:translate-x-1/2 -bottom-5">
                        100
                      </span>
                      <span class="text-sm text-gray-500  absolute end-0 -bottom-5">
                        Max(200)
                      </span>
                    </div>
                  </div>

                  <div className="">
                    <label
                      htmlFor="Brightness"
                      className="border px-3  rounded-full bg-zinc-200 font-semibold"
                    >
                      Brightness
                    </label>
                    <div className="relative mb-4">
                      <input
                        className=" w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer  range-sm"
                        type="range"
                        min="0"
                        id="Brightness"
                        max="200"
                        value={brightness}
                        onChange={handleBrightnessChange}
                      />
                      <span class="text-sm text-gray-500  absolute start-0 -bottom-5">
                        0
                      </span>
                      <span class="text-sm text-gray-500  absolute start-1/2 -translate-x-1/2 rtl:translate-x-1/2 -bottom-5">
                        100
                      </span>
                      <span class="text-sm text-gray-500  absolute end-0 -bottom-5">
                        Max(200)
                      </span>
                    </div>
                  </div>
                  <div className="">
                    <label
                      htmlFor="Scale"
                      className="border px-3  rounded-full bg-zinc-200 font-semibold"
                    >
                      Scale:
                    </label>
                    <div className="relative mb-4">
                      <input
                        className=" w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer  range-sm"
                        type="range"
                        min="0.1"
                        max="2"
                        step="0.01"
                        id="Scale"
                        value={scale}
                        onChange={handleScaleChange}
                      />
                      <span class="text-sm text-gray-500  absolute start-0 -bottom-5">
                        0.1
                      </span>
                      <span class="text-sm text-gray-500  absolute start-1/2 -translate-x-1/2 rtl:translate-x-1/2 -bottom-5">
                        1
                      </span>
                      <span class="text-sm text-gray-500  absolute end-0 -bottom-5">
                        Max(2)
                      </span>
                    </div>
                  </div>

                  <div className="">
                    <p className="">Image Dimensions:</p>

                    <div className="flex justify-between">
                      <p className="m-0 caption col-6">
                        W: {Math.floor(imageWidth)}px
                      </p>
                      <p className="m-0 caption col-6">
                        H: {Math.floor(imageHeight)}px
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-1 rounded-xl h-full overflow-scroll border bg-zinc-50 p-1.5 px-3">
                <div
                  className={`p-2  rounded-lg cursor-pointer ${
                    viewFrames === true ? "bg-blue-100" : "bg-zinc-200"
                  } `}
                >
                  <div
                    className="flex justify-between "
                    onClick={() => setviewFrames(true)}
                  >
                    <p className="font-semibold ">Select Frame:</p>
                  </div>
                </div>
              </div>

              {/* image components */}
              {/* <div className="absolute h-full right-full top-0 mr-2 w-[20rem] z-20 shadow-sm rounded-xl overflow-hidden ">
                {viewFrames === true ? (
                  <div className="bg-zinc-50 p-2 flex flex-col h-full  w-full rounded">
                    <div className="flex justify-between">
                      <p className="font-semibold text-lg">Select Frame:</p>
                      <button
                        className="border px-2 rounded-md"
                        onClick={() => setviewFrames(false)}
                      >
                        <IoCloseOutline />
                      </button>
                    </div>

                    <div className="flex flex-col   ">
                      <div className=" w-full flex justify-between bg-zinc-50 pt-2  rounded-t-md overflow-hidden relative ">
                        {selectedCategories.map((item, index) => (
                          <button
                            key={index}
                            className={` px-3 py-1.5 rounded-t-xl  mx-2 relative z-10  ${
                              selectedCategory === item
                                ? "bg-white/100 border-b-transparent "
                                : "border-zinc-100"
                            } `}
                            style={{ borderWidth: 1 }}
                            onClick={() => handleCategoryChange(item)}
                          >
                            {item}
                          </button>
                        ))}
                      </div>
                    </div>
                    <div className="grid grid-cols-2 bg-white py-3 rounded-lg overflow-y-auto max-h-full">
                      {frameOptions
                        .filter(
                          (frame) =>
                            selectedCategory === "all" ||
                            frame.category === selectedCategory
                        )
                        .map((frame) => (
                          <label
                            title=""
                            key={frame.url}
                            className={`m-1 ${
                              selectedFrame === frame.url ? "active" : ""
                            }`}
                          >
                            <input
                              type="radio"
                              name="frame"
                              className="d-none"
                              value={frame.url}
                              checked={selectedFrame === frame.url}
                              onChange={handleFrameChange}
                            />
                          
                            <img
                              src={frame?.url}
                              alt=""
                              className="w-full shadow-sm bg-white rounded-md"
                            />
                          </label>
                        ))}
                    </div>
                  </div>
                ) : null}
              </div> */}
            </div>
          )}

          {/* -------------------------------- Editor Side MEnus-------------------------------- */}
          <div className="h-full absolute z-10 left-0 top-0 bottom-0">
            <div className="flex flex-col shadow drop-shadow-lg p-1 py-3 h-full bg-white  rounded-md gap-2 relative">
              <SelectMenu
                icon={
                  currentMenuTab === "frames" ? (
                    <MdSpaceDashboard />
                  ) : (
                    <MdOutlineSpaceDashboard />
                  )
                }
                title="Design"
                active={`${
                  currentMenuTab === "frames" ? "bg-zinc-500 text-white" : ""
                }`}
                onClick={() => setCurrentMenuTab("frames")}
              />
              <SelectMenu
                icon={<TbIcons />}
                title="Element"
                className=""
                active={currentMenuTab === "element"}
                onClick={() => setCurrentMenuTab("element")}
              />
              <SelectMenu
                icon={<PiTextTBold />}
                title="Text"
                className=""
                onClick={() => setCurrentMenuTab("text")}
              />
              <div className="relative">
                <SelectMenu
                  icon={<LuPencilLine />}
                  title="Draw"
                  className=""
                  onClick={() => setCurrentMenuTab("draw")}
                />
                {currentMenuTab === "draw" ? (
                  <div className=" clip-inset-custom w-20  absolute ml-3 left-full -translate-y-1/2  rounded-md shadow-lg bg-red-400  h-72">
                    <div className=" cursor-pointer absolute left-0 top-0 hover:-translate-x-2 -translate-x-8 transition-all duration-300">
                      <svg.pencil />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            {currentMenuTab === "frames" ? (
              <container.sideNavMenu
                title="Select Frame:"
                onClick={() => setCurrentMenuTab("")}
              >
                <div className="bg-white  p-2 flex flex-col h-full  w-full rounded">
                  {/* <div className="flex justify-between mb-2">
                    <p className="font-semibold text-lg"></p>
                    <button className="border px-2 rounded-md">
                      <IoCloseOutline />
                    </button>
                  </div> */}

                  <div className="flex flex-col   ">
                    <div className=" w-full flex justify-between bg-zinc-50 pt-2  rounded-t-md overflow-hidden relative ">
                      {selectedCategories.map((item, index) => (
                        <button
                          key={index}
                          className={` px-3 py-1.5 rounded-t-xl  mx-2 relative z-10  ${
                            selectedCategory === item
                              ? "bg-white/100 border-b-transparent "
                              : "border-zinc-100"
                          } `}
                          style={{ borderWidth: 1 }}
                          onClick={() => handleCategoryChange(item)}
                        >
                          {item}
                        </button>
                      ))}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 bg-white py-3 rounded-lg overflow-y-auto max-h-full">
                    {frameOptions
                      .filter(
                        (frame) =>
                          selectedCategory === "all" ||
                          frame.category === selectedCategory
                      )
                      .map((frame) => (
                        <label
                          title=""
                          key={frame.url}
                          className={`m-1 ${
                            selectedFrame === frame.url ? "active" : ""
                          }`}
                        >
                          <input
                            type="radio"
                            name="frame"
                            className="d-none"
                            value={frame.url}
                            checked={selectedFrame === frame.url}
                            onChange={handleFrameChange}
                          />
                          {/* {frame.name} */}
                          <img
                            src={frame?.url}
                            alt=""
                            className="w-full shadow-sm bg-white rounded-md"
                          />
                        </label>
                      ))}
                  </div>
                </div>
              </container.sideNavMenu>
            ) : null}

            {currentMenuTab === "element" ? (
              <container.sideNavMenu
                title="Elements"
                onClick={() => setCurrentMenuTab("")}
              >
                <div className="flex flex-wrap gap-3">
                  {Object.entries(ListData.element.icon).map(
                    ([iconName, Icon]) => (
                      <button
                        key={iconName}
                        className="text-5xl  text-slate-600"
                        // onClick={() => setSelectedIcon(iconName)}
                      >
                        <Icon />
                      </button>
                    )
                  )}
                </div>
              </container.sideNavMenu>
            ) : null}

            {/* {currentMenuTab === "draw" ? (
              <div className=" absolute left-full    shadow-lg bg-red-400 w-32 h-72"></div>
            ) : (
              ""
            )} */}
          </div>

          <div
            className=" flex justify-center items-center h-full  relative"
            style={imageSrc ? { width: `${editorWidth}%` } : { width: "100%" }}
          >
            {imageSrc && (
              <div className="h-full w-full   absolute top-0 left-0 right-0 bottom-0">
                <div className="absolute top-0 right-0 left-0 flex justify-between bottom-0 w-full h-full">
                  <div className="w-0.5 h-full bg-gradient-to-b from-transparent via-slate-400/0 to-transparent"></div>
                  <div className="w-0.5 h-full bg-gradient-to-b from-transparent via-slate-400/20 to-transparent"></div>
                  <div className="w-0.5 h-full bg-gradient-to-b from-transparent via-slate-400/20 to-transparent"></div>
                  <div className="w-0.5 h-full bg-gradient-to-b from-transparent via-slate-400/20 to-transparent"></div>
                  <div className="w-0.5 h-full bg-gradient-to-b from-transparent via-slate-400/20 to-transparent"></div>
                  <div className="w-0.5 h-full bg-gradient-to-b from-transparent via-slate-400/0 to-transparent"></div>
                </div>
                <div className="absolute top-0 right-0 left-0 flex flex-col justify-between  bottom-0 w-full h-full">
                  <div className="w-full h-0.5 bg-gradient-to-r from-transparent via-slate-400/0 to-transparent"></div>
                  <div className="w-full h-0.5 bg-gradient-to-r from-transparent via-slate-400/20 to-transparent"></div>
                  <div className="w-full h-0.5 bg-gradient-to-r from-transparent via-slate-400/20 to-transparent"></div>
                  <div className="w-full h-0.5 bg-gradient-to-r from-transparent via-slate-400/20 to-transparent"></div>
                  <div className="w-full h-0.5 bg-gradient-to-r from-transparent via-slate-400/20 to-transparent"></div>
                  <div className="w-full h-0.5 bg-gradient-to-r from-transparent via-slate-400/0 to-transparent"></div>
                </div>
              </div>
            )}
            {!imageSrc && (
              <div className="max-w-3xl mx-auto bg-slate-100 rounded-xl md:p-12">
                <div className="fileInput">
                  <label
                    htmlFor="file"
                    className="flex flex-col items-center justify-center cursor-pointer bg-slate-200 hover:bg-slate-300 transition-colors duration-200 py-16 px-8 rounded-lg border-4 border-dashed border-slate-400"
                  >
                    <p className="text-slate-600 mb-6 text-center text-xl">
                      Browse or Drag and Drop .jpg, .png files here
                    </p>
                    <button className="bg-slate-500 text-white px-8 py-3 rounded-lg hover:bg-slate-600 transition-colors duration-200 text-lg">
                      Browse File
                    </button>
                    <input
                      type="file"
                      id="file"
                      accept="image/*"
                      className="hidden"
                      onChange={handleImageUpload}
                    />
                  </label>
                </div>
              </div>
            )}

            <div
              className={`relative w-full h-full overflow-scroll ${
                !imageSrc ? "hidden" : "block"
              }`}
            >
              <canvas
                ref={canvasRef}
                onMouseDown={handleCanvasMouseDown}
                onMouseMove={handleCanvasMouseMove}
                onMouseUp={handleCanvasMouseUp}
                className="object-cover  absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]"
                style={{
                  width: `${imageWidth / 3}px`,
                  height: `${imageHeight / 3}px`,
                }}
              />

              <canvas
                ref={textRef}
                width={imageWidth}
                height={imageHeight}
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  pointerEvents: "none",
                }}
              />
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default ImageEditor;

const SelectMenu = ({
  icon,
  viewFrames,
  setViewFrames,
  className,
  onClick,
  title,
  active,
}) => (
  <div
    className={`p-2  flex flex-col justify-center items-center group text-zinc-500   border-2 border-transparent rounded-md  cursor-pointer ${className}`}
    onClick={onClick}
  >
    <span
      className={`text-2xl  p-1 rounded-md ${
        active
          ? "shadow drop-shadow-lg text-blue-500"
          : "group-hover:text-zinc-950 group-hover:border-zinc-300  group-hover:drop-shadow-lg"
      } `}
    >
      {icon}
    </span>
    <span className="text-xs ">{title}</span>
  </div>
);
