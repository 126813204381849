import React, { useState, useEffect } from 'react'
import Layout, { Container } from "../components/layout";
import { AiTwotoneEdit } from "react-icons/ai";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { Link, useNavigate } from 'react-router-dom';
import { FiSearch } from "react-icons/fi";
import ButtonContainer from "../components/buttonContainer";
import Input from "../components/inputContainer";
import { IoAddOutline } from "react-icons/io5";
import { useUser } from "../config/userProvider";
import { apiurl } from "../config/config";

import axios from "axios";

function Offers() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchInput, setSearchInput] = useState("");

  const { userData } = useUser();
  let vendorObjId = userData?.vendorDetails?.vendorObjId;

  useEffect(() => {
    let data = JSON.stringify({
      vendorObjId: vendorObjId,
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${apiurl}/admin/v1/offer/get`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setOffers(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchInput(value);
  };

  const offerDetails = (id) => {
    navigate(`/offers/${id}`);
  };

  const filterdata = offers.filter(
    (offer) =>
      offer.name.toLowerCase().includes(searchInput.toLowerCase()) ||
      offer.description.toLowerCase().includes(searchInput.toLowerCase())
  );

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString();
  };

  return (
    <Layout>
      <Container>
        <div className="h-full overflow-auto">
          <div className="border border-slate-200 md:rounded-xl h-full w-full overflow-auto px-5 py-3">
            <div className="flex flex-col md:flex-row justify-between items-center md:items-start my-4  w-full gap-2 md:gap-5">
              <div className="flex justify-between md:justify-start w-full md:w-[40%]">
                <h1 className="text-xl md:text-3xl font-medium text-left text-slate-500">
                  Offers
                </h1>
                <div className="block md:hidden">
                  <Link to="/offers/create">
                    <ButtonContainer icon={<IoAddOutline />} />
                  </Link>
                </div>
              </div>
              <div className="flex flex-row gap-4 w-full justify-between md:w-[40%]">
                <div className="hidden md:block">
                  <Link to="/offers/create">
                    <ButtonContainer icon={<IoAddOutline />}>
                      Create Offer
                    </ButtonContainer>
                  </Link>
                </div>

                <Input.search
                  placeholder={"Search Offer..."}
                  value={searchInput}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="flex flex-col relative">
              {isOpen && (
                <div className="absolute top-0 left-0 w-full h-full opacity-30 rounded-xl bg-black"></div>
              )}
              <div className="overflow-x-auto sm:rounded-lg h-[540px]">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 h-96">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 sticky top-0">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Name
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Description
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Type
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Value
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Start Date
                      </th>
                      <th scope="col" className="px-6 py-3">
                        End Date
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Created At
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {filterdata &&
                      filterdata.length > 0 &&
                      filterdata.map((offer) => (
                        <tr
                          key={offer._id}
                          className="odd:bg-white even:bg-gray-50 border-b cursor-pointer"
                          onClick={() => offerDetails(offer._id)}
                        >
                          <td className="px-6 py-4 font-medium text-gray-900">
                            {offer.name}
                          </td>
                          <td className="px-6 py-4">{offer.description}</td>
                          <td className="px-6 py-4 capitalize">
                            <div
                              className={
                                offer.type === "percentage"
                                  ? "text-green-600"
                                  : "text-blue-600"
                              }
                            >
                              {offer.type}
                            </div>
                          </td>
                          <td className="px-6 py-4">
                            {offer.value}
                            {offer.type === "percentage" ? "%" : " rs"}
                          </td>
                          <td className="px-6 py-4">
                            {formatDate(offer.startDate)}
                          </td>
                          <td className="px-6 py-4">
                            {formatDate(offer.endDate)}
                          </td>
                          <td className="px-6 py-4">
                            {formatDate(offer.createdAt)}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}

export default Offers;