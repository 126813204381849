import React from 'react'
import Layout from '../components/layout'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <>
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 text-center">
      <h1 className="text-6xl font-bold text-gray-800 mb-4">404</h1>
      <p className="text-xl text-gray-600 mb-8">Oops! The page you're looking for doesn't exist.</p>
      
      <Link
        to="/dashboard"
        className="inline-block bg-[#111111] text-white py-2 px-6 rounded-full hover:bg-gray-800 transition duration-200"
      >
        Go Back Home
      </Link>
    </div>
            
  </>
  )
}

export default NotFound