import React from "react";

const ComingSoon = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-white">
      <div className="bg-white flex justify-center align-middle">
        <img
          src="https://img.freepik.com/free-vector/abstract-grunge-style-coming-soon-with-black-splatter_1017-26690.jpg"
          className=""
        />
      </div>
    </div>
  );
};

export default ComingSoon;
