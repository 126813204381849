import { useState, useEffect } from "react";
import { useZxing } from "react-zxing";

const BarcodeScanner = ({
  barCodeResult,
  setBarCodeResult,
  setCombinedData,
  data,
}) => {
  const { ref } = useZxing({
    onDecodeResult(barCodeResult) {
      setBarCodeResult(barCodeResult.getText());
    },
  });
  // console.log(ref);

  useEffect(() => {
    if (barCodeResult) {
      const matchBarcode = data.find((item) => item?.barcode === barCodeResult);
      if (matchBarcode) {
        setCombinedData((prevData) => [...prevData, matchBarcode]);
        const timer = setTimeout(() => {
          setBarCodeResult("");
        }, 2000);
        return () => clearTimeout(timer); // Cleanup the timeout on unmount or when barCodeResult changes
      } else {
      }
    }
  }, [barCodeResult]);

  return (
    <>
      {/* <div
        className=" mt-[20%] border-white rounded-lg overflow-hidden p-5"
        style={{ borderWidth: 4 }}
      > */}
      <div className="rounded-lg overflow-hidden">
        <video ref={ref} height="200px" />
      </div>
      {/* </div> */}
      {/* {barCodeResult && (
        <p className=" bg-white w-10/12 p-2 absolute top-1/2 left-1/2 -translate-x-1/2  -transition-y-1/2">
          <div className="font-semibold text-xl shadow-md text-center">
            {barCodeResult}
          </div>
          <div className="font-semibold text-xl shadow-md text-center">
            {"["}
            {barcodeData} {"]"}
          </div>
        </p>
      )} */}
    </>
  );
};
export default BarcodeScanner;
