import logo from "./logo.svg";
import "./App.css";
import React, { useState, useEffect } from "react";
import Routers from "./routes";
import NoInternetConnection from "./components/noInternetConnection";
import { useUser } from "./config/userProvider";
import { PdfProvider } from "./pages/createCatalog/pdfContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InstallPWA from "./config/installPwa";

function App() {
  const { showInternetStatus } = useUser();

  return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>

    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
      />
      {/* <InstallPWA /> */}
      <PdfProvider>
        <NoInternetConnection />
        {showInternetStatus && (
          <div
            style={{ borderWidth: 1 }}
            className="z-50 absolute left-1/2 top-2 translate-x-[-50%] font-semibold p-1 px-4 rounded-md text-red-700 bg-red-100 border-red-600"
          >
            Network disconnect
          </div>
        )}
        <Routers />
      </PdfProvider>
    </React.Fragment>
  );
}

export default App;
