import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Storepopup from "../components/storepopup";
import Layout, { Container } from "../components/layout";
import { Bar, Doughnut, Line, Pie } from "react-chartjs-2";
import { apiurl } from "../config/config";
import { useUser } from "../config/userProvider";
import { getCookie } from "../config/webStorage";
import SegmentedDonutChart from "./segmentedDonutChart";
import SegmentedTrafficBar from "./segmentedTrafficBar";

function Analytics() {
  const {
    userData,
    storeData,
    getstoresLoading,
    setCloseProfile,
    permissions,
  } = useUser();
  const token = getCookie("bb_token");

  const [isPopupOpen, setPopupOpen] = useState(false);
  // const [pdfPopupOpen, setPdfPopupOpen] = useState(false);
  const [todayCount, setTodayCount] = useState("00");
  const [totalpdf, SetTotalPdf] = useState("00");
  const [selectedRange, setSelectedRange] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  console.log("select range : ", selectedRange)
  console.log("startDate : ", startDate)
  console.log("endDate : ", endDate)

  const handleRangeChange = (e) => {
    setSelectedRange(e.target.value);
    // Clear custom dates if another range is selected
    if (e.target.value !== "custom") {
      setStartDate("");
      setEndDate("");
    }
  };
  console.log("Today Generated PDF :", todayCount);
  console.log("Total Generated PDF :", totalpdf);

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const trafficData = {
    title: "Website Traffic",
    subtitle: "Unique visitors",
    totalVisitors: 26751,
    segments: [
      { label: "Direct", value: 14750, color: "#1f77b4" },
      { label: "Organic", value: 8000, color: "#2ca02c" },
      { label: "Referral", value: 2500, color: "#ff7f0e" },
      { label: "Social", value: 1500, color: "#d62728" },
    ],
  };

  useEffect(() => {
    const TodayCount = {
      method: "get",
      url: "https://blackberry-9ab311620e64.herokuapp.com/analytic/todaycount",
    };

    axios
      .request(TodayCount)
      .then((response) => {
        setTodayCount(response.data.todayCount);
      })
      .catch((error) => {
        console.log(error);
      });
    const TotalPdfGenerate = {
      method: "get",
      url: "https://blackberry-9ab311620e64.herokuapp.com/analytic/count",
    };

    axios
      .request(TotalPdfGenerate)
      .then((response) => {
        SetTotalPdf(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setLoadingData(true);
    if (userData?.userType === "admin") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${apiurl}/api/analytic/get-all-statistics`,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          if (response.data.success === true) {
            setLoadingData(false);
            setAnalyticsData(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoadingData(false);
        });
    } else if (userData?.userType === "user") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${apiurl}/api/analytic/get-pdf-statistics-by-storecode/M-MODTJLD2`,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          if (response.data.success === true) {
            setLoadingData(false);
            setAnalyticsData(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoadingData(false);
        });
    }
  }, []);

  const [AnalyticsData, setAnalyticsData] = useState("");
  const [loadingData, setLoadingData] = useState(false);

  // const PieData = {
  //   labels: ["Total Generated PDF", "Today Generated PDF"],
  //   datasets: [
  //     loadingData
  //       ? {
  //           label: "loading data",
  //           data: [45, 100],
  //           backgroundColor: ["rgb(255,225,225,1)", "rgb(225,225,225,0.3)"],
  //           hoverOffset: 2,
  //         }
  //       : {
  //           label: "pdf data",
  //           data: [
  //             // AnalyticsData?.activeUserCount,
  //             // AnalyticsData?.userCount,
  //             totalpdf,
  //             todayCount,
  //           ],
  //           backgroundColor: ["rgb(255, 99, 132)", "rgb(54, 162, 235)"],
  //           hoverOffset: 2,
  //         },
  //   ],
  // };

  const PieData = {
    labels: ["Active User", "Total Users"],
    datasets: [
      loadingData
        ? {
          label: "loading data",
          data: [
            45, 100,
            // dashboardData?,
          ],
          backgroundColor: ["rgb(255,225,225,1)", "rgb(225,225,225,0.3)"],
          hoverOffset: 2,
        }
        : {
          label: "user data",
          data: [
            AnalyticsData?.activeUserCount,
            AnalyticsData?.userCount,
            // dashboardData?,
          ],
          backgroundColor: ["rgb(255, 99, 132)", "rgb(54, 162, 235)"],
          hoverOffset: 2,
        },
    ],
  };
  const data = {
    labels: ["week 1", "week 2", "week 3", "week 4"],
    datasets: [
      {
        label: "First dataset",
        data:
          AnalyticsData && AnalyticsData?.weeklyData.map((item) => item.count),
        fill: true,
        tension: 0.2,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };
  const dummyGraph = {
    labels: ["week 1", "week 2", "week 3", "week 4"],

    datasets: [
      {
        label: "loading dataset",
        data: [33, 53, 85, 41],
        fill: true,
        tension: 0.2,
        backgroundColor: "rgba(235, 235, 235,0.2)",
        borderColor: "rgba(235, 235, 235,1)",
      },
    ],
  };

  return (
    <Layout>
      <Container>
        <div className="w-full h-full overflow-scroll bg-slate-50 rounded-xl px-4 py-4">
          {/* <div className="container position-relative">
          <div className="analyticSection">
            <div className="analyticOrder">
              <div className="analyticTotalOrder">
                <div className="analyticTotalOrderImg">
                  <img
                    className="totalorderImage"
                    src="/image/totalpdf.png"
                    alt=""
                  />
                </div>
                <div className="analyticTotalOrderText">
                  <h2 className="analyticTotalOrderHead">Total PDF Genrate</h2>
                  <h2 className="analyticTotalOrderSubhead">{totalpdf}</h2>
                </div>
              </div>
              <div className="analyticTotalOrder">
                <div className="analyticTotalOrderImg">
                  <img
                    className="totalorderImage"
                    src="/image/orderpdf.png"
                    alt=""
                  />
                </div>
                <div className="analyticTotalOrderText">
                  <h2 className="analyticTotalOrderHead">Today PDF Order</h2>
                  <h2 className="analyticTotalOrderSubhead">{todayCount}</h2>
                </div>
              </div>
              <div className="analyticTotalOrder cardShow" onClick={openPopup}>
                <div className="analyticTotalOrderImg">
                  <img
                    className="totalorderImage"
                    src="/image/viewpdf.png"
                    alt=""
                  />
                </div>
                <div className="analyticTotalOrderText ">
                  <h2 className="analyticTotalOrderHead">View PDF Genrate</h2>
                </div>
              </div>

              <Link
                to="/admin/storedatewise"
                className="text-decoration-none analyticTotalOrder cardShow text-dark"
              >
                <div className="analyticTotalOrderImg">
                  <img
                    className="totalorderImage"
                    src="/dashboardLogo/datepdf.png"
                    alt=""
                  />
                </div>
                <div className="analyticTotalOrderText ">
                  <h2 className="analyticTotalOrderHead">
                    Date Vise PDF Genrate
                  </h2>
                </div>
              </Link>
            </div>
          </div>
          {isPopupOpen === true ? (
            <>
              <div className="storeViewOverlay"></div>
              <div className="storeView">
                <Storepopup isOpen={isPopupOpen} onClose={closePopup} />
              </div>
            </>
          ) : null}
        </div> */}
          {/* <div className="flex justify-around">
          <div className="shadow-sm  rounded-lg h-auto lg:w-1/3 p-2   bg-white/100 ">
            <Doughnut data={PieData} />
          </div>
          <div>
            <div className="analyticTotalOrder cardShow" onClick={openPopup}>
              <div className="analyticTotalOrderImg">
                <img
                  className="totalorderImage"
                  src="/image/viewpdf.png"
                  alt=""
                />
              </div>
              <div className="analyticTotalOrderText ">
                <h2 className="analyticTotalOrderHead">View PDF Genrate</h2>
              </div>
            </div>
            <div>
              <Link
                to="/admin/storedatewise"
                className="text-decoration-none analyticTotalOrder cardShow text-dark"
              >
                <div className="analyticTotalOrderImg">
                  <img
                    className="totalorderImage"
                    src="/dashboardLogo/datepdf.png"
                    alt=""
                  />
                </div>
                <div className="analyticTotalOrderText ">
                  <h2 className="analyticTotalOrderHead">
                    Date Vise PDF Genrate
                  </h2>
                </div>
              </Link>
            </div>
          </div>
        </div> */}
          {/* <div className="flex w-full justify-end">

            <select
              className="px-4 py-2 rounded-md bg-white border-white mb-4"
              value={selectedRange}
              onChange={handleRangeChange}
            >
              <option value="">Select a Range</option>
              <option value="last_5_days">Last 5 Days</option>
              <option value="last_15_days">Last 15 Days</option>
              <option value="last_30_days">Last 30 Days</option>
              <option value="custom">Custom Date Range</option>
            </select>
          </div> */}

          <div className="flex flex-col-reverse md:flex-row gap-5 items-middle items-end w-full mb-5">
            <div>
              {selectedRange === "custom" && (
                <div className="flex flex-row items-center gap-4">
                  <div>
                    <label className="block text-xs md:text-base text-gray-700 mb-1">Start Date:</label>
                    <input
                      type="date"
                      className="px-1 py-1 text-sm md:text-base md:px-4 md:py-2 border rounded-md"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>

                  <div>
                    <label className="block text-sm md:text-base text-gray-700 mb-1">End Date:</label>
                    <input
                      type="date"
                      className="px-1 py-1 text-xs md:text-base md:px-4 md:py-2 border rounded-md"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                </div>
              )}
            </div>
            <div>
            <label className="block text-xs md:text-base text-gray-700 mb-1">Select Date Range:</label>

              <select
                className="px-4 py-2 text-xs md:text-base rounded-md bg-white border"
                value={selectedRange}
                onChange={handleRangeChange}
              >
                <option value="">Select a Range</option>
                <option value="last_5_days">Last 5 Days</option>
                <option value="last_15_days">Last 15 Days</option>
                <option value="last_30_days">Last 30 Days</option>
                <option value="custom">Custom Date Range</option>
              </select>
            </div>



          </div>

          <div className='flex w-full lg:flex-row flex-col gap-1 lg:gap-2 '>

            <div
              className={`shadow-sm  rounded-lg lg:w-1/3 ${permissions?.analytics === true && "lg:w-1/3"
                } w-full  bg-white/100  p-3`}
            >
              <Line
                data={loadingData ? dummyGraph : data}
                style={{ height: "", width: "" }}
              />
            </div>
            <div
              className={`shadow-sm  rounded-lg lg:w-1/3 ${permissions?.analytics === true && "lg:w-1/3"
                } w-full  bg-white/100  p-3`}
            >
              <Bar
                data={loadingData ? dummyGraph : data}
                style={{ height: "", width: "" }}
              />
            </div>
            {permissions?.analytics === true && (
              <div className='shadow-sm  rounded-lg h-auto lg:w-1/3 p-2   bg-white/100 '>
                {/* search */}
                {/* <div
                className="flex items-center py-1 px-2  rounded-full  bg-white/100  "
                onClick={() => setIsOpenSearch(true)}
                >
                <FiSearch />
                <input
                  type="text"
                  placeholder="search here..."
                  className="outline-none w-full px-2"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                </div> */}
                {/* cards-container */}
                {/* <div className="  pt-3 flex flex-col gap-2 bg-white/100">
                {getstoresLoading ? (
                  <div>fetching your data</div>
                ) : (
                  <div className="flex flex-col h-full overflow-hidden gap-2">
                    {storeData &&
                      storeData.map(
                        (item, index) =>
                          index < 5 && (
                            <div className=" bg-zinc-50 shadow-sm rounded-lg p-2 border">
                              <div className="flex gap-2 text-sm">
                                <p className="font-medium ">Store Name:</p>
                                <p>{item.storename}</p>
                              </div>
                              <div className="flex gap-2 text-sm">
                                <p className="font-medium ">Store Code:</p>
                                <p>{item.storecode}</p>
                              </div>
                            </div>
                          )
                      )}
                  </div>
                )}
                </div> */}
                {/* {storeData && (
                  <div className="flex justify-end">
                    <NavLink
                      to="/admin/viewstore"
                      className="bg-black text-white px-3 py-2 rounded-lg mt-3"
                    >
                      View store data
                    </NavLink>
                  </div>
                )} */}

                <Doughnut data={PieData} />
              </div>
            )}



            {/* <div className="shadow-sm my-2 rounded-lg lg:w-1/3 h-[30rem] w-full  bg-white/100 lg:hidden block overflow-hidden">
                
                <div className="flex items-center py-1 px-2  rounded-xl  w-full bg-white/100 border ">
                  <FiSearch />
                  <input
                    type="text"
                    placeholder="search here..."
                    className="outline-none w-full px-2  "
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                </div>
                
                <div className=" overflow-y-scroll h-4/5  flex flex-col p-2 gap-2 w-full">
                  {filteredStores.length > 0 && searchInput
                    ? filteredStores.map((item, index) => (
                        <div className=" bg-zinc-50 shadow-sm rounded-lg p-2 border w-full">
                          <div className="flex gap-2 text-sm">
                            <p className="font-medium ">Store Name:</p>
                            <p>{item.storename}</p>
                          </div>
                          <div className="flex gap-2 text-sm">
                            <p className="font-medium ">Store Code:</p>
                            <p>{item.storecode}</p>
                          </div>
                          <div className="flex gap-2 text-sm">
                            <p className="font-medium ">Address:</p>
                            <p>{item.Address}</p>
                          </div>
                        </div>
                      ))
                    : storeData &&
                      storeData.map(
                        (item, index) =>
                          index < 5 && (
                            <div className=" bg-zinc-50 shadow-sm rounded-lg p-2 border">
                              <div className="flex gap-2 text-sm">
                                <p className="font-medium ">Store Name:</p>
                                <p>{item.storename}</p>
                              </div>
                              <div className="flex gap-2 text-sm">
                                <p className="font-medium ">Store Code:</p>
                                <p>{item.storecode}</p>
                              </div>
                            </div>
                          )
                      )}
                </div>
                {storeData && (
                  <div className="flex justify-end">
                    <NavLink
                      to="/admin/viewstore"
                      className="bg-black text-white px-3 py-2 rounded-lg mt-3 lg:text-base text-xs m-2"
                    >
                      View store data
                    </NavLink>
                  </div>
                )}
              </div> */}
          </div>

          {/* <div className='flex w-full lg:flex-row flex-col gap-1 lg:gap-2 mt-5'>
            <SegmentedDonutChart
              title="Online Requests"
              totalRequests={9245}
              newRequests={5900}
              returningRequests={3100}
            />

            <SegmentedDonutChart
              title="In-Store Requests"
              totalRequests={4200}
              newRequests={2700}
              returningRequests={1500}
            />

            <SegmentedTrafficBar
              title={trafficData.title}
              subtitle={trafficData.subtitle}
              totalVisitors={trafficData.totalVisitors}
              segments={trafficData.segments}
            /> */}


          <div className="flex flex-col lg:flex-row w-full gap-4 mt-5">
            {/* Online Requests */}
            <div className={`shadow-sm  rounded-lg lg:w-1/3 w-full  bg-white/100  p-3`}>
              <SegmentedDonutChart
                title="Online Requests"
                totalRequests={9245}
                newRequests={5900}
                returningRequests={3100}
              />
            </div>

            {/* In-Store Requests */}
            <div className={`shadow-sm  rounded-lg lg:w-1/3 w-full  bg-white/100  p-3`}>
              <SegmentedDonutChart
                title="In-Store Requests"
                totalRequests={4200}
                newRequests={2700}
                returningRequests={1500}
              />
            </div>

            {/* Website Traffic */}
            <div className={`shadow-sm  rounded-lg lg:w-1/3 w-full  bg-white/100  p-3`}>
              <SegmentedTrafficBar
                title={trafficData.title}
                subtitle={trafficData.subtitle}
                totalVisitors={trafficData.totalVisitors}
                segments={trafficData.segments}
              />
            </div>
            {/* </div> */}




            {/* <div className='shadow-sm rounded-lg py-4 w-[100%] h-[50%] md:w-[50%] md:h-[70%] bg-white flex justify-center items-center'>
              <Doughnut data={PieData} />
            </div>
            <div className='flex flex-row w-[100%] md:w-[50%] gap-4 justify-between'>
              <div
                className='flex bg-slate-200 w-[50%] p-3 hover:bg-slate-300 justify-evenly rounded-md items-center'
                onClick={openPopup}
              >
                <div className=''>
                  <img
                    className='w-6 h-6 md:w-9 md:h-9 object-cover'
                    src='/image/viewpdf.png'
                    alt=''
                  />
                </div>
                <div className='text-center'>
                  <h2 className='text-xs md:text-xl font-semibold'>
                    View PDF Generate
                  </h2>
                </div>
              </div>
              <div className='bg-slate-200 w-[50%] p-3 hover:bg-slate-300 rounded-md '>
                <Link
                  to='/admin/storedatewise'
                  className='flex justify-evenly items-center'
                >
                  <div className=''>
                    <img
                      className='w-6 h-6 md:w-9 md:h-9 object-cover'
                      src='/dashboardLogo/datepdf.png'
                      alt=''
                    />
                  </div>
                  <div className='text-center'>
                    <h2 className='text-xs md:text-xl font-semibold '>
                      Date Wise PDF Generate
                    </h2>
                  </div>
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </Container>
    </Layout>
  );
}

export default Analytics;




