import React, { useEffect, useState } from "react";
import Layout, { Container } from "../components/layout";
import { AiTwotoneEdit } from "react-icons/ai";
import { RiDeleteBin6Fill } from "react-icons/ri";
import axios from "axios";
import { apiurl } from "../config/config";
import { useUser } from "../config/userProvider";
import { FiSearch } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { DynamicLoader } from "../components/loader";
import { IoMdAddCircle } from "react-icons/io";
import SearchContainer from "../components/searchContainer";
import ButtonContainer from "../components/buttonContainer";
import { IoAddOutline } from "react-icons/io5";
import BackHeader from "../components/backHeader";

function Teams() {
  // const [isOpen, setIsOpen] = useState(false);
  const { userData } = useUser();
  const [users, setUsers] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  console.log(users);

  const deletePopup = () => {
    setShowDeleteMemberPopup(!showDeleteMemberPopup);
  };
  const handleDelete = () => {
    setShowDeleteMemberPopup(false);
  };
  const [showDeleteMemberPopup, setShowDeleteMemberPopup] = useState(false);
  const [formdata, setFormdata] = useState({
    name: "",
    email: "",
    phone: "",
    role: "",
    stores: true,
    products: true,
    orders: true,
    settings: true,
    reports: true,
    users: true,
    analytics: true,
  });

  const handleAddMember = () => {
    navigate(`/teams/add`);
  };
  const handleEditMember = (id) => {
    navigate(`/teams/${id}`);
  };

  const Toggle = ({ name, checked, onChange }) => (
    <div className='flex justify-between'>
      <label className='mr-2 text-lg'>
        {name.charAt(0).toUpperCase() + name.slice(1)}
      </label>
      <div
        onClick={onChange}
        className={`w-12 h-6 flex items-center rounded-full p-1 cursor-pointer ${
          checked ? "bg-gray-500" : "bg-gray-300"
        }`}
      >
        <div
          className={`bg-white w-4 h-4 rounded-full shadow-md transform ${
            checked ? "translate-x-6" : ""
          } transition-transform`}
        />
      </div>
    </div>
  );

  const fetchUserData = async (vendorObjId) => {
    setLoading(true);
    let payload = JSON.stringify({
      vendorObjId: vendorObjId,
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${apiurl}/admin/v1/user/get`,
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Customer Data:", response.data.data);
        // setAppData(response.data.data);
        // setLoading(false);
        setUsers(response?.data?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (userData?.vendorDetails?.vendorObjId) {
      fetchUserData(userData.vendorDetails.vendorObjId);
    }
  }, []);

  const filterdata = users.filter(
    (user) =>
      user.name.toLowerCase().includes(searchInput.toLowerCase()) ||
      user.phone.toLowerCase().includes(searchInput.toLowerCase())
  );

  return (
    <Layout>
      <Container>
        {loading === true ? (
          <DynamicLoader maintext="wait" subtext="Fetching Teams Data" />
        ) : (
          // <div>
          //   <div className=" border border-2 border-slate-200 md:rounded-xl w-full px-5 py-3">
          //     <div className="flex justify-between my-4">
          //       <div>
          //         <h1 className="text-3xl font-medium text-center mb-3 text-slate-500">
          //           Our Team
          //         </h1>
          //       </div>
          //       <div>
          //         <div className='flex gap-5'>
          //           <div>
          //             <button
          //               className="bg-slate-500 hover:bg-slate-600 text-white font-semibold py-2 px-4 rounded-lg"
          //               // onClick={() => setShowAddMemberPopup(true)}
          //               onClick={handleAddMember}
          //             >
          //               Add Member
          //             </button>
          //           </div>
          //           <div className="flex items-center py-1 px-3 rounded-full bg-gray-50 border focus-within:border-gray-300 max-w-xl">
          //             <FiSearch className="text-gray-400 text-xl" />
          //             <input
          //               type="text"
          //               placeholder="search Team..."
          //               className="bg-transparent w-full focus:outline-none pr-4 font-semibold border-0 focus:ring-0 px-2"
          //               value={searchInput}
          //               onChange={(e) => setSearchInput(e.target.value)}
          //             />
          //             <button className="flex items-center justify-center min-w-[130px] px-4 rounded-full bg-slate-500 text-white font-medium border-transparent py-1.5 h-[38px] -mr-3">
          //               Search
          //             </button>
          //           </div>
          //         </div>
          //       </div>
          //     </div>
          //     <div className="flex flex-col relative">
          //       <div className="overflow-x-auto sm:rounded-lg h-[540px]">
          //         <table className="w-full text-sm text-left rtl:text-right text-gray-500  max-h-96">
          //           <thead className="text-xs text-gray-700 uppercase bg-gray-50   sticky top-0">
          //             <tr>
          //               <th scope="col" className="px-6 py-3">
          //                 Name
          //               </th>
          //               <th scope="col" className="px-6 py-3 ">
          //                 Email
          //               </th>
          //               <th scope="col" className="px-6 py-3 ">
          //                 Phone No.
          //               </th>
          //               <th scope="col" className="px-6 py-3 ">
          //                 user Type
          //               </th>
          //             </tr>
          //           </thead>

          //           <tbody>
          //             {users.map((user, index) => (
          //               <tr
          //                 key={index}
          //                 className={`${index % 2 === 0
          //                   ? "odd:bg-white "
          //                   : "even:bg-gray-50 "
          //                   } border-b cursor-pointer`}
          //                 onClick={() => handleEditMember(user._id)}
          //               >
          //                 <th
          //                   scope="row"
          //                   className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
          //                 >
          //                   {user.name}
          //                 </th>
          //                 <td className="px-6 py-4">{user.email}</td>
          //                 <td className="px-6 py-4">{user.phone}</td>
          //                 <td className="px-6 py-4">{user.userType}</td>
          //               </tr>
          //             ))}
          //           </tbody>
          //         </table>
          //       </div>
          //     </div>
          //   </div>

          // </div>

          <div className="border border-slate-200 rounded-xl h-full w-full px-5 py-3">
            {/* <div className="flex flex-col md:flex-row justify-between my-4">
              <div>
                <h1 className="text-3xl font-medium text-center mb-3 text-slate-500">
                  Our Team
                </h1>
              </div>
              <div className="flex flex-col md:flex-row gap-5 items-center">
                <button
                  className="bg-slate-500 hover:bg-slate-600 text-white font-semibold py-2 px-4 rounded-lg"
                  onClick={handleAddMember}
                >
                  Add Member
                </button>
                <div className="flex items-center py-1 px-3 rounded-full bg-gray-50 border focus-within:border-gray-300 max-w-xl w-full md:w-auto">
                  <FiSearch className="text-gray-400 text-xl" />
                  <input
                    type="text"
                    placeholder="Search Team..."
                    className="bg-transparent w-full focus:outline-none pr-4 font-semibold border-0 focus:ring-0 px-2"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                  <button className="flex items-center justify-center min-w-[130px] px-4 rounded-full bg-slate-500 text-white font-medium border-transparent py-1.5 h-[38px] -mr-3">
                    Search
                  </button>
                </div>
              </div>
            </div> */}
            <div className="flex flex-col md:flex-row justify-between items-center my-2 md:mx-2 md:my-2 md:p-2 gap-2 md:gap-0">
              <div className="flex justify-between w-full items-center">
                <BackHeader title="Our Team" />
                <div className="block md:hidden">
                  {/* <button
                    className="flex justify-center items-center px-1 py-1 md:py-2 md:px-4 bg-slate-500 text-white rounded-md"
                    onClick={handleAddMember}
                  >
                    <span className="text-xl"><IoMdAddCircle /></span>
                  </button> */}
                  <Link to="/teams/add">
                    <ButtonContainer icon={<IoAddOutline />}></ButtonContainer>
                  </Link>
                </div>
              </div>

              <div className="flex md:gap-2 items-center w-full md:w-[40%]">
                <div className="hidden md:block">
                  {/* <button
                    className="flex justify-center items-center px-2 md:px-4 md:py-1 bg-slate-500 text-white rounded-md"
                    onClick={handleAddMember}
                  >
                    <span className="text-xl pr-2"><IoMdAddCircle /></span>
                    <span className="hidden md:block">Add</span>
                  </button> */}
                  <Link to="/teams/add">
                    <ButtonContainer icon={<IoAddOutline />}>
                      Create
                    </ButtonContainer>
                  </Link>
                </div>
                <SearchContainer
                  value={searchInput}
                  placeholder={"Search Team..."}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-col relative">
              <div className="overflow-x-auto sm:rounded-lg h-[540px]">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 max-h-96">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 sticky top-0">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Name
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Email
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Phone No.
                      </th>
                      <th scope="col" className="px-6 py-3">
                        User Type
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {filterdata &&
                      filterdata.length > 0 &&
                      filterdata.map((user, index) => (
                        <tr
                          key={index}
                          className={`${
                            index % 2 === 0 ? "odd:bg-white" : "even:bg-gray-50"
                          } border-b cursor-pointer`}
                          onClick={() => handleEditMember(user._id)}
                        >
                          <th
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                          >
                            {user.name}
                          </th>
                          <td className="px-6 py-4">{user.email}</td>
                          <td className="px-6 py-4">{user.phone}</td>
                          <td className="px-6 py-4">{user.userType}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </Container>
    </Layout>
  );
}

export default Teams;
