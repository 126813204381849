import { IoCloseOutline } from "react-icons/io5";

const sideNavMenu = ({ children, title, onClick }) => {
  return (
    <div className="absolute p-3 bg-white h-full left-full top-0 ml-2 w-[20rem] z-20 shadow drop-shadow-lg rounded-xl overflow-hidden ">
      <div className="flex justify-between mb-2">
        <p className="font-semibold text-lg">{title}</p>
        <button className="border px-2 rounded-md" onClick={onClick}>
          <IoCloseOutline />
        </button>
      </div>

      {children}
    </div>
  );
};

// -----EXport the components
const container = { sideNavMenu };
export default container;
