import React, { useEffect, useState } from "react";
import Layout, { Container } from "../components/layout";
import { apiurl } from "../config/config";
import axios from "axios";
import { useUser } from "../config/userProvider";
import { ToastContainer, toast } from "react-toastify";
import { getCookie } from "../config/webStorage";
import "../App.css";
import { DynamicLoader } from "../components/loader";
import { Link } from "react-router-dom";
import ButtonContainer from "../components/buttonContainer";
import { IoAddOutline } from "react-icons/io5";
import { PiEyeThin } from "react-icons/pi";
import SearchContainer from "../components/searchContainer";

const Apps = () => {
  const { userData } = useUser();
  const [isOpen, setIsOpen] = useState(false);
  let sctoken = getCookie("sctoken");

  const [error, setError] = useState("");
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState("");
  const [appId, setAppId] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [showCreateAppPopup, setShowCreateAppPopup] = useState(false);
  const [showDeleteMemberPopup, setShowDeleteMemberPopup] = useState(false);
  const [showDisableConfirmationPopup, setShowDisableConfirmationPopup] =
    useState(false);
  const [appData, setAppData] = useState([]);
  const [formdata, setFormdata] = useState({
    appName: "",
    appDescription: "",
    stores: { read: false, write: false },
    products: { read: false, write: false },
    customers: { read: false, write: false },
    // orders: { read: false, write: false },
    // settings: { read: false, write: false },
    // reports: { read: false, write: false },
    // users: { read: false, write: false },
    // analytics: { read: false, write: false },
  });

  const dataToSend = {
    vendorObjId: userData?.vendorDetails?.vendorObjId,
    appName: formdata.appName,
    createdBy: userData?._id,
    permissions: {
      products: {
        read: formdata.products.read,
        write: formdata.products.write,
      },
      stores: {
        read: formdata.stores.read,
        write: formdata.stores.write,
      },
      customers: {
        read: formdata.customers.read,
        write: formdata.customers.write,
      },
    },
    discription: formdata.appDescription,
  };

  // console.log("DATA TO SEND", dataToSend)

  const [apps, setApps] = useState([
    {
      name: "App Name Example 1",
      description: "App Description Example 1",
      enabled: true,
    },
  ]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormdata({ ...formdata, [name]: value });
  };

  const handleToggleChange = (key, type) => {
    setFormdata((prevFormData) => ({
      ...prevFormData,
      [key]: {
        ...prevFormData[key],
        [type]: !prevFormData[key][type],
      },
    }));
  };

  const Toggle = ({ name, type, checked, onChange }) => {
    // Fallback to empty string if name or type is undefined
    const displayName = name
      ? name.charAt(0).toUpperCase() + name.slice(1)
      : "";
    const displayType = type
      ? type.charAt(0).toUpperCase() + type.slice(1)
      : "";

    return (
      <div className='flex justify-between'>
        <label className='mr-2 text-lg'>{`${displayType}`}</label>
        <div
          onClick={onChange}
          className={`w-12 h-6 flex items-center rounded-full p-1 cursor-pointer ${
            checked ? "bg-gray-500" : "bg-gray-300"
          }`}
        >
          <div
            className={`bg-white w-4 h-4 rounded-full shadow-md transform ${
              checked ? "translate-x-6" : ""
            } transition-transform`}
          />
        </div>
      </div>
    );
  };

  const toggleEnable = (index) => {
    setApps((prevApps) =>
      prevApps.map((app, i) =>
        i === index && app.enabled
          ? { ...app, enabled: !app.enabled } // Only toggle if `enabled` is true
          : app
      )
    );
  };

  // const Toggle = ({ name, type, checked, onChange }) => {
  //     const displayName = name ? name.charAt(0).toUpperCase() + name.slice(1) : '';
  //     const displayType = type ? type.charAt(0).toUpperCase() + type.slice(1) : '';

  //     return (
  //         <div className="flex justify-between">
  //             <label className="mr-2 text-lg">{`${displayType}`}</label>
  //             <div
  //                 onClick={() => {
  //                     if (checked) {
  //                         onChange();
  //                     }
  //                 }}
  //                 className={`w-12 h-6 flex items-center rounded-full p-1 cursor-pointer ${checked ? "bg-gray-500" : "bg-gray-300"}`}
  //             >
  //                 <div
  //                     className={`bg-white w-4 h-4 rounded-full shadow-md transform ${checked ? "translate-x-6" : ""} transition-transform`}
  //                 />
  //             </div>
  //         </div>
  //     );
  // };

  const fetchApplicationData = async (vendorObjId) => {
    setLoading(true);
    let payload = JSON.stringify({
      vendorObjId: vendorObjId,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${apiurl}/admin/v1/application/get`,
      headers: {
        Authorization: sctoken,
        "Content-Type": "application/json",
      },
      data: payload,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        setAppData(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const res = await axios.post(
        `${apiurl}/admin/v1/application/create`,
        dataToSend,
        {
          headers: {
            Authorization: sctoken,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("RESPONSE SECCESS",res.data.success)
      if (res?.data?.success === true) {
        console.log("RESPONSE MESSAGE IS:", res?.data?.message);
        setShowCreateAppPopup(false);
        toast.success("App created successfully");
        window.location.reload();
      } else {
        console.log("RESPONSE MESSAGE IS:", res?.data?.message);
        toast.error(`Failed to create app, ${res?.data?.message}`);
      }
      // setResponse(res.data);
    } catch (error) {
      setError(error.response ? error.response.data : "An error occurred");
      toast.error(`Failed to create app`);
    } finally {
      setLoading(false);
      // window.location.reload();
    }
  };

  useEffect(() => {
    if (userData?.vendorDetails?.vendorObjId) {
      fetchApplicationData(userData.vendorDetails.vendorObjId);
    }
  }, []);

  const filterdata =
    appData &&
    appData.length > 0 &&
    appData.filter((app) =>
      app.appName.toLowerCase().includes(searchInput.toLowerCase())
    );

  return (
    <Layout>
      <Container>
        {loading === true ? (
          <DynamicLoader maintext="Wait" subtext="Fetching Apps Data..." />
        ) : null}
        <div className="border border-slate-200 md:rounded-xl h-full w-full px-5 py-2">
          <div className="flex flex-col md:flex-row justify-between items-center my-2 md:mx-2 md:my-2 md:p-2 gap-2 md:gap-0">
            <div className="flex justify-between w-full items-center">
              <div>
                <h1 className="text-2xl md:text-3xl font-medium text-center md:mb-3 text-slate-500">
                  Apps
                </h1>
              </div>
              <div className="block md:hidden">
                <Link to="/apps/create">
                  <ButtonContainer icon={<IoAddOutline />}></ButtonContainer>
                </Link>
              </div>
            </div>
            <div className="flex md:gap-2 items-center w-full md:w-[40%]">
              <div className="hidden md:block">
                <Link to="/apps/create">
                  <ButtonContainer icon={<IoAddOutline />}>
                    Create App
                  </ButtonContainer>
                </Link>
              </div>
              <SearchContainer
                value={searchInput}
                placeholder={"Search App..."}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </div>
          </div>

          {/* <div className="flex flex-col relative py-4 h-[90%]  overflow-scroll  hide-scrollbar">
            <div className="sm:rounded-lg ">
              {appData.map((app, index) => (
                <div
                  key={index}
                  className="text-xs p-4 text-gray-700 border my-4 border-slate-300 rounded-lg bg-gray-50 w-full shadow-sm"
                >
                  <div className="flex justify-end mb-2">
                    <Link
                      to={`/apps/${app?._id}`}
                      className="px-4 py-2 text-sm bg-slate-500 text-white rounded-lg hover:bg-slate-700 transition ease-in-out duration-200"

                    >
                      View Details
                    </Link>
                  </div>
                  <div className="w-full border-t border-gray-200 py-4">
                    <div className="mb-2">
                      <div className="flex items-center mb-1">
                        <span className="w-1/3 text-gray-600 font-medium text-base">
                          App Name:
                        </span>
                        <span className="w-2/3 text-xl font-semibold text-gray-800">
                          {app?.appName}
                        </span>
                      </div>
                      <div className="flex items-center mb-1 hidden md:block">
                        <span className="w-1/3 text-gray-600 font-medium text-base">
                          App Description:
                        </span>
                        <span className="w-2/3 text-gray-700 text-base">
                          {app?.discription}
                        </span>
                      </div>
                      <div className="flex items-center">
                        <span className="w-1/3 text-gray-600 font-medium text-base">
                          Status:
                        </span>
                        <span className="w-2/3 text-base">
                          {app?.status === 'enabled' ? (
                            <span className="text-green-600 font-semibold">
                              Enabled
                            </span>
                          ) : (
                            <span className="text-red-600 font-semibold">
                              Disabled
                            </span>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div> */}
          <div className="flex flex-col relative py-4 h-[90%] overflow-scroll hide-scrollbar">
            <div className="sm:rounded-lg">
              {filterdata &&
                filterdata.length > 0 &&
                filterdata.map((app, index) => (
                  <div
                    key={index}
                    className="text-xs p-2 md:p-4 text-gray-700 my-4 rounded-lg bg-white w-full border border-zinc-50 shadow"
                  >
                    <div className="flex justify-end mb-2">
                      <Link to={`/apps/${app?._id}`}>
                        <ButtonContainer icon={<PiEyeThin />}>
                          <span className="hidden md:block">View Details</span>
                        </ButtonContainer>
                      </Link>
                    </div>
                    <div className="w-full border-t border-gray-200 py-1 md:py-4">
                      <div className="mb-2">
                        <div className="flex items-center mb-1">
                          <span className="flex-1 text-gray-600 font-medium text-base">
                            App Name:
                          </span>
                          <span className="flex-1 md:flex-[2] text-lg font-semibold text-gray-800">
                            {app?.appName}
                          </span>
                        </div>
                        <div className="flex items-center mb-1 hidden md:flex">
                          <span className="flex-1 text-gray-600 font-medium text-base">
                            App Description:
                          </span>
                          <span className="flex-1 md:flex-[2] text-gray-700 text-base">
                            {app?.discription}
                          </span>
                        </div>
                        <div className="flex items-center">
                          <span className="flex-1 text-gray-600 font-medium text-base">
                            Status:
                          </span>
                          <span className="flex-1 md:flex-[2] text-base">
                            {app?.status === "enabled" ? (
                              <span className="text-green-600 font-semibold">
                                Enabled
                              </span>
                            ) : (
                              <span className="text-red-600 font-semibold">
                                Disabled
                              </span>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* {showCreateAppPopup && (
          <>
            <div className="fixed top-0 left-0 w-full h-full opacity-30 bg-black"></div>
            <div
              className={`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg w-11/12 md:w-[40rem] pl-6 pr-6 pt-8 pb-8 md:pl-8 md:pr-8 md:pt-10 md:pb-10 text-center`}
              style={{ width: "50%" }}
            >
              <button
                className="text-3xl md:text-4xl absolute right-4 top-4"
                onClick={() => setShowCreateAppPopup(false)}
              >
                &times;
              </button>
              <h2 className="text-2xl font-bold mb-4 text-slate-500">
                Create App
              </h2>
              <div className=" flex w-[100%]">
                <div className="flex flex-col gap-4 justify-evenly p-2 bg-slate-100 border borde-2 border-slate-300 rounded-lg w-[100%]">
                  <div className="flex flex-col justify-between">
                    <div className="flex flex-col  w-[100%]">
                      <lable className="text-left font-semibold text-slate-600">
                        App Name:
                      </lable>
                      <input
                        name="appName"
                        value={formdata.appName}
                        placeholder="Enter Name"
                        className="w-[100%] border border-[1px] border-slate-200 focus:outline-none px-3 py-2 my-2 rounded-lg"
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="flex flex-col  w-[100%]">
                      <lable className="text-left font-semibold text-slate-600">
                        App Description:
                      </lable>
                      <input
                        name="appDescription"
                        value={formdata.appDescription}
                        placeholder="Enter Description"
                        className="w-[100%] border border-[1px] border-slate-200 focus:outline-none px-3 py-2 my-2 rounded-lg"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row justify-between">
                    <div className="flex flex-col w-[45%] p-2 border border-2 border-white rounded-lg">
                      <div className="flex flex-row w-[100%] justify-between items-center">
                        <div>
                          <p className="text-left font-semibold text-slate-600 text-lg">
                            stores :{" "}
                          </p>
                        </div>

                        <div>
                          <Toggle
                            name="stores"
                            type="read"
                            checked={formdata.stores.read}
                            onChange={() =>
                              handleToggleChange("stores", "read")
                            }
                          />
                        </div>
                        <div>
                          <Toggle
                            name="stores"
                            type="write"
                            checked={formdata.stores.write}
                            onChange={() =>
                              handleToggleChange("stores", "write")
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col w-[45%] p-2 border border-2 border-white rounded-lg">
                      <div className="flex flex-row w-[100%] justify-between">
                        <div>
                          <p className="text-left font-semibold  text-slate-600 text-lg">
                            products
                          </p>
                        </div>
                        <Toggle
                          name="products"
                          type="read"
                          checked={formdata.products.read}
                          onChange={() =>
                            handleToggleChange("products", "read")
                          }
                        />
                        <Toggle
                          name="products"
                          type="write"
                          checked={formdata.products.write}
                          onChange={() =>
                            handleToggleChange("products", "write")
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-between">
                    <div className="flex flex-col w-[45%] p-2 border border-2 border-white rounded-lg">
                      <div className="flex flex-row w-[100%] justify-between">
                        <div>
                          <p className="text-left font-semibold  text-slate-600 text-lg">
                            Customers
                          </p>
                        </div>
                        <Toggle
                          name="customers"
                          type="read"
                          checked={formdata.customers.read}
                          onChange={() =>
                            handleToggleChange("customers", "read")
                          }
                        />
                        <Toggle
                          name="customers"
                          type="write"
                          checked={formdata.customers.write}
                          onChange={() =>
                            handleToggleChange("customers", "write")
                          }
                        />
                      </div>
                    
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center">
             
                <button
                  disabled={loading}
                  type="button"
                  onClick={handleSubmit}
                  className={`text-white bg-slate-500 hover:bg-slate-800 mt-4 rounded-lg text-sm px-5 py-2 items-center `}
                >
                  {loading ? (
                    <>
                      <svg
                        aria-hidden="true"
                        role="status"
                        className="inline w-4 h-4 me-3 text-white animate-spin"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="#E5E7EB"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentColor"
                        />
                      </svg>
                      Creating...
                    </>
                  ) : (
                    "Create"
                  )}
                </button>
              </div>
            </div>
          </>
        )} */}
        {showDisableConfirmationPopup && (
          <>
            <div className="fixed top-0 left-0 w-full h-full opacity-30 bg-black"></div>
            <div
              className={`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg w-11/12 md:w-[40rem] pl-6 pr-6 pt-8 pb-8 md:pl-8 md:pr-8 md:pt-10 md:pb-10 text-center`}
              style={{ width: "50%" }}
            >
              <button
                className="text-3xl md:text-4xl absolute right-4 top-4"
                onClick={() => setShowDisableConfirmationPopup(false)}
              >
                &times;
              </button>
              <h2 className="text-2xl font-bold mb-4 text-slate-500">
                Create App
              </h2>
              <div className="flex w-[100%] justify-evenly"></div>
            </div>
          </>
        )}
      </Container>
    </Layout>
  );
};

export default Apps;
