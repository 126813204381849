import React, { useState, useEffect } from "react";
import Advanceloader from "../advanceloader.gif";
import axios from "axios";
import { apiurl } from "../config/config";
import Layout, { Container } from "../components/layout";
import { VariantSku } from "./createCatalog/index";
import { IoIosAdd } from "react-icons/io";
import { LuEye } from "react-icons/lu";
import { LuPenLine } from "react-icons/lu";
import { useUser } from "../config/userProvider";
import { Link, useNavigate } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import { DynamicLoader } from "../components/loader";
import { MdOutlineAddBox } from "react-icons/md";
import { IoMdAddCircle } from "react-icons/io";
import ButtonContainer from "../components/buttonContainer";
import { IoAddOutline } from "react-icons/io5";
import SearchContainer from "../components/searchContainer";
import { PopModel } from "../components/popupModels";

const Products = () => {
  const { userData } = useUser();
  const navigate = useNavigate();
  let vendorObjId = userData?.vendorDetails?.vendorObjId;
  console.log("userData", userData);
  console.log("vendorObjId 16 ", vendorObjId);

  const [loading, setLoading] = useState(false);
  const [syncStatus, setSyncStatus] = useState(null);
  const [data, setData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [showImportContainer, setShowImportContainer] = useState(false);

  const syncProductTarget = () => {
    setLoading(true);
    axios
      .get(`${apiurl}/sync`)
      .then((response) => {
        if (response.data.success === true) {
          setSyncStatus("success");
        } else {
          setSyncStatus("error");
        }
      })
      .catch((error) => {
        console.error("Error syncing products:", error);
        setSyncStatus("error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchProductsList = async () => {
    setLoading(true);
    let data = {
      vendorObjId: vendorObjId,
    };
    try {
      const response = await axios.post(
        `${apiurl}/admin/v1/product/get`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("response && response.data", response && response);
      if (response && response?.data) {
        setData(response?.data?.products);
      }
    } catch (error) {
      console.error("Error fetching user list:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProductsList();
  }, []);

  console.log("data", data);

  const filterdata =
    data &&
    data.filter((product) =>
      product.title.toLowerCase().includes(searchInput.toLowerCase())
    );
  return (
    <Layout>
      {loading === true ? (
        <DynamicLoader maintext='wait' subtext='Fetching Products Data' />
      ) : (
        <div className='border border-slate-200 sm:rounded-lg md:rounded-xl h-full w-full px-2 py-2'>
          <div className='bg-white relative flex flex-col gap-2 w-full overflow-hidden rounded-lg sm:rounded-xl px-2 h-full p-2'>
            <div className='flex flex-col md:flex-row justify-between items-center md:m-2 md:p-2 gap-2 md:gap-0'>
              <div className='flex justify-between w-[100%] items-center'>
                <div>
                  <h1 className='text-2xl md:text-3xl font-medium text-center md:mb-3 text-slate-500'>
                    Products
                  </h1>
                </div>
                <div className='flex gap-3 mx-3 items-center'>
                  <button
                    className='border text-xs  sm:text-base p-2 px-3 rounded-md border-zinc-900 hover:bg-zinc-900  hover:text-white'
                    onClick={() => setShowImportContainer(true)}
                  >
                    Import
                  </button>

                  <Link
                    to='/products/add'
                    className='flex justify-center  items-center px-2 md:px-4 md:py-2 border  border-zinc-900 hover:bg-zinc-900  hover:text-white rounded-md'
                  >
                    <button className='flex gap-1 items-center'>
                      <span className='text-xl p-1.5 sm:p-0'>
                        <IoAddOutline />
                      </span>
                      <span className='hidden md:block'>Add</span>
                    </button>
                  </Link>
                </div>
              </div>
              <div className='flex md:gap-2 items-center w-[100%] md:w-[40%]'>
                <SearchContainer
                  placeholder={"Search Products..."}
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </div>
            </div>
            <div className='relative overflow-y-auto h-full sm:rounded-lg'>
              <table className='w-full text-sm text-left text-gray-500'>
                <thead className='text-xs text-gray-700 uppercase bg-gray-50 sticky top-0'>
                  <tr>
                    <th scope='col' className='pl-4 sm:pl-6 py-3'>
                      S. No.
                    </th>
                    <th scope='col' className='pr-3'>
                      Image
                    </th>
                    <th scope='col' className='pl-4 sm:pl-6 py-3'>
                      Title
                    </th>
                    <th scope='col' className='pl-4 sm:pl-6 py-3'>
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filterdata &&
                    filterdata.length > 0 &&
                    filterdata.map((item, index) => (
                      <tr
                        key={item.id}
                        className={`${
                          index % 2 === 0 ? "bg-white" : "bg-gray-50"
                        } border-b cursor-pointer h-10`}
                        onClick={() => navigate(`/products/${item?._id}`)}
                      >
                        <th
                          scope='row'
                          className='pl-4 sm:pl-6 py-4 font-medium text-gray-900 whitespace-nowrap'
                        >
                          {index + 1}
                        </th>
                        <td className='py-2'>
                          <label
                            htmlFor={`${item.id}`}
                            className='cursor-pointer'
                          >
                            <img
                              src={
                                item.image ??
                                "https://blackberrys.com/cdn/shop/files/logo_155x52.png"
                              }
                              alt=''
                              className='h-11 w-11 object-cover rounded'
                            />
                          </label>
                        </td>
                        <td className='pl-4 sm:pl-6 py-4'>
                          <label
                            htmlFor={`${item.id}`}
                            className='cursor-pointer'
                          >
                            {item.title}
                          </label>
                        </td>
                        <td className='pl-4 sm:pl-6 py-4'>{`${item.status}`}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {showImportContainer && (
        <PopModel setShowImportContainer={setShowImportContainer}></PopModel>
      )}
    </Layout>
  );
};

export default Products;
