import React, { useEffect, useState } from "react";
import Layout, { Container } from "../components/layout";
import { apiurl } from "../config/config";
import axios from "axios";
import { useUser } from "../config/userProvider";
import { ToastContainer, toast } from "react-toastify";
import { getCookie } from "../config/webStorage";
import "../App.css";
import { DynamicLoader } from "../components/loader";
import { Link, useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { BlackButton } from "../components/buttonContainer.js";
import BackHeader from "../components/backHeader";

function CreateApp() {
  const navigate = useNavigate();
  const { userData } = useUser();
  const [isOpen, setIsOpen] = useState(false);
  let sctoken = getCookie("sctoken");

  const [error, setError] = useState("");
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState("");
  const [appId, setAppId] = useState("");
  const [showCreateAppPopup, setShowCreateAppPopup] = useState(false);
  const [showDeleteMemberPopup, setShowDeleteMemberPopup] = useState(false);
  const [showDisableConfirmationPopup, setShowDisableConfirmationPopup] =
    useState(false);
  const [appData, setAppData] = useState([]);
  const [formdata, setFormdata] = useState({
    appName: "",
    appDescription: "",
    stores: { read: false, write: false },
    products: { read: false, write: false },
    customers: { read: false, write: false },
    // orders: { read: false, write: false },
    // settings: { read: false, write: false },
    // reports: { read: false, write: false },
    // users: { read: false, write: false },
    // analytics: { read: false, write: false },
  });

  const dataToSend = {
    vendorObjId: userData?.vendorDetails?.vendorObjId,
    appName: formdata.appName,
    createdBy: userData?._id,
    permissions: {
      products: {
        read: formdata.products.read,
        write: formdata.products.write,
      },
      stores: {
        read: formdata.stores.read,
        write: formdata.stores.write,
      },
      customers: {
        read: formdata.customers.read,
        write: formdata.customers.write,
      },
    },
    discription: formdata.appDescription,
  };

  // console.log("DATA TO SEND", dataToSend)

  const [apps, setApps] = useState([
    {
      name: "App Name Example 1",
      description: "App Description Example 1",
      enabled: true,
    },
  ]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormdata({ ...formdata, [name]: value });
  };

  const handleToggleChange = (key, type) => {
    setFormdata((prevFormData) => ({
      ...prevFormData,
      [key]: {
        ...prevFormData[key],
        [type]: !prevFormData[key][type],
      },
    }));
  };

  const Toggle = ({ name, type, checked, onChange }) => {
    // Fallback to empty string if name or type is undefined
    const displayName = name
      ? name.charAt(0).toUpperCase() + name.slice(1)
      : "";
    const displayType = type
      ? type.charAt(0).toUpperCase() + type.slice(1)
      : "";

    return (
      <div className="flex justify-between">
        <label className="mr-2 ">{`${displayType}`}</label>
        <div
          onClick={onChange}
          className={`w-12 h-6 flex items-center rounded-full p-1 cursor-pointer ${
            checked ? "bg-gray-500" : "bg-gray-300"
          }`}
        >
          <div
            className={`bg-white w-4 h-4 rounded-full shadow-md transform ${
              checked ? "translate-x-6" : ""
            } transition-transform`}
          />
        </div>
      </div>
    );
  };

  const toggleEnable = (index) => {
    setApps((prevApps) =>
      prevApps.map((app, i) =>
        i === index && app.enabled
          ? { ...app, enabled: !app.enabled } // Only toggle if `enabled` is true
          : app
      )
    );
  };

  // const Toggle = ({ name, type, checked, onChange }) => {
  //     const displayName = name ? name.charAt(0).toUpperCase() + name.slice(1) : '';
  //     const displayType = type ? type.charAt(0).toUpperCase() + type.slice(1) : '';

  //     return (
  //         <div className="flex justify-between">
  //             <label className="mr-2 ">{`${displayType}`}</label>
  //             <div
  //                 onClick={() => {
  //                     if (checked) {
  //                         onChange();
  //                     }
  //                 }}
  //                 className={`w-12 h-6 flex items-center rounded-full p-1 cursor-pointer ${checked ? "bg-gray-500" : "bg-gray-300"}`}
  //             >
  //                 <div
  //                     className={`bg-white w-4 h-4 rounded-full shadow-md transform ${checked ? "translate-x-6" : ""} transition-transform`}
  //                 />
  //             </div>
  //         </div>
  //     );
  // };

  const fetchApplicationData = async (vendorObjId) => {
    setLoading(true);
    let payload = JSON.stringify({
      vendorObjId: vendorObjId,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${apiurl}/admin/v1/application/get`,
      headers: {
        Authorization: sctoken,
        "Content-Type": "application/json",
      },
      data: payload,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        setAppData(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const res = await axios.post(
        `${apiurl}/admin/v1/application/create`,
        dataToSend,
        {
          headers: {
            Authorization: sctoken,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("RESPONSE SECCESS",res.data.success)
      if (res?.data?.success === true) {
        console.log("RESPONSE MESSAGE IS:", res?.data?.message);
        setShowCreateAppPopup(false);
        toast.success("App created successfully");
        // window.location.reload();
        navigate("/apps");
      } else {
        console.log("RESPONSE MESSAGE IS:", res?.data?.message);
        toast.error(`Failed to create app, ${res?.data?.message}`);
      }
      // setResponse(res.data);
    } catch (error) {
      setError(error.response ? error.response.data : "An error occurred");
      toast.error(`Failed to create app`);
    } finally {
      setLoading(false);
      // window.location.reload();
    }
  };

  useEffect(() => {
    if (userData?.vendorDetails?.vendorObjId) {
      fetchApplicationData(userData.vendorDetails.vendorObjId);
    }
  }, []);
  return (
    <Layout>
      <Container>
        <div className="flex flex-wrap justify-between w-full h-full ">
          <div className="flex flex-col py-2 px-2 md:px-14  h-full w-full overflow-scroll">
            {/* <div className="flex items-center justify-between w-full">
              <Link to="/apps">
                <div className="flex items-center justify-center border border-slate-300 rounded-md w-8 h-8 cursor-pointer">
                  <IoMdArrowRoundBack className="text-slate-500" />
                </div>
              </Link>
              <h2 className="text-2xl text-center flex-1 text-slate-500 font-bold">
                Create App
              </h2>
            </div> */}

            <BackHeader backButton={true} title="Create App" link="/apps" />

            <div className=" flex w-[100%] mt-10">
              <div className="flex flex-col gap-4 justify-evenly w-[100%]">
                <div className="flex flex-col justify-between">
                  <div className="flex flex-col  w-[100%]">
                    <lable className="text-left text-base md:text-lg font-semibold text-slate-600">
                      App Name:
                    </lable>
                    <input
                      name="appName"
                      value={formdata.appName}
                      placeholder="Enter Name"
                      className="w-[100%] border border-[1px] border-slate-200 focus:outline-none px-3 py-2 my-2 rounded-lg"
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="flex flex-col  w-[100%]">
                    <lable className="text-left text-base md:text-lg font-semibold text-slate-600">
                      App Description:
                    </lable>
                    <input
                      name="appDescription"
                      value={formdata.appDescription}
                      placeholder="Enter Description"
                      className="w-[100%] border border-[1px] border-slate-200 focus:outline-none px-3 py-2 my-2 rounded-lg"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="flex flex-col md:flex-row  gap-4 md:gap-0 justify-between">
                  <div className="flex flex-col w-[100%] md:w-[45%] px-3 md:px-10 py-3 bg-slate-50 border border-2 border-slate-200 rounded-lg">
                    <div className="flex flex-col md:flex-row w-[100%] justify-between items-center">
                      <div className="w-full md:w-auto">
                        <p className="text-left text-base md:text-lg font-semibold text-slate-600 ">
                          stores :{" "}
                        </p>
                      </div>
                      <div className="flex justify-between mt-2 w-[100%] md:w-[60%]">
                        <div>
                          <Toggle
                            name="stores"
                            type="read"
                            checked={formdata.stores.read}
                            onChange={() =>
                              handleToggleChange("stores", "read")
                            }
                          />
                        </div>
                        <div>
                          <Toggle
                            name="stores"
                            type="write"
                            checked={formdata.stores.write}
                            onChange={() =>
                              handleToggleChange("stores", "write")
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col w-[100%] md:w-[45%] px-3 md:px-10 py-3 bg-slate-50 border border-2 border-slate-200 rounded-lg">
                    <div className="flex flex-col md:flex-row w-[100%] justify-between items-center">
                      <div className="w-full md:w-auto">
                        <p className="text-left text-base md:text-lg font-semibold  text-slate-600 ">
                          products :{" "}
                        </p>
                      </div>
                      <div className="flex justify-between mt-2 w-[100%] md:w-[60%]">
                        <Toggle
                          name="products"
                          type="read"
                          checked={formdata.products.read}
                          onChange={() =>
                            handleToggleChange("products", "read")
                          }
                        />
                        <Toggle
                          name="products"
                          type="write"
                          checked={formdata.products.write}
                          onChange={() =>
                            handleToggleChange("products", "write")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-row justify-between">
                  <div className="flex flex-col w-full md:w-[45%] px-3 md:px-10 py-3 bg-slate-50 border border-2 border-slate-200 rounded-lg">
                    <div className="flex flex-col md:flex-row w-full justify-between items-center">
                      <div className="w-full md:w-auto">
                        <p className="text-left text-base md:text-lg font-semibold text-slate-600 ">
                          Customers :{" "}
                        </p>
                      </div>
                      <div className="flex justify-between w-full md:w-[60%] mt-2 md:mt-0">
                        <Toggle
                          name="customers"
                          type="read"
                          checked={formdata.customers.read}
                          onChange={() =>
                            handleToggleChange("customers", "read")
                          }
                        />
                        <Toggle
                          name="customers"
                          type="write"
                          checked={formdata.customers.write}
                          onChange={() =>
                            handleToggleChange("customers", "write")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mt-10 md:mt-32">
              <BlackButton
                loading={loading}
                handleSubmit={handleSubmit}
                loadingTitle="Creating..."
                title="Create"
              />
                
              
            </div>
          </div>
        </div>

        {/* </div> */}
      </Container>
    </Layout>
  );
}

export default CreateApp