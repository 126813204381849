import React, { useState, useEffect } from "react";
import Layout, { Container } from "../components/layout";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useUser } from "../config/userProvider";
import axios from "axios";
import { apiurl } from "../config/config";
import { DynamicLoader } from "../components/loader";
import { IoMdArrowRoundBack } from "react-icons/io";
import BackHeader from "../components/backHeader";

const ProductDetails = () => {
  const { productId } = useParams();
  const { userData } = useUser();
  const navigate = useNavigate();
  let vendorObjId = userData?.vendorDetails?.vendorObjId;

  const [productDetails, setProductDetails] = useState(null); // Changed initial state to null
  const [loading, setLoading] = useState(false); // Added loading state

  const fetchProductDetails = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${apiurl}/admin/v1/product/get/${productId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response && response.data) {
        setProductDetails(response.data.product); // Changed to response.data.product to match expected structure
      }
    } catch (error) {
      console.error("Error fetching product details:", error); // Updated error message for clarity
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (productId) {
      fetchProductDetails();
    }
  }, [productId]); // Added productId as a dependency

  console.log(productDetails);

  return (
    <Layout>
      <Container>
        {loading ? (
          <DynamicLoader maintext="wait" subtext="Fetching Product Details" />
        ) : (
          <div className="h-full w-full px-5 py-3 overflow-scroll">
            {/* First Container */}
            {/* <div className="w-full md:h-[15%] flex flex-col md:flex-row justify-between items-center"> */}
            <BackHeader
              backButton={true}
              link="/products"
              title={productDetails?.title}
              rightSide={
                <div className=" text-gray-600 border-gray-300 py-1.5 rounded-md px-2 text-center">
                  <span className="font-semibold text-sm">Status:</span>{" "}
                  <span
                    className={`border text-xs md:text-sm px-4 p-0.5 rounded-md capitalize font-semibold ${
                      productDetails?.status === "active"
                        ? "bg-green-100 text-green-600 border-green-200"
                        : "bg-red-100 text-red-600 border-red-200"
                    }`}
                  >
                    {productDetails?.status}
                  </span>
                </div>
              }
            />

            {/* Second Container */}
            <div className="flex flex-col w-full md:h-[85%] overflow-scroll mt-5 md:mt-14">
              {/* Product Details and Image Container */}
              <div className="flex flex-col md:flex-row justify-between md:h-full w-full">
                {/* Product Details Container */}
                <div className="py-5 md:py-0 md:p-5 flex flex-col justify-between h-full md:w-[70%]">
                  <div className="mb-4 flex flex-col md:flex-row justify-between">
                    <div className="w-full md:w-[49%] text-gray-700 bg-slate-50 border-2 border-slate-200 px-3 md:px-10 py-3 rounded-lg mb-2 md:mb-0">
                      <div className="flex justify-between">
                        <span className="font-semibold">Barcode:</span>{" "}
                        {productDetails?.barcode}
                      </div>
                    </div>
                    <div className="w-full md:w-[49%] text-gray-700 bg-slate-50 border-2 border-slate-200 px-3 md:px-10 py-3 rounded-lg">
                      <div className="flex justify-between">
                        <span className="font-semibold">Product Type:</span>{" "}
                        {productDetails?.product_type}
                      </div>
                    </div>
                  </div>

                  {/* Created At and Updated At */}
                  <div className=" flex flex-col md:flex-row justify-between">
                    <div className="w-full md:w-[49%] text-gray-700 bg-slate-50 border-2 border-slate-200 px-3 md:px-10 py-3 rounded-lg mb-2 md:mb-0">
                      <div className="flex justify-between">
                        <span className="font-semibold">Created At:</span>{" "}
                        {new Date(
                          productDetails?.createdAt
                        ).toLocaleDateString()}
                      </div>
                    </div>
                    <div className="w-full md:w-[49%] text-gray-700 bg-slate-50 border-2 border-slate-200 px-3 md:px-10 py-3 rounded-lg">
                      <div className="flex justify-between">
                        <span className="font-semibold">Updated At:</span>{" "}
                        {new Date(
                          productDetails?.updatedAt
                        ).toLocaleDateString()}
                      </div>
                    </div>
                  </div>

                  {/* Vendor ID and Vendor Name */}
                  <div className=" flex flex-col md:flex-row justify-between">
                    <div className="w-full md:w-[49%] text-gray-700 bg-slate-50 border-2 border-slate-200 px-3 md:px-10 py-3 rounded-lg mb-2 md:mb-0">
                      <div className="flex justify-between">
                        <span className="font-semibold">Vendor ID:</span>{" "}
                        {productDetails?.vendorDetails?.vendorId}
                      </div>
                    </div>
                    <div className="w-full md:w-[49%] text-gray-700 bg-slate-50 border-2 border-slate-200 px-3 md:px-10 py-3 rounded-lg">
                      <div className="flex justify-between">
                        <span className="font-semibold">Vendor Name:</span>{" "}
                        {productDetails?.vendorDetails?.vendorName}
                      </div>
                    </div>
                  </div>

                  {/* Vendor Object ID */}
                  <div className="w-full  text-gray-700 bg-slate-50 border-2 border-slate-200 px-3 md:px-10 py-3 rounded-lg">
                    <div className="flex justify-between">
                      <span className="font-semibold">Vendor Object ID:</span>{" "}
                      {productDetails?.vendorDetails?.vendorObjId}
                    </div>
                  </div>

                  <div className="w-full text-gray-700 bg-slate-50 border-2 border-slate-200 px-3 md:px-10 py-3 rounded-lg">
                    <div className="flex justify-between">
                      <h3 className="text-lg font-semibold">Tags:</h3>
                      <ul className="list-disc pl-5 flex flex-wrap gap-2">
                        {productDetails?.tags?.map((tag, index) => (
                          <li
                            key={index}
                            className="list-none bg-gray-200 text-gray-700 border-gray-500 p-0.5 px-2 rounded-md"
                          >
                            {tag}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                {/* Main Image */}
                <div className="w-full md:w-[30%]  md:mb-0">
                  <img
                    className="w-full h-auto rounded-lg"
                    src={productDetails?.image}
                    alt={productDetails?.title}
                  />
                </div>
              </div>

              {/* Variants Container */}
              <div className="my-4">
                <h3 className="text-2xl text-slate-500 font-semibold">
                  Variants:
                </h3>
                <ul className="mt-4 space-y-4">
                  {productDetails?.variants?.map((variant, index) => (
                    <li
                      key={index}
                      className="flex items-start gap-4 bg-slate-50 border border-2 border-slate-200 rounded-lg p-4"
                    >
                      <img
                        className="w-14 h-14 object-cover rounded-lg"
                        src={variant.image}
                        alt={variant.title}
                      />
                      <div className="flex gap-8 flex-1">
                        <h4 className="font-semibold capitalize text-lg text-slate-600">
                          {variant.title}
                        </h4>
                        <p className="mt-1">
                          <span className="font-semibold text-sm text-slate-600">
                            Price:
                          </span>{" "}
                          ${variant.price}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>

              {/* Gallery Container */}
              <div className="my-5">
                <h3 className="text-2xl text-slate-500 font-semibold">
                  Gallery:
                </h3>

                <div className="flex flex-wrap gap-4 mt-4">
                  {productDetails?.images?.map((img, index) => (
                    <img
                      key={index}
                      className=" w-[100%]  md:w-52 rounded-lg"
                      src={img}
                      alt={`Product Image ${index + 1}`}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </Container>
    </Layout>
  );
};

export default ProductDetails;
