import React, { useEffect, useState } from "react";
import axios from "axios";
import Layout, { Container } from "../components/layout";
import { AiTwotoneEdit } from "react-icons/ai";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import { IoMdArrowRoundBack } from "react-icons/io";
import { apiurl } from "../config/config";
import { useUser } from "../config/userProvider";
import SearchContainer from "../components/searchContainer";
import BackHeader from "../components/backHeader";
import { BlackButton } from "../components/buttonContainer";


function CreateOffer() {
  const navigate = useNavigate();

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };
  const handleSend = () => {
    setShowAddProductsPopup(false);
  };

  const [storeData, setStoreData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [showCreateOfferPopup, setShowCreateOfferPopup] = useState(false);
  const [showAddProductsPopup, setShowAddProductsPopup] = useState(false);
  const [showOfferProductsPopup, setShowOfferProductsPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [data, setData] = useState([]);
  
  const { userData } = useUser();
  let vendorObjId = userData?.vendorDetails?.vendorObjId;

  console.log("showOfferProductsPopup", showOfferProductsPopup);

  const [formdata, setFormdata] = useState({
    srNo: "",
    offerName: "",
    type: "",
    value: "",
    createdBy: "",
    validFrom: "",
    validThrough: "",
    description: "",
    status: "",
    collections: false,
    products: false,
  });

  const submitHandler = () => {
    let data = JSON.stringify({
      name: formdata?.offerName,
      description: formdata?.description,
      startDate: formdata?.validFrom,
      endDate: formdata?.validThrough,
      vendorId: userData?.vendorDetails?.vendorId,
      vendorObjId: vendorObjId,
      type: formdata?.type.toLowerCase(),
      createdBy: userData?._id,
      value: parseInt(formdata?.value),
      products: selectedProducts,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${apiurl}/admin/v1/offer/create`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setFormdata("");
        setShowCreateOfferPopup(false);
        navigate("/offers");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const data = {
        vendorObjId: vendorObjId,
      };

      try {
        const response = await axios.post(
          `${apiurl}/admin/v1/product/get`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        console.log("response && response.data", response);
        if (response && response.data) {
          setData(response.data.products);
        }
      } catch (error) {
        console.error("Error fetching user list:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [vendorObjId, apiurl]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (formdata.type === "Percentage" && name === "value") {
      const percentageRegex = /^(100|[0-9]?[0-9])$/;

      if (!percentageRegex.test(value) && value !== "") {
        console.log("Value should be between 0 and 100 for Percentage");
        return;
      }
    }

    if (formdata.type === "Flat" && name === "value") {
      const flatRegex = /^[1-9]\d*$/;

      if (!flatRegex.test(value) && value !== "") {
        console.log("Value should be a positive number for Flat");
        return;
      }
    }

    setFormdata({
      ...formdata,
      [name]: value,
    });
  };

  const handleSelectProduct = (user) => {
    if (selectedProducts.includes(user._id)) {
      setSelectedProducts(selectedProducts.filter((u) => u !== user._id));
    } else {
      setSelectedProducts([...selectedProducts, user._id]);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedProducts(data.map(product => product._id));
    } else {
      setSelectedProducts([]);
    }
  };

  console.log(formdata);
  const handleToggleChange = (key) => {
    setFormdata((prevFormData) => {
      let newFormData = {
        ...prevFormData,
        [key]: !prevFormData[key],
      };

      if (key === "products" && newFormData.products) {
        newFormData.collections = false;
      }

      if (key === "collections" && newFormData.collections) {
        newFormData.products = false;
      }

      return newFormData;
    });
  };

  // Custom toggle switch component
  const Toggle = ({ name, checked, onChange }) => (
    <div className="flex justify-between">
      <label className="mr-2 text-lg">
        {name.charAt(0).toUpperCase() + name.slice(1)}
      </label>
      <div
        onClick={onChange}
        className={`w-12 h-6 flex items-center rounded-full p-1 cursor-pointer ${
          checked ? "bg-gray-500" : "bg-gray-300"
        }`}
      >
        <div
          className={`bg-white w-4 h-4 rounded-full shadow-md transform ${
            checked ? "translate-x-6" : ""
          } transition-transform`}
        />
      </div>
    </div>
  );

  console.log("formdata", selectedProducts, formdata);

  const offerProductList = ({ id }) => {
    navigate(`/offers/${id}`);
  };
  return (
    <Layout>
      <Container>
        {!showAddProductsPopup ? (
          <div className="flex flex-wrap justify-between w-full h-full ">
            <div className="flex flex-col  py-2 px-2 md:px-14 h-full w-full">
              <BackHeader
                backButton={true}
                link="/offers"
                title="Create New Offer"
              />

              <div className="md:mt-14 mt-4">
                <div className="flex flex-wrap -mx-2">
                  <div className="w-[50%] px-2">
                    <label className="text-sm">Offer Name</label>

                    <input
                      name="offerName"
                      value={formdata.name}
                      placeholder="Enter Offer Name"
                      className="w-full border border-slate-200 focus:outline-none px-3 py-2 mb-2 rounded-lg"
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="w-[50%] px-2">
                    <label className="text-sm">Type</label>

                    <select
                      name="type"
                      className="w-full border border-slate-200 focus:outline-none px-3 py-2 mb-2 rounded-lg"
                      defaultValue=""
                      onChange={handleInputChange}
                    >
                      <option value="" disabled>
                        Select Type
                      </option>
                      <option value="Percentage">Percentage</option>
                      <option value="Flat">Flat</option>
                    </select>
                  </div>
                  <div className="w-[50%] px-2">
                    <label className="text-sm">
                      Value {formdata.type ? "in" : null} {formdata.type}
                    </label>

                    <input
                      name="value"
                      value={formdata.value}
                      placeholder="Enter Value"
                      className="w-full border border-slate-200 focus:outline-none px-3 py-2 mb-2 rounded-lg"
                      onChange={handleInputChange}
                      disabled={formdata.type === ""}
                    />
                  </div>

                  <div className="w-[50%] px-2">
                    <label className="text-sm">Description</label>

                    <input
                      name="description"
                      value={formdata.description}
                      placeholder="Enter description"
                      className="w-full border border-slate-200 focus:outline-none px-3 py-2 mb-2 rounded-lg"
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="w-[50%] px-2">
                    <label className="text-sm">Status</label>

                    <select
                      name="status"
                      className="w-full border border-slate-200 focus:outline-none px-3 py-2 mb-2 rounded-lg"
                      defaultValue=""
                      onChange={handleInputChange}
                    >
                      <option value="" disabled>
                        Select Status
                      </option>
                      <option value="Active">Active</option>
                      <option value="Draft">Draft</option>
                    </select>
                  </div>
                  <div className="w-[50%] px-2">
                    <label className="text-sm">Valid From</label>
                    <input
                      name="validFrom"
                      type="date"
                      value={formdata.validFrom}
                      placeholder="valid From"
                      className="w-full border border-slate-200 focus:outline-none px-3 py-2 mb-2 rounded-lg"
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="w-[50%] px-2">
                    <label className="text-sm">Valid Through</label>
                    <input
                      name="validThrough"
                      type="date"
                      value={formdata.validThrough}
                      placeholder="valid Through"
                      className="w-full border border-slate-200 focus:outline-none px-3 py-2 mb-2 rounded-lg"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="flex flex-col md:flex-row justify-center md:justify-between">
                  <div className="flex mt-2 md:gap-3">
                    <div className="">
                      <Toggle
                        name="collections"
                        checked={formdata.collections}
                        onChange={() => handleToggleChange("collections")}
                      />
                    </div>
                    <div>
                      <Toggle
                        name="products"
                        checked={formdata.products}
                        onChange={() => handleToggleChange("products")}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="flex justify-center align-middle mt-3">
                      <button
                        onClick={() => setShowAddProductsPopup(true)}
                        className={`${
                          formdata.collections === false &&
                          formdata.products === false
                            ? "bg-white border-[1px] border-slate-500 text-slate-500 px-10 py-2 rounded-md "
                            : "bg-slate-500 text-white border-[1px] border-slate-500 px-10 py-2 rounded-md cursor-pointer"
                        }`}
                        disabled={
                          formdata.collections === false &&
                          formdata.products === false
                        }
                      >
                        Add{" "}
                        {formdata.collections === true
                          ? "Collections"
                          : formdata.products
                          ? "Products"
                          : null}{" "}
                        for this offer
                      </button>
                    </div>
                  </div>
                </div>

                <div className="text-center">
                  <BlackButton title="Create" handleSubmit={submitHandler} />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="flex flex-wrap justify-between w-full h-full">
              <div className="flex flex-col  py-2 px-2 md:px-8 h-full w-full">
                <div className="flex flex-col md:flex-row justify-between items-center md:my-2 w-full h-[10%] md:gap-4">
                  <div className="flex justify-between items-center w-full md:w-auto md:gap-5">
                    <div
                      className="flex items-center justify-center border border-slate-300 rounded-md w-8 h-8 cursor-pointer"
                      onClick={() => setShowAddProductsPopup(false)}
                    >
                      <IoMdArrowRoundBack className="text-slate-500 text-lg" />
                    </div>
                    <div>
                      <h2 className="flex-1 text-xl md:text-2xl font-bold text-slate-500 text-center">
                        Select Products
                      </h2>
                    </div>
                  </div>

                  <div className="flex flex-row gap-4 w-full justify-between md:w-[30%]">
                    <SearchContainer
                      placeholder={"Search Products..."}
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex flex-col justify-between w-full h-[75%] md:h-[80%]">
                  <div className="relative overflow-y-auto h-full rounded-lg">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 border-[1px] border-slate-200">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50 sticky top-0">
                        <tr className="border-b border-gray-200">
                          <th scope="col" className="px-4 py-3">
                            <input
                              type="checkbox"
                              onChange={handleSelectAll}
                              checked={
                                data.length > 0 &&
                                selectedProducts.length === data.length
                              }
                            />
                          </th>
                          <th scope="col" className="px-4 py-3">
                            Image
                          </th>

                          <th scope="col" className="px-4 py-3">
                            Title
                          </th>
                          <th scope="col" className="px-4 py-3">
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data &&
                          data.length > 0 &&
                          data.map((item, index) => (
                            <tr
                              key={index}
                              className={`${
                                index % 2 === 0 ? "bg-white" : "bg-gray-50"
                              } border-b`}
                            >
                              <td className="px-4 py-4">
                                <input
                                  type="checkbox"
                                  checked={selectedProducts.includes(item._id)}
                                  onChange={() => handleSelectProduct(item)}
                                />
                              </td>
                              <td className="px-4 py-4">
                                <label
                                  htmlFor={`${item.id}`}
                                  className="cursor-pointer"
                                >
                                  <img
                                    src={
                                      item.image ??
                                      "https://blackberrys.com/cdn/shop/files/logo_155x52.png"
                                    }
                                    alt=""
                                    className="h-11 w-11 object-cover rounded"
                                  />
                                </label>
                              </td>
                              <td className="px-4 py-4">{item?.title}</td>
                              <td className="px-4 py-4">{item?.status}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="text-center mt-4">
                    <BlackButton title="Add" handleSubmit={handleSend} />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Container>
    </Layout>
  );
}

export default CreateOffer;
