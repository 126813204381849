import React, { useContext, useEffect, useRef, useState } from "react";
import MyPDF from "./myPdf";
import { MyDocument2 } from "./template2";
import { MyDocument3 } from "./template3";


import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
  Link,
} from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import axios from "axios";
import "./createCatalog.css";
import PdfHeader from "./pdfHeader";
import PdfFooter from "./pdfFooter";
import TopBanner from "./topbanner.jpg";
import FooterBanner from "./footerbanner.jpg";
import { PdfContext } from "./pdfContext";

const downloadedData = async (data) => {

  const pdfData = await {
    Product: data?.Product,
    storeName: data?.Store?.storename,
    managerName: data?.Store?.managername,
    storePhone: data?.Store?.storePhone,
    storeAddress: data?.Store?.storeAdress,
    storeCode: data?.Store?.StoreCode,
  };
  axios({
    method: "post",
    url: "http://localhost:7000/analytic/create",
    payload: pdfData,
  })
    .then(function (response) {
      // handle success
      // console.log("success", response);
    })
    .catch(function (error) {
      console.log(error);
    });
};

const PDFComponent = ({ data }) => {
  const pdfRef = useRef();


  const { choosedPdf, setchoosedPdf } = useContext(PdfContext); // Use the context
  console.log("YE HAI PDF KA NAAM", choosedPdf);

  console.log("Data Hai", data?.Product?.[0]?.image);

  const submitData = async (data) => {
    // debugger
    const pdfData = await {
      Product: data?.Product,
      storeName: data?.Store?.storename,
      managerName: data?.Store?.managername,
      storePhone: data?.Store?.storePhone,
      storeAddress: data?.Store?.storeAdress,
      storeCode: data?.Store?.StoreCode,
    };
    try {
      const { data } = await axios({
        url: `https://blackberry-9ab311620e64.herokuapp.com/analytic/create`,
        method: "POST",
        // headers: {
        //   Authorization: ` ${token}`
        // },
        data: pdfData,
      });
    } catch (error) {
      console.log("error", error);
    }
  };
  console.log("Choosed pdf is :", choosedPdf)
  return (
    <div className="flex text-center justify-end w-full">
      <PDFDownloadLink
        document={
          choosedPdf === "Modern-Muse" ? (
            <MyDocument2 jsonData={data} />
          ) : choosedPdf === "Casual-Classics" ? (
            <MyDocument3 jsonData={data} />
          ) : (
            <MyPDF jsonData={data} />
          )
        }
        fileName={
          choosedPdf === "Modern-Muse"
            ? "Modern-Muse.pdf"
            : choosedPdf === "Casual-Classics"
            ? "Casual-Classics.pdf"
            : choosedPdf === "Luxe-Looks"
            ? "Luxe-Looks.pdf"
            : "Products.pdf"
        }
        onClick={() => submitData(data)}
        className="bg-slate-800 text-white px-10 md:px-10 py-2 rounded-md mt-4"
      >
        {({ blob, url, loading, error }) =>
          loading ? "Download now!" : "Download now!"
        }
      </PDFDownloadLink>
    </div>
  );
};

export default PDFComponent;
