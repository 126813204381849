import React, { useState } from 'react'
import Layout, { Container } from "../components/layout";
import { AiTwotoneEdit } from "react-icons/ai";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import { useUser } from "../config/userProvider";
import { apiurl } from "../config/config";
import {
  BackButtonContainer,
  BlackButton,
} from "../components/buttonContainer";
import BackHeader from "../components/backHeader";
import axios from "axios";

function AddCustomer() {
  const navigate = useNavigate();
  const { userData } = useUser();
  const vendorObjId = userData?.vendorDetails?.vendorObjId;

  const [formdata, setFormdata] = useState({
    name: "",
    email: "",
    phone: "",
    role: "user",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormdata({ ...formdata, [name]: value });
  };
  console.log(formdata);

  const handleToggleChange = (key) => {
    setFormdata((prevFormData) => ({
      ...prevFormData,
      [key]: !prevFormData[key],
    }));
  };

  const handleBack = () => {
    navigate("/customers");
  };

  const CreateHandler = () => {
    let data = JSON.stringify({
      vendorObjId: vendorObjId,
      name: formdata?.name,
      email: formdata?.email,
      phone: formdata?.phone,
      role: formdata?.role,
      description: "",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${apiurl}/admin/v1/customer/create`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        if (response.data.success === true) {
          navigate(`/customers`);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Custom toggle switch component
  const Toggle = ({ name, checked, onChange }) => (
    <div className="flex justify-between">
      <label className="mr-2 text-lg">
        {name.charAt(0).toUpperCase() + name.slice(1)}
      </label>
      <div
        onClick={onChange}
        className={`w-12 h-6 flex items-center rounded-full p-1 cursor-pointer ${
          checked ? "bg-gray-500" : "bg-gray-300"
        }`}
      >
        <div
          className={`bg-white w-4 h-4 rounded-full shadow-md transform ${
            checked ? "translate-x-6" : ""
          } transition-transform`}
        />
      </div>
    </div>
  );
  return (
    <Layout>
      <Container>
        <div className="flex flex-wrap justify-between w-full h-full ">
          <div className="flex flex-col  py-2 px-2 md:px-14 w-full">
            {/* <h2 className="text-2xl font-bold mb-4 text-slate-500 text-center p-4">
                            ADD Customer
                        </h2> */}
            {/* <div className="flex items-center justify-between w-full">
              <BackButtonContainer onClick={handleBack} />
              <h2 className="text-2xl text-center flex-1 text-slate-500 font-bold">
                Add Customer
              </h2>
            </div> */}
            <BackHeader
              title="Add Customer"
              backButton={true}
              link="/customers"
            />

            {/* <p className="text-slate-500 font-semibold">Information:</p> */}
            <div className="flex flex-col mt-10">
              <label className="font-semibold">Name :</label>
              <input
                name="name"
                value={formdata.name}
                placeholder="Enter Name"
                className="w-full border border-slate-200 focus:outline-none px-3 py-2 my-2 rounded-lg"
                onChange={handleInputChange}
              />
            </div>
            <div className="flex flex-col">
              <label className="font-semibold">Phone :</label>
              <input
                name="phone"
                value={formdata.phone}
                placeholder="Enter Phone No."
                className="w-full border border-slate-200 focus:outline-none px-3 py-2 my-2 rounded-lg"
                onChange={handleInputChange}
              />
            </div>

            <div className="flex flex-col">
              <label className="font-semibold">Email :</label>
              <input
                name="email"
                value={formdata.email}
                placeholder="Enter Email"
                className="w-full border border-slate-200 focus:outline-none px-3 py-2 my-2 rounded-lg"
                onChange={handleInputChange}
              />
            </div>
            {/* <div className="flex flex-col">
              <label className="font-semibold">Role :</label>
              <select
                name="role"
                value={formdata.role}
                className="w-full border border-slate-200 focus:outline-none px-3 py-2 my-2 rounded-lg"
                defaultValue=""
                onChange={handleInputChange}
              >
               
                <option value="admin">Admin</option>
                <option value="user">User</option>
              </select>,
            </div> */}

            <div className="text-center">
              <BlackButton title="Add" handleSubmit={CreateHandler} />
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}

export default AddCustomer