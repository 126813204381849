import React, { useState,useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Layout, { Container } from '../components/layout';
import { useUser } from "../config/userProvider";
import { IoMdArrowRoundBack } from "react-icons/io";
import { BlackButton } from "../components/buttonContainer";
import axios from "axios"
import BackHeader from "../components/backHeader";
import Input from "../components/inputContainer"
import { apiurl } from "../config/config";

const EditStore = () => {
  const { storeId } = useParams();
  const { userData } = useUser();
  let vendorObjId = userData?.vendorDetails?.vendorObjId;

  const navigate = useNavigate();

  const [storeDetails, setStoreDetails] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStoreDetails({ ...storeDetails, [name]: value });
  };

  const fetchUserData = async (vendorObjId) => {
    const payload = JSON.stringify({
      vendorObjId: vendorObjId,
    });
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${apiurl}/admin/v1/store/get/${storeId}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify("response.data", response.data));
        setStoreDetails(response.data?.store);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      vendorObjId: vendorObjId,
      storeCode: "67865",
      storeName: storeDetails?.name,
      storeEmail: storeDetails?.email,
      storePhone: storeDetails?.phone,
      storeAddress: storeDetails?.address,
      managerEmail: storeDetails?.managerEmail,
    };

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${apiurl}/admin/v1/store/update/${storeId}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        if (response.data.success === true) {
          console.log("Updated Store Details:", storeDetails);
          navigate(`/stores/${storeId}`);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleBack = () => {
    navigate(`/stores/${storeId}`);
  };

  console.log("storeDetails", storeDetails);
  return (
    <Layout>
      <Container>
        <div className="flex flex-wrap justify-between w-full h-full">
          <div className="flex flex-col  py-2 px-2 md:px-14 w-full h-full">
            <BackHeader leftSide={handleBack} title="Edit Details" />

            <div className="mt-5 md:mt-10">
              <div className="mb-4 flex space-x-4">
                <Input.normal
                  label="Store Name"
                  type="text"
                  name="name"
                  value={storeDetails?.storeDetails?.name}
                  onChange={handleInputChange}
                  // className="mt-1 p-2 block w-full border rounded"
                />

                <Input.normal
                  label="Email"
                  type="email"
                  name="email"
                  value={storeDetails?.storeDetails?.email}
                  onChange={handleInputChange}
                />
              </div>

              <div className="mb-4 flex space-x-4">
                <Input.normal
                  label="Phone"
                  type="text"
                  name="phone"
                  value={storeDetails?.storeDetails?.phone}
                  onChange={handleInputChange}
                  // className="mt-1 p-2 block w-full border rounded"
                />

                <Input.normal
                  label="Address"
                  type="text"
                  name="address"
                  value={storeDetails?.storeDetails?.addressDetails?.address}
                  onChange={handleInputChange}
                />
              </div>

              {/* <div className="mb-4 flex space-x-4">
                <Input.normal
                  label="Manager Name"
                  type="text"
                  name="managerName"
                  value={storeDetails.managerName}
                  onChange={handleInputChange}
                />
                <Input.normal
                  label="Manager Email"
                  type="email"
                  name="managerEmail"
                  value={storeDetails.managerEmail}
                  onChange={handleInputChange}
                />
              </div> */}

              {/* <Input.normal
                label="Manager Phone"
                type="text"
                name="managerPhone"
                value={storeDetails.managerPhone}
                onChange={handleInputChange}
              /> */}

              <div className="text-center">
                <BlackButton handleSubmit={handleSubmit} title="Save Changes" />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default EditStore;