import React from 'react'
import Layout from '../components/layout'
import ComingSoon from '../components/comingSoon'

const Orders = () => {
  return (
  <Layout>
  <ComingSoon/>
  </Layout>
  )
}

export default Orders