import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Layout, { Container } from "../components/layout";
import { AiTwotoneEdit } from "react-icons/ai";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { apiurl } from '../config/config';
import { useUser } from '../config/userProvider';
import { useParams, useNavigate } from 'react-router-dom';
import { IoMdArrowRoundBack } from "react-icons/io";
import { DynamicLoader } from '../components/loader';
import BackHeader from "../components/backHeader";
import { BlackButton } from "../components/buttonContainer";

function CustomerDetails() {
  const { userData } = useUser();
  const { customerObjId } = useParams(); // Extract the ID from the URL
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState([]);

  const deletePopup = () => {
    setShowDeleteMemberPopup(!showDeleteMemberPopup);
  };
  const handleDelete = () => {
    setShowDeleteMemberPopup(false);
  };
  const [showAddMemberPopup, setShowAddMemberPopup] = useState(false);
  const [showDeleteMemberPopup, setShowDeleteMemberPopup] = useState(false);
  // const [formdata, setFormdata] = useState({
  //     name: '',
  //     email: '',
  //     phone: '',
  //     role: '',
  //     stores: false,
  //     products: false,
  //     orders: false,
  //     settings: false,
  //     reports: false,
  //     users: false,
  //     analytics: false,
  // });
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    phone: "",
    role: "",
    stores: false,
    products: false,
    orders: false,
    settings: false,
    reports: false,
    users: false,
    analytics: false,
  });

  const handleBack = () => {
    navigate("/customers");
  };
  const fetchUserData = async (vendorObjId) => {
    setLoading(true);
    let payload = JSON.stringify({
      vendorObjId: vendorObjId,
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${apiurl}/admin/v1/customer/get/${customerObjId}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Customer Data:", response.data.data);
        // setAppData(response.data.data);
        // setLoading(false);
        // setUser(response?.data?.data)
        setUserDetails({
          name: response?.data?.data?.name || "",
          email: response?.data?.data?.email || "",
          phone: response?.data?.data?.phone || "",
          role: response?.data?.data?.userType || "",
          stores: response?.data?.data?.permission?.stores || false,
          products: response?.data?.data?.permission?.products || false,
          orders: response?.data?.data?.permission?.orders || false,
          settings: response?.data?.data?.permission?.settings || false,
          reports: response?.data?.data?.permission?.reports || false,
          users: response?.data?.data?.permission?.users || false,
          analytics: response?.data?.data?.permission?.analytics || false,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (userData?.vendorDetails?.vendorObjId) {
      fetchUserData(userData.vendorDetails.vendorObjId);
    }
  }, []);

  // Custom toggle switch component
  const Toggle = ({ name, checked, onChange }) => (
    <div className="flex justify-between">
      <label className="mr-2 text-lg">
        {name.charAt(0).toUpperCase() + name.slice(1)}
      </label>
      <div
        onClick={onChange}
        className={`w-12 h-6 flex items-center rounded-full p-1 ${
          checked ? "bg-gray-500" : "bg-gray-300"
        }`}
      >
        <div
          className={`bg-white w-4 h-4 rounded-full shadow-md transform ${
            checked ? "translate-x-6" : ""
          } transition-transform`}
        />
      </div>
    </div>
  );
  const editTeamDetails = (e) => {
    e.preventDefault();
    // Logic to update store details via API or state
    // console.log('Updated Store Details:', storeDetails);
    navigate(`/customers/${customerObjId}/edit`);
  };
  return (
    <Layout>
      <Container>
        {loading === true ? (
          <DynamicLoader maintext="wait" subtext="Fetching Customer Data" />
        ) : (
          <div className="flex flex-wrap justify-between w-full h-full">
            <div className="flex flex-col p-2 w-[100%] h-[100%] overflow-scroll">
              {/* <div className="flex justify-between items-center my-4 md:my-0">
                  <div
                    className="flex items-center justify-center border border-slate-300 rounded-md w-8 h-8 cursor-pointer"
                    onClick={handleBack}
                  >
                    <IoMdArrowRoundBack className="text-slate-500" />
                  </div>
                  <div>
                    <h2 className="text-base  md:text-2xl font-bold md:mb-4 text-slate-500 text-center md:p-4">
                      Customer Details
                    </h2>
                  </div>
                  <div>
                    <button
                      className="bg-slate-500 text-white px-2 md:px-20 text-sm md:text-base py-2 rounded-md md:mt-4"
                      onClick={editTeamDetails}
                    >
                      Edit Details
                    </button>
                  </div>
                </div> */}

              <BackHeader
                backButton={true}
                link="/customers"
                title="Customer Details"
                rightSide={
                  <BlackButton
                    handleSubmit={editTeamDetails}
                    title="Edit"
                  />
                  // <button
                  //   className="bg-slate-500 text-white px-2 md:px-20 text-sm md:text-base py-2 rounded-md md:mt-4"
                  //   onClick={editTeamDetails}
                  // >
                  //   Edit Details
                  // </button>
                }
              />
              <div className=" mt-5 md:mt-4 md:p-5">
                <p className="text-slate-500 font-semibold my-5">Information:</p>
                <div className='md:px-10 h-full overflow-auto'>
                <div className="flex flex-col md:flex-row md:gap-4">
                  <div className="w-full text-slate-500">
                    <p className="text-sm">Name :</p>
                    <div className="w-full border bg-white border-slate-200 focus:outline-none px-4 py-2 my-2 rounded-lg">
                      {userDetails.name || "N/A"} {/* Displaying user name */}
                    </div>
                  </div>

                  <div className="w-full text-slate-500">
                    <p className="text-sm">Phone :</p>

                    <div className="w-full border bg-white border-slate-200 focus:outline-none px-4 py-2 my-2 rounded-lg">
                      {userDetails.phone || "N/A"} {/* Displaying user phone */}
                    </div>
                  </div>
                </div>

                <div className="flex flex-col md:flex-row md:gap-4">
                  <div className="w-full text-slate-500">
                    <p className="text-sm">Email :</p>
                    <div className="w-full border bg-white border-slate-200 focus:outline-none px-4 py-2 my-2 rounded-lg">
                      {userDetails.email || "N/A"} {/* Displaying user email */}
                    </div>
                  </div>
                  <div className="w-full text-slate-500">
                    <p className="text-sm">Role :</p>
                    <div className="w-full border bg-white border-slate-200 focus:outline-none px-4 py-2 my-2 rounded-lg">
                      {userDetails.role || "Select Role"}{" "}
                      {/* Displaying user role */}
                    </div>
                  </div>
                </div>
                {/* <p className="text-slate-500 py-2 font-semibold">Permissions:</p>
                        <div className='flex flex-col gap-5'>
                            <div className="flex gap-4">
                                <div className="w-full">
                                    <Toggle
                                        name="stores"
                                        checked={userDetails.stores}
                                    />
                                </div>
                                <div className="w-full">
                                    <Toggle
                                        name="products"
                                        checked={userDetails.products}
                                    />
                                </div>
                            </div>
                            <div className="flex gap-4">
                                <div className="w-full">
                                    <Toggle
                                        name="orders"
                                        checked={userDetails.orders}
                                    />
                                </div>
                                <div className="w-full">
                                    <Toggle
                                        name="settings"
                                        checked={userDetails.settings}
                                    />
                                </div>
                            </div>
                            <div className="flex gap-4">
                                <div className="w-full ">
                                    <Toggle
                                        name="reports"
                                        checked={userDetails.reports}
                                    />
                                </div>
                                <div className="w-full ">
                                    <Toggle
                                        name="users"
                                        checked={userDetails.users}
                                    />
                                </div>
                            </div>
                            <div className="flex gap-4">
                                <div className="w-[49.2%]">
                                    <Toggle
                                        name="analytics"
                                        checked={userDetails.analytics}
                                    />
                                </div>
                            </div>
                        </div> */}
              </div>
              </div>
            </div>
          </div>
        )}
      </Container>
    </Layout>
  );
}

export default CustomerDetails