import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout, { Container } from "../components/layout";
import axios from "axios";
import { useUser } from "../config/userProvider";
import { apiurl } from "../config/config";
import { IoMdArrowRoundBack } from "react-icons/io";
import BackHeader from "../components/backHeader";
import Input from "../components/inputContainer";

function AddProduct() {
  const { userData } = useUser();
  let vendorObjId = userData?.vendorDetails?.vendorObjId;

  const [formdata, setFormdata] = useState({
    barcode: "",
    title: "",
    body_html: "",
    product_type: "",
    handle: "",
    status: "",
    tags: [],
    variants: [
      {
        id: "",
        title: "",
        size: "",
        sku: "",
        price: "",
        image: "",
      },
    ],
    images: [],
    image: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const token = "your-auth-token";

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormdata({ ...formdata, [name]: value });
    setError("");
  };

  const handleVariantChange = (index, field, value) => {
    const updatedVariants = [...formdata.variants];
    updatedVariants[index][field] = value;
    setFormdata({ ...formdata, variants: updatedVariants });
  };

  const addVariant = () => {
    setFormdata({
      ...formdata,
      variants: [
        ...formdata.variants,
        {
          id: "",
          title: "",
          size: "",
          sku: "",
          price: "",
          image: "",
        },
      ],
    });
  };

  const handleBack = () => {
    navigate("/products");
  };

  const handleSubmit = async () => {
    if (
      !formdata.barcode ||
      !formdata.title ||
      !formdata.body_html ||
      !formdata.product_type ||
      !formdata.handle ||
      !formdata.status
    ) {
      setError("Please fill in all required fields.");
      return;
    }

    setError("");
    setLoading(true);

    try {
      let data = JSON.stringify({
        barcode: formdata.barcode,
        title: formdata.title,
        body_html: formdata.body_html,
        vendorObjId: vendorObjId,
        product_type: formdata.product_type,
        handle: formdata.handle,
        status: formdata.status,
        tags: formdata.tags,
        variants: formdata.variants,
        images: formdata.images,
        image: formdata.image,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${apiurl}/admin/v1/product/create`,
        headers: {
          "x-api-Key": "2dce270654bbada13964b9d2ff1a9376",
          "x-token": token,
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios.request(config);
      if (response?.data?.success) {
        navigate("/products");
      } else {
        setError("Failed to add product. Please try again.");
      }
    } catch (err) {
      setError("An error occurred while adding the product. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <Container>
        <div className="h-full w-full mx-auto">
          <div className="h-[100%] w-[100%] flex flex-col  p-4 overflow-auto">
            <BackHeader
              backButton={true}
              link="/products"
              title=" Add Product"
              rightSide={
                <div className="">
                  <Input.normal label="Status" isInput={false} />
                  <select
                    name="status"
                    value={formdata.status}
                    className="w-full border border-slate-300 px-3 py-2 rounded-lg focus:outline-none"
                    onChange={handleInputChange}
                  >
                    <option value="" disabled>
                      Select Status
                    </option>
                    <option value="active">Active</option>
                    <option value="draft">Draft</option>
                  </select>
                </div>
              }
            />
            <div className="flex-col  flex gap-3 mt-5 md:mt-10  text-sm md:text-base">
              <div className="flex flex-col md:flex-row gap-3 md:gap-4">
                <Input.normal
                  label="Barcode"
                  name="barcode"
                  value={formdata.barcode}
                  placeholder="Enter Barcode"
                  containerWidth="md:w-[30%]"
                  // className="w-full border border-slate-300 px-3 py-2 rounded-lg focus:outline-none"
                  onChange={handleInputChange}
                />

                <Input.normal
                  label="Title"
                  name="title"
                  value={formdata.title}
                  placeholder="Enter Title"
                  // className="w-full border border-slate-300 px-3 py-2 rounded-lg focus:outline-none"
                  onChange={handleInputChange}
                />
              </div>

              <div className="">
                <Input.textArea
                  label=" Description (HTML)"
                  name="body_html"
                  value={formdata.body_html}
                  rows="4"
                  placeholder="Enter Description in HTML"
                  // className="w-full border border-slate-300 px-3 py-2 rounded-lg focus:outline-none"
                  onChange={handleInputChange}
                />
                
              </div>

              <Input.normal
                label="Handle"
                name="handle"
                value={formdata.handle}
                placeholder="Enter Handle"
                // className="w-full border border-slate-300 px-3 py-2 rounded-lg focus:outline-none"
                onChange={handleInputChange}
              />

              <FlexCol>
                <div className="md:w-2/6">
                  <Input.normal label="Product Type" isInput={false} />

                  <select
                    name="product_type"
                    value={formdata.product_type}
                    className="w-full border border-slate-300 px-3 py-2 rounded-lg focus:outline-none"
                    onChange={handleInputChange}
                  >
                    <option value="" disabled>
                      Select Product Type
                    </option>
                    <option value="Electronics">Electronics</option>
                    <option value="Clothing">Clothing</option>
                    <option value="Furniture">Furniture</option>
                  </select>
                </div>

                <div className=" md:w-full">
                  <Input.normal label="Main Image URL" isInput={false} />
                  <input
                    name="image"
                    value={formdata.image}
                    placeholder="Enter Main Image URL"
                    className="w-full border border-slate-300 px-3 py-2 rounded-lg focus:outline-none"
                    onChange={handleInputChange}
                  />
                </div>
              </FlexCol>

              <FlexCol>
                <div className=" md:w-full">
                  <Input.normal
                    label="Additional Images (comma-separated URLs)"
                    isInput={false}
                  />

                  <input
                    name="images"
                    value={formdata.images.join(",")}
                    placeholder="Enter Additional Image URLs"
                    className="w-full border border-slate-300 px-3 py-2 rounded-lg focus:outline-none"
                    onChange={(e) =>
                      setFormdata({
                        ...formdata,
                        images: e.target.value.split(","),
                      })
                    }
                  />
                </div>

                <div className="">
                  <Input.normal
                    label="Tags (comma-separated)"
                    isInput={false}
                  />
                  <input
                    name="tags"
                    value={formdata.tags.join(",")}
                    placeholder="Enter Tags"
                    className="w-full border border-slate-300 px-3 py-2 rounded-lg focus:outline-none"
                    onChange={(e) =>
                      setFormdata({
                        ...formdata,
                        tags: e.target.value.split(","),
                      })
                    }
                  />
                </div>
              </FlexCol>
              <div className="">
                <Input.normal label="Variants" isInput={false} />
                {formdata.variants.map((variant, index) => (
                  <div
                    key={index}
                    className="border border-slate-300 p-3 mb-2 rounded-lg"
                  >
                    <input
                      placeholder="Title"
                      value={variant.title}
                      onChange={(e) =>
                        handleVariantChange(index, "title", e.target.value)
                      }
                      className="w-full border border-slate-300 px-3 py-2 mb-2 rounded-lg focus:outline-none"
                    />
                    <input
                      placeholder="Size"
                      value={variant.size}
                      onChange={(e) =>
                        handleVariantChange(index, "size", e.target.value)
                      }
                      className="w-full border border-slate-300 px-3 py-2 mb-2 rounded-lg focus:outline-none"
                    />
                    <input
                      placeholder="SKU"
                      value={variant.sku}
                      onChange={(e) =>
                        handleVariantChange(index, "sku", e.target.value)
                      }
                      className="w-full border border-slate-300 px-3 py-2 mb-2 rounded-lg focus:outline-none"
                    />
                    <input
                      placeholder="Price"
                      value={variant.price}
                      onChange={(e) =>
                        handleVariantChange(index, "price", e.target.value)
                      }
                      className="w-full border border-slate-300 px-3 py-2 mb-2 rounded-lg focus:outline-none"
                    />
                    <input
                      placeholder="Image URL"
                      value={variant.image}
                      onChange={(e) =>
                        handleVariantChange(index, "image", e.target.value)
                      }
                      className="w-full border border-slate-300 px-3 py-2 rounded-lg focus:outline-none"
                    />
                  </div>
                ))}
                <button
                  onClick={addVariant}
                  className="bg-slate-400 text-white px-4 py-1 rounded-md mt-2"
                >
                  Add Variant
                </button>
              </div>

              {error && <p className="text-red-500 text-sm mb-4">{error}</p>}

              <div className="text-center">
                <button
                  onClick={handleSubmit}
                  className="bg-slate-500 hover:bg-slate-600 text-white px-6 py-2 rounded-md mt-4 disabled:bg-slate-200"
                  disabled={loading}
                >
                  {loading ? "Adding..." : "ADD PRODUCT"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}

export default AddProduct;

const FlexCol = ({ children }) => (
  <div className="flex-col md:flex-row flex gap-3 w-full">{children}</div>
);