const SegmentedTrafficBar = ({ title, subtitle, totalVisitors, segments }) => {
    // Calculate the total value of all segments for percentage calculation
    const totalValue = segments.reduce((acc, segment) => acc + segment.value, 0);

    return (
        <div className="bg-white rounded-lg p-6 w-full h-full">
            <h2 className="text-lg font-semibold text-gray-700 mb-1">{title}</h2>
            <p className="text-gray-500 mb-4">{subtitle}</p>
            <p className="text-4xl font-bold text-gray-800 mb-1">{totalVisitors.toLocaleString()}</p>
            <p className="text-gray-500 mb-6">Unique visitors</p>

            {/* Stacked Bar */}
            <div className="relative w-full h-4 rounded-full overflow-hidden bg-gray-200 mb-4">
                {segments.map((segment, index) => {
                    const percentage = (segment.value / totalValue) * 100;
                    return (
                        <div
                            key={index}
                            className="h-full"
                            style={{
                                width: `${percentage}%`,
                                backgroundColor: segment.color,
                            }}
                        />
                    );
                })}
            </div>

            {/* Segment Legend */}
            <div>
                {segments.map((segment, index) => (
                    <div key={index} className="flex justify-between items-center text-sm mb-1">
                        <div className="flex items-center">
                            <span
                                className="w-3 h-3 rounded-full mr-2"
                                style={{ backgroundColor: segment.color }}
                            />
                            <span className="text-gray-600">{segment.label}</span>
                        </div>
                        <div className="text-gray-800 font-medium">
                            {segment.value.toLocaleString()} ({((segment.value / totalValue) * 100).toFixed(1)}%)
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};



export default SegmentedTrafficBar;
