import { useState, useEffect } from 'react';
import { Document, Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import { PDFDownloadLink } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import axios from "axios";
import "./createCatalog.css";
import PdfHeader from "./pdfHeader";
import PdfFooter from "./pdfFooter";
import TopBanner from "./topbanner.jpg";
import FooterBanner from "./footerbanner.jpg";
import TopSecBanner from "./images/STORE_PDFF_2.jpg";



const styles = StyleSheet.create({
    page: {
        // flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        position: 'relative',
    },
    firstPageHeaderLine: {
        position: 'absolute',
        top: '55px',
        left: '0px',
        width: "73%",
        backgroundColor: '#e6c978',
        height: '2px'
    },
    firstPageUpperHeadingLeftBox: {
        height: '6.5%',
        width: '73%',
        position: 'absolute',
        top: '0px',
        left: '0px',
        backgroundColor: 'white',
        padding: '10px 80px',

    },
    firstPageLeftHeaderHeading: {
        color: '#e6c978',
        fontSize: 17,
        fontWeight: 'extrabold',
        fontStyle: 'italic',
    },
    firstPageLeftHeaderSubheading: {
        color: '#85adc7',
        fontSize: 8,
        paddingTop: '5px'
    },
    upperFirstBox: {
        height: '8%',
        width: '25%',
        position: 'absolute',
        top: '20px',
        right: '20px',
        backgroundColor: 'white',
        border: "2px solid #e6c978",
        padding: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
    },
    firstPageHeaderHeading: {
        color: '#375364',
        fontSize: 15,
        fontWeight: 'bold',
    },
    firstPageHeaderSubheading: {
        color: '#85adc7',
        fontSize: 8,
        padding: '5px'

    },
    firstPageMainContainer: {
        position: 'absolute',
        top: '10%',
        left: '0%',
        width: "60%",
        height: '60%',
        backgroundColor: '#024c80',
        padding: '20px',
        borderTopRightRadius: '40%',
    },
    firstPageMainInsideContainer: {
        position: 'absolute',
        backgroundColor: '#006da6',
        height: '40%',
        width: '35%',
        right: '0',
        bottom: '0',

    },
    firstPageMainInsideBottomContainer: {
        position: 'absolute',
        backgroundColor: '#e8c67a',
        height: '45%',
        width: '30%',
        left: '0',
        top: '0',
    },
    firstPageMainInsideTopContainer: {
        position: 'absolute',
        backgroundColor: '#e8c67a',
        height: '7%',
        width: '60%',
        left: '0',
        top: '10%',
    },
    firstPageImageContainer: {
        position: 'relative',
        top: '9.5%',
        left: '-20',
    },
    firstPageImage: {
        width: '84%',
        height: '84%',
        objectFit: 'cover',
    },
    firstPageMainInsideDotContainer: {
        position: 'absolute',
        width: '90%',
        height: '25%',
        bottom: '0',
        left: '0',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        padding: '20px',

    },
    firstPageMainInsideDotTopContainer: {
        display: 'flex',
        flexDirection: 'row',
        widht: '100%',
        gap: '27px'

    },
    firstPageMainInsideDotSecondContainer: {
        display: 'flex',
        flexDirection: 'row',
        widht: '100%',
        gap: '27px'
    },
    firstPageMainInsideDotThirdContainer: {
        display: 'flex',
        flexDirection: 'row',
        widht: '100%',
        gap: '27px'
    },
    firstPageMainInsideDotBottomContainer: {
        display: 'flex',
        flexDirection: 'row',
        widht: '100%',
        gap: '27px'
    },
    dot: {
        backgroundColor: '#e8c67a',
        borderRadius: '50%',
        width: '5px',
        height: '5px',

    },
    mainHeadingContainer: {
        position: 'absolute',
        top: '20%',
        right: '0px',
        width: "51%",
        height: '30%',
        padding: '30px 20px',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
    },
    mainHeading: {
        color: '#e6c577',
        fontSize: 65,
        fontWeight: '900',
    },
    firstPageContentContainer: {
        position: 'absolute',
        top: '48%',
        right: '0%',
        width: "58.5%",
        height: '45%',
        backgroundColor: '#eeeeee',
        padding: '50px 50px 50px 120px',
        gap: '8',

    },

    firstPageContentContainerHeading: {
        fontSize: 15,
        fontWeight: 'bold',
        color: '#004d80',
    },
    firstPageContentContainerDescription: {
        fontSize: 8,
    },
    firstPageBottomContainer: {
        // height: '10%',
        width: '50%',
        position: 'absolute',
        top: '75%',
        left: '50px',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    firstPageBottomContainerSubheading: {
        fontSize: 10,
        fontWeight: 'bold'
    },
    firstPageBottomContainerHeading: {
        color: '#004d80',
        fontSize: 18,
    },
    firstPageBottomContainerDescription: {
        fontSize: 8,
    },

    //For second Page ==>>

    secondPageHeaderLine: {
        position: 'absolute',
        top: '55px',
        left: '0px',
        width: "100%",
        backgroundColor: '#e6c978',
        height: '2px'
    },
    secondPageUpperHeadingLeftBox: {
        height: '6.5%',
        width: '73%',
        position: 'absolute',
        top: '0px',
        left: '0px',
        backgroundColor: 'white',
        padding: '10px 80px',

    },
    secondPageLeftHeaderHeading: {
        color: '#e6c978',
        fontSize: 17,
        fontWeight: 'extrabold',
        fontStyle: 'italic',
    },
    secondPageLeftHeaderSubheading: {
        color: '#85adc7',
        fontSize: 8,
        paddingTop: '5px'

    },
    secondPageImageContainer: {
        position: 'relative',
        top: '9.5%',
        left: '-19',
    },
    secondPageImage: {
        width: '55%',
        height: '65%',
        objectFit: 'cover',
        borderBottomRightRadius: '400px'
    },
    secondPageMainHeadingContainer: {
        position: 'absolute',
        top: '5%',
        left: '58%',
        height: '50%',
        width: '30%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly'

    },
    secondPageMainHeading: {
        color: '#004d80',
        fontSize: 28,
    },
    secondPageMainDescription: {
        fontSize: 10,
    },
    secondPagePrice: {
        color: '#004d80',
        textAlign: 'right',
        fontSize: 28,
        marginTop: '10px'
    },
    secondPageBottomContainer: {
        position: 'absolute',
        top: '55%',
        height: '30%',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
    },
    secondPageBottomFirstContainer: {
        width: '150px',
        height: '250px',
        backgroundColor: '#eeeeee',

    },
    secondPageBottomSecondContainer: {
        width: '150px',
        height: '250px',
        backgroundColor: '#eeeeee'
    },
    secondPageBottomThirdContainer: {
        width: '150px',
        height: '250px',
        backgroundColor: '#eeeeee',
    },
    secondPageBottomFirstImageContainer: {
        width: '100%',
        height: '50%',
    },
    secondPageBottomSecondImageContainer: {
        width: '100%',
        height: '50%',
    },
    secondPageBottomThirdImageContainer: {
        width: '100%',
        height: '50%',
    },
    secondPageBottomFirstImage: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
    },
    secondPageBottomSecondImage: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
    },
    secondPageBottomThirdImage: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
    },
    secondPageBottomFirstHeadingContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        padding: '5px',
        width: '100%',
        height: '50%',
    },
    secondPageBottomSecondHeadingContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        padding: '5px',
        width: '100%',
        height: '50%',
    },
    secondPageBottomThirdHeadingContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        padding: '5px',
        width: '100%',
        height: '50%',
    },
    secondPageBottomFirstContainerHeading: {
        color: '#004d80',
        fontSize: 15,
    },
    secondPageBottomFirstContainerDescription: {
        fontSize: 8,
    },
    secondPageBottomFirstContainerPrice: {
        color: '#004d80',
        fontSize: 10,
    },
    secondPageBottomSecondContainerHeading: {
        color: '#004d80',
        fontSize: 15,
    },
    secondPageBottomSecondContainerDescription: {
        fontSize: 8,
    },
    secondPageBottomSecondContainerPrice: {
        color: '#004d80',
        fontSize: 10,
    },
    secondPageBottomThirdContainerHeading: {
        color: '#004d80',
        fontSize: 15,
    },
    secondPageBottomThirdContainerDescription: {
        fontSize: 8,
    },
    secondPageBottomThirdContainerPrice: {
        color: '#004d80',
        fontSize: 10,
    },
    secondPageFooterContainer: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'row',
        height: '20%',
        width: '100%',
    },
    secondPageFooterLeftContainer: {
        width: '30%',
        backgroundColor: '#0887c2',
        height: '100%',
    },
    secondPageFooterRightContainer: {
        width: '70%',
        backgroundColor: '#00528a',
        height: '100%',
    },
    secondPageFooterDescriptionContainer: {
        position: 'absolute',
        left: 0,
        bottom: 20,
        display: 'flex',
        flexDirection: 'column',
        height: '10%',
        width: '100%',
        justifyContent: 'space-around',
        textAlign: 'center',
        paddingLeft: '50px',
        paddingRight: '50px'
    },
    secondPageFooterFirstDescriptionContainer: {
        color: 'white',
        fontSize: '10',

    },
    secondPageFooterSecondDescriptionContainer: {
        color: 'white',
        fontSize: '10',

    },
    secondPageBackContainer: {
        position: 'absolute',
        backgroundColor: '#e8c67a',
        height: '17%',
        width: '20%',
        right: '15',
        top: '53%',
    },


});



const MyPDF = ({ jsonData }) => {
    const [Data, setData] = useState();

    useEffect(() => {
        setData(jsonData);
    }, [jsonData]);

    console.log("This is the data of Luxe-Looks :=>", Data);
    console.log("IMAGE ================================================================>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.firstPageHeaderLine} />
                <View style={[styles.firstPageUpperHeadingLeftBox]} >
                    <Text style={styles.firstPageLeftHeaderHeading}>LIVING STYLE</Text>
                    <Text style={styles.firstPageLeftHeaderSubheading}>ROOM DECOR</Text>
                </View>
                <View style={[styles.upperFirstBox]} >
                    <Text style={styles.firstPageHeaderHeading}>LIVING STYLE</Text>
                    <Text style={styles.firstPageHeaderSubheading}>ROOM DECOR</Text>
                </View>
                <View style={[styles.firstPageLowerRightLight]} />

                <View style={[styles.firstPageContentContainer]} >
                    <Text style={styles.firstPageContentContainerHeading}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam tortor justo, molestie non tellus ut, varius volutpat quam. </Text>
                    <Text style={styles.firstPageContentContainerDescription}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam tortor justo, molestie non tellus ut, varius volutpat quam. Curabitur vitae pharetra mi, ac consequat massa. Donec augue odio, placerat ac iaculis sit amet, hendrerit sit amet neque. Suspendisse cursus aliquet scelerisque. Nunc pretium nulla odio, non luctus erat bibendum at. I</Text>
                </View>
                <View style={[styles.firstPageMainContainer]} >
                    <View style={[styles.firstPageMainInsideTopContainer]} >

                    </View>
                    <View style={[styles.firstPageMainInsideContainer]} >
                        <View style={[styles.firstPageMainInsideBottomContainer]} >

                        </View>
                    </View>
                    <View style={styles.firstPageImageContainer}>
                        <Image
                            style={styles.firstPageImage}
                            src={Data?.Product?.[0]?.image}
                        />
                    </View>
                    <View style={[styles.firstPageMainInsideDotContainer]} >
                        <View style={[styles.firstPageMainInsideDotTopContainer]} >
                            <Text style={styles.dot}>.</Text>
                            <Text style={styles.dot}>.</Text>
                            <Text style={styles.dot}>.</Text>
                            <Text style={styles.dot}>.</Text>
                            <Text style={styles.dot}>.</Text>
                            <Text style={styles.dot}>.</Text>
                            <Text style={styles.dot}>.</Text>
                            <Text style={styles.dot}>.</Text>
                        </View>
                        <View style={[styles.firstPageMainInsideDotSecondContainer]} >
                            <Text style={styles.dot}>.</Text>
                            <Text style={styles.dot}>.</Text>
                            <Text style={styles.dot}>.</Text>
                            <Text style={styles.dot}>.</Text>
                            <Text style={styles.dot}>.</Text>
                            <Text style={styles.dot}>.</Text>
                            <Text style={styles.dot}>.</Text>
                            <Text style={styles.dot}>.</Text>
                        </View>
                        <View style={[styles.firstPageMainInsideDotThirdContainer]} >
                            <Text style={styles.dot}>.</Text>
                            <Text style={styles.dot}>.</Text>
                            <Text style={styles.dot}>.</Text>
                            <Text style={styles.dot}>.</Text>
                            <Text style={styles.dot}>.</Text>
                            <Text style={styles.dot}>.</Text>
                            <Text style={styles.dot}>.</Text>
                            <Text style={styles.dot}>.</Text>
                        </View>
                        <View style={[styles.firstPageMainInsideDotBottomContainer]} >
                            <Text style={styles.dot}>.</Text>
                            <Text style={styles.dot}>.</Text>
                            <Text style={styles.dot}>.</Text>
                            <Text style={styles.dot}>.</Text>
                            <Text style={styles.dot}>.</Text>
                            <Text style={styles.dot}>.</Text>
                            <Text style={styles.dot}>.</Text>
                            <Text style={styles.dot}>.</Text>
                        </View>
                    </View>

                </View>
                <View style={[styles.mainHeadingContainer]} >
                    <Text style={styles.mainHeading}>NEW</Text>
                    <Text style={styles.mainHeading}>STYLES</Text>
                </View>
                <View style={[styles.firstPageBottomContainer]} >
                    <Text style={styles.firstPageBottomContainerSubheading}>DESIGNED BY</Text>
                    <Text style={styles.firstPageBottomContainerHeading}>INFI CONNECT</Text>
                    <Text style={styles.firstPageBottomContainerDescription}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam tortor justo, molestie non tellus ut, varius volutpat quam.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam tortor justo, molestie non tellus ut, varius volutpat quam.</Text>
                </View>
            </Page>

            {/* Second Page => */}
            <Page size="A4" style={styles.page}>
                <View style={styles.secondPageHeaderLine} />
                <View style={[styles.secondPageUpperHeadingLeftBox]} >
                    <Text style={styles.secondPageLeftHeaderHeading}>LIVING STYLE</Text>
                    <Text style={styles.secondPageLeftHeaderSubheading}>ROOM DECOR</Text>
                </View>
                <View style={styles.secondPageImageContainer}>
                    <Image
                        style={styles.secondPageImage}
                        src={Data?.Product?.[1]?.image}
                    />
                </View>
                <View style={styles.secondPageMainHeadingContainer}>
                    <Text style={styles.secondPageMainHeading}>{Data?.Product?.[0]?.title}</Text>
                    <Text style={styles.secondPageMainDescription}>It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.</Text>
                    {/* <Text style={styles.secondPagePrice}>$99.99</Text> */}
                    <Text style={styles.secondPagePrice}>{Data?.Product?.[0]?.variants?.[0]?.price} rs.</Text>
                </View>
                <View style={styles.secondPageFooterContainer}>
                    <View style={styles.secondPageFooterLeftContainer}></View>
                    <View style={styles.secondPageFooterRightContainer}></View>
                </View>
                <View style={styles.secondPageBackContainer}></View>
                <View style={styles.secondPageBottomContainer}>

                    {/* <View style={styles.secondPageBottomFirstContainer}>
                    <View style={styles.secondPageBottomFirstImageContainer}>
                        <Image
                            style={styles.secondPageBottomFirstImage}
                            src={Data?.Product?.[2]?.image}
                        />
                    </View>
                    <View style={styles.secondPageBottomFirstHeadingContainer}>
                        <Text style={styles.secondPageBottomFirstContainerHeading}>Lorem Ipsum Dolor Sit</Text>
                        <Text style={styles.secondPageBottomFirstContainerDescription}>It uses a dictionary of over 200 Latin words.</Text>
                        <Text style={styles.secondPageBottomFirstContainerPrice}>$99.99</Text>
                    </View>
                </View> */}

                    {Data?.Product?.[0]?.variants?.[0] ? (
                        <View style={styles.secondPageBottomFirstContainer}>
                            <View style={styles.secondPageBottomFirstImageContainer}>
                                <Image
                                    style={styles.secondPageBottomFirstImage}
                                    src={Data?.Product?.[0]?.variants?.[0]?.image}
                                />
                            </View>
                            <View style={styles.secondPageBottomFirstHeadingContainer}>
                                <Text style={styles.secondPageBottomFirstContainerHeading}>
                                {Data?.Product?.[0]?.variants?.[0]?.title}
                                </Text>
                                <Text style={styles.secondPageBottomFirstContainerDescription}>
                                    It uses a dictionary of over 200 Latin words.
                                </Text>
                                <Text style={styles.secondPageBottomFirstContainerPrice}>
                                {Data?.Product?.[0]?.variants?.[0]?.price} rs.
                                </Text>
                            </View>
                        </View>
                    ) : null}
                    {Data?.Product?.[0]?.variants?.[1] ? (
                        <View style={styles.secondPageBottomSecondContainer}>
                            <View style={styles.secondPageBottomSecondImageContainer}>
                                <Image
                                    style={styles.secondPageBottomSecondImage}
                                    src={Data?.Product?.[0]?.variants?.[1]?.image}
                                />
                            </View>
                            <View style={styles.secondPageBottomSecondHeadingContainer}>
                                <Text style={styles.secondPageBottomSecondContainerHeading}>{Data?.Product?.[0]?.variants?.[1]?.title}</Text>
                                <Text style={styles.secondPageBottomSecondContainerDescription}>It uses a dictionary of over 200 Latin words.</Text>
                                <Text style={styles.secondPageBottomSecondContainerPrice}>{Data?.Product?.[0]?.variants?.[1]?.price} rs.</Text>
                            </View>
                        </View>
                    ) : null}
                    {Data?.Product?.[0]?.variants?.[2] ? (
                        <View style={styles.secondPageBottomThirdContainer}>
                            <View style={styles.secondPageBottomThirdImageContainer}>
                                <Image
                                    style={styles.secondPageBottomThirdImage}
                                    src={Data?.Product?.[0]?.variants?.[2]?.image}
                                />
                            </View>
                            <View style={styles.secondPageBottomThirdHeadingContainer}>
                                <Text style={styles.secondPageBottomThirdContainerHeading}>{Data?.Product?.[0]?.variants?.[2]?.title}</Text>
                                <Text style={styles.secondPageBottomThirdContainerDescription}>It uses a dictionary of over 200 Latin words.</Text>
                                <Text style={styles.secondPageBottomThirdContainerPrice}>{Data?.Product?.[0]?.variants?.[2]?.price} rs.</Text>
                            </View>
                        </View>
                    ) : null}
                </View>
                <View style={styles.secondPageFooterDescriptionContainer}>
                    <View style={styles.secondPageFooterFirstDescriptionContainer}>
                        <Text style={styles.secondPageFooterFirstDescription}>It uses a dictionary of over 200 Latin words.</Text>
                    </View>
                    <View style={styles.secondPageFooterSecondDescriptionContainer}>
                        <Text style={styles.secondPageFooterFirstDescription}>It uses a dictionary of over 200 Latin words.It uses a dictionary of over 200 Latin words.It uses a dictionary of over 200 Latin words.It uses a dictionary of over 200 Latin words.It uses a dictionary of over 200 Latin words.It uses a dictionary of over 200 Latin words.It uses a dictionary of over 200 Latin words.It uses a dictionary of over 200 Latin words.</Text>
                    </View>
                </View>
            </Page>


        </Document>
    );
};

export default MyPDF;
