import React, { useEffect, useState } from "react";
import Layout, { Container } from "../components/layout";
import axios from "axios";
import { FiSearch } from "react-icons/fi";
import Loader from "../components/loader";
import { Link, useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import SearchContainer from "../components/searchContainer";
import BackHeader from "../components/backHeader";
import { useUser } from "../config/userProvider";
import { BlackButton } from "../components/buttonContainer";
import { apiurl } from "../config/config";
import Input from "../components/inputContainer";
import Card from "../components/card";
import DataNotFound from "../components/dataNotFound";
import svg from "../components/svg";

function CreateBroadcast() {
  const navigate = useNavigate();
  const { userData } = useUser();
  let vendorObjId = userData?.vendorDetails?.vendorObjId;

  const [storeData, setStoreData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [showUserListPopup, setShowUserListPopup] = useState(false);
  const [formdata, setFormdata] = useState({
    name: "",
    description: "",
    status: "active",
    launchDate: "",
    file: "",
    customers: [],
    mail: false,
    whatsapp: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormdata({ ...formdata, [name]: value });
  };

  const handleSelectUser = (user) => {
    if (selectedUsers.some((selectedUser) => selectedUser === user._id)) {
      setSelectedUsers(selectedUsers.filter((id) => id !== user._id));
    } else {
      setSelectedUsers([...selectedUsers, user._id]);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedUsers(storeData.map((user) => user._id));
    } else {
      setSelectedUsers([]);
    }
  };

  // console.log("selected User", selectedUsers);
  const [error, setError] = useState("");

  console.log("formdata", formdata);

  const handleNext = (e) => {
    if (
      formdata?.name === "" ||
      formdata?.description === "" ||
      formdata.status === "" ||
      formdata?.launchDate === ""
    ) {
      setError("Fill all the input fields");
    } else {
      setShowUserListPopup(true);
      setError("");
    }
  };

    const handleToggleChange = (key) => {
      if (key === "whatsapp") {
        setFormdata((prev) => ({ ...prev, whatsapp: true, mail: false }));
      }

      if (key === "mail") {
        setFormdata((prev) => ({ ...prev, whatsapp: false, mail: true }));
      }
    };

  const handleSend = async () => {
    try {
      const data = JSON.stringify({
        broadcastsname: formdata.name,
        description: formdata.description,
        status: formdata.status,
        mail: formdata.mail.toString(),
        whatsapp: formdata.whatsapp.toString(),
        vendorObjId: vendorObjId,
        customers: selectedUsers,
      });

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${apiurl}/admin/v1/broadcast/create`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));
      setShowUserListPopup(false);
      navigate("/broadcasts");
    } catch (error) {
      console.log(error);
    }
  };

  const [customers, setCustomers] = useState("");

  useEffect(() => {
    let payload = JSON.stringify({
      vendorObjId: vendorObjId,
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${apiurl}/admin/v1/customer/get`,
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Customer Data:", response.data.data);
        // setAppData(response.data.data);
        // setLoading(false);
        setCustomers(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);



  const Toggle = ({ name, checked, onChange }) => (
    <div className="flex justify-between">
      <label className="mr-2 text-lg">
        {name.charAt(0).toUpperCase() + name.slice(1)}
      </label>
      <div
        onClick={onChange}
        className={`w-12 h-6 flex items-center rounded-full p-1 cursor-pointer ${
          checked ? "bg-gray-500" : "bg-gray-300"
        }`}
      >
        <div
          className={`bg-white w-4 h-4 rounded-full shadow-md transform ${
            checked ? "translate-x-6" : ""
          } transition-transform`}
        />
      </div>
    </div>
  );

  console.log("customers", customers);
  return (
    <Layout>
      <Container>
        <>
          {!showUserListPopup ? (
            <div className="flex flex-wrap justify-between w-full h-full">
              <div className="flex flex-col w-full h-full overflow-scroll p-2 ">
                <BackHeader
                  title="Create Broadcast"
                  link="/broadcasts"
                  backButton={true}
                  rightSide={
                    <div className="">
                      <Input.normal isInput={false} />

                      <select
                        name="status"
                        value={formdata?.status}
                        className="w-full border  border-slate-200 focus:outline-none bg-white px-3 py-2  rounded-lg"
                        onChange={handleInputChange}
                      >
                        <option value="">Select Status</option>
                        <option value="active">Active</option>
                        <option value="expired">Expired</option>
                        <option value="schedule">Schedule</option>
                      </select>
                    </div>
                  }
                />

                <div className=" flex w-[100%] justify-between h-[90%] mt-10">
                  <div className="flex flex-col  w-full gap-5">
                    <Input.normal
                      label="Broadcast Name"
                      name="name"
                      value={formdata.name}
                      placeholder="Enter Name of Broadcast"
                      //   className="w-[100%] border  border-slate-200 focus:outline-none px-3 py-2 my-2 rounded-lg"
                      onChange={handleInputChange}
                    />

                    <Input.textArea
                      label="Description"
                      name="description"
                      value={formdata.description}
                      placeholder="Enter Description"
                      rows={4}
                      //   className="w-[100%] border  border-slate-200 focus:outline-none px-3 py-2 my-2 rounded-lg"
                      onChange={handleInputChange}
                    />

                    <div className="flex w-full items-center gap-3 flex-col md:flex-row ">
                      <Input.normal
                        label="Photo/Pdf"
                        type="file"
                        name="file"
                        value={formdata.file ? formdata.file : ""}
                        placeholder="Select File"
                        //   className="w-[100%] border  border-slate-200 focus:outline-none bg-white px-3 py-2 my-2 rounded-lg"
                        onChange={handleInputChange}
                      />

                      <div>
                        <Input.normal
                          label="Launch Date"
                          type="date"
                          name="launchDate"
                          value={formdata.launchDate}
                          placeholder="Enter Launch Date"
                          //   className="w-[100%] border  border-slate-200 focus:outline-none px-3 py-2 my-2 rounded-lg"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="flex justify-center">
                      {" "}
                      <div className="bg-red-50 text-sm text-red-700 font-semibold rounded-md border-red-200 px-4 py-0.5">
                        {error}
                      </div>
                    </div>

                    <div>
                      <div className="w-full flex justify-center items-center gap-2 md:gap-4  mt-3">
                        <div
                          className="hover:border-green-600 cursor-pointer px-3 py-1 border rounded-md flex items-center gap-2 hover:text-green-700 text-nowrap "
                          onClick={() => {
                            handleToggleChange("whatsapp");
                            handleNext();
                          }}
                        >
                          <svg.whatsapp />
                          <span>Send on whatsapp</span>
                        </div>
                        <div
                          className="hover:border-red-600 cursor-pointer px-3 py-1 border rounded-md flex items-center gap-2 hover:text-red-700 text-nowrap "
                          onClick={() => {
                            handleToggleChange("mail");
                            handleNext();
                          }}
                        >
                          <svg.gmail />
                          <span>Send on gmail</span>
                        </div>
                      </div>

                      {/* <div className="flex justify-center">
                        <div className="flex mt-2 gap-3">
                          <div className="">
                            <Toggle
                              name="mail"
                              checked={formdata.mail}
                              onChange={() => handleToggleChange("mail")}
                            />
                          </div>
                          <div>
                            <Toggle
                              name="whatsapp"
                              checked={formdata.whatsapp}
                              onChange={() => handleToggleChange("whatsapp")}
                            />
                          </div>
                        </div>
                      </div> */}
                    </div>

                    <div className="text-center mt-2">
                      {/* <BlackButton
                        type="submit"
                        title="Next"
                        
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            // <div className="flex flex-wrap justify-between w-full h-full">
            //   <div className="flex flex-col py-2 px-2 md:px-8 h-full w-full">
            <Card.AddPopUp removePopUp={() => setShowUserListPopup(false)}>
              <BackHeader
                title={
                  <div className="flex flex-col">
                    <span>Select Users</span>
                    <span
                      className={`  text-xs  font-semibold rounded-md  px-4 py-0.5   ${
                        formdata?.whatsapp === true
                          ? "bg-green-50 text-green-700 border-green-200"
                          : formdata?.mail === true
                          ? "bg-red-50 text-red-700 border-red-200"
                          : ""
                      }`}
                    >
                      ...Sending on{" "}
                      {formdata?.whatsapp === true
                        ? "whatsapp"
                        : formdata?.mail === true
                        ? "mail"
                        : ""}
                    </span>
                  </div>
                }
                leftSide={() => setShowUserListPopup(false)}
                rightSide={
                  <div className="w-[40%] md:w-[30%]">
                    <SearchContainer
                      value={searchInput}
                      placeholder={"Search Users..."}
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                  </div>
                }
              />

              <div className="flex flex-col justify-between w-full h-[80%] mt-10">
                <div className="relative overflow-y-auto h-full rounded-lg ">
                  <table className="w-full text-sm text-left rtl:text-right text-gray-500 border-[1px] border-slate-200">
                    <thead className="text-xs md:text-sm text-gray-700 uppercase bg-gray-50 sticky top-0">
                      <tr className="border-b border-gray-200">
                        <th scope="col" className="px-4 py-3">
                          <input
                            type="checkbox"
                            onChange={handleSelectAll}
                            checked={selectedUsers.length === storeData.length}
                          />
                        </th>
                        <th scope="col" className="px-4 py-3">
                          User Name
                          {/* <select className="px-2 py-1 text-xs mx-2 rounded-lg border border-slate-200">
                            <option value="" disabled>
                              Sort By
                            </option>
                            <option value="time">Time</option>
                          </select> */}
                        </th>
                        <th scope="col" className="px-4 py-3">
                          Store Phone
                        </th>
                        <th scope="col" className="px-4 py-3">
                          Store Address
                        </th>
                        <th scope="col" className="px-4 py-3">
                          User Login Email
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {customers.length > 0 &&
                        customers
                          .filter((user) =>
                            user.name
                              .toLowerCase()
                              .includes(searchInput.toLowerCase())
                          )
                          .map((item, index) => (
                            <tr
                              key={index}
                              className={`${
                                index % 2 === 0 ? "bg-white" : "bg-gray-50"
                              } border-b`}
                            >
                              <td className="px-4 py-4">
                                <input
                                  type="checkbox"
                                  checked={selectedUsers.some(
                                    (selectedUser) => selectedUser === item._id
                                  )}
                                  onChange={() => handleSelectUser(item)}
                                />
                              </td>
                              <td className="px-4 py-4">{item?.name}</td>
                              <td className="px-4 py-4">{item?.phone}</td>
                              <td className="px-4 py-4">
                                {item?.storeAddress}
                              </td>
                              <td className="px-4 py-4">{item?.email}</td>
                            </tr>
                          ))}
                    </tbody>
                  </table>

                  {!customers.length > 0 && (
                    <DataNotFound.container label="Users" />
                  )}
                </div>

                <div className="text-center mt-4">
                  <BlackButton handleSubmit={handleSend} title="Send" />
                </div>
              </div>
            </Card.AddPopUp>
          )}
        </>
      </Container>
    </Layout>
  );
}

export default CreateBroadcast;
