import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Layout, { Container } from "../components/layout";
import { apiurl } from '../config/config';
import { useUser } from '../config/userProvider';
import { useNavigate, useParams } from 'react-router-dom';
import { IoMdArrowRoundBack } from "react-icons/io";
import BackHeader from "../components/backHeader";
import { BlackButton } from "../components/buttonContainer";

function EditCustomer() {
  const { userData } = useUser();
  const { customerObjId } = useParams(); // Extract the ID from the URL
  const navigate = useNavigate();

  const [showDeleteMemberPopup, setShowDeleteMemberPopup] = useState(false);
  const [formdata, setFormdata] = useState({
    name: "",
    email: "",
    phone: "",
    role: "",
    stores: false,
    products: false,
    orders: false,
    settings: false,
    reports: false,
    users: false,
    analytics: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormdata({ ...formdata, [name]: value });
  };
  console.log(formdata);

  const handleToggleChange = (key) => {
    setFormdata((prevFormData) => ({
      ...prevFormData,
      [key]: !prevFormData[key],
    }));
  };

  const fetchUserData = async (vendorObjId) => {
    let payload = JSON.stringify({
      vendorObjId: vendorObjId,
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${apiurl}/admin/v1/user/get/${customerObjId}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Customer Data:", response.data.data);
        // setAppData(response.data.data);
        // setLoading(false);
        // setUser(response?.data?.data)
        setFormdata({
          name: response?.data?.data?.name || "",
          email: response?.data?.data?.email || "",
          phone: response?.data?.data?.phone || "",
          role: response?.data?.data?.userType || "",
          stores: response?.data?.data?.permission?.stores || false,
          products: response?.data?.data?.permission?.products || false,
          orders: response?.data?.data?.permission?.orders || false,
          settings: response?.data?.data?.permission?.settings || false,
          reports: response?.data?.data?.permission?.reports || false,
          users: response?.data?.data?.permission?.users || false,
          analytics: response?.data?.data?.permission?.analytics || false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (userData?.vendorDetails?.vendorObjId) {
      fetchUserData(userData.vendorDetails.vendorObjId);
    }
  }, []);

  // Custom toggle switch component
  const Toggle = ({ name, checked, onChange }) => (
    <div className="flex justify-between">
      <label className="mr-2 text-lg">
        {name.charAt(0).toUpperCase() + name.slice(1)}
      </label>
      <div
        onClick={onChange}
        className={`w-12 h-6 flex items-center rounded-full p-1 cursor-pointer ${
          checked ? "bg-gray-500" : "bg-gray-300"
        }`}
      >
        <div
          className={`bg-white w-4 h-4 rounded-full shadow-md transform ${
            checked ? "translate-x-6" : ""
          } transition-transform`}
        />
      </div>
    </div>
  );
  const handleBack = () => {
    navigate(`/customers/${customerObjId}`);
  };
  return (
    <Layout>
      <Container>
        <div className="flex flex-wrap justify-between w-full h-full">
          <div className="flex flex-col  py-2 px-2 md:px-14  w-[100%] h-[100%]">
            {/* <h2 className="text-2xl font-bold mb-4 text-slate-500 text-center p-4">
                            Edit Details
                        </h2> */}
            {/* <div className="flex items-center justify-between w-full">
                <div
                  className="flex items-center justify-center border border-slate-300 rounded-md w-8 h-8 cursor-pointer"
                  onClick={handleBack}
                >
                  <IoMdArrowRoundBack className="text-slate-500" />
                </div>
                <h2 className="text-xl md:text-2xl text-center flex-1 text-slate-500 font-bold">
                  Edit Details
                </h2>
              </div> */}

            <BackHeader
              title=" Edit Details"
              backButton={true}
              link={`/customers/${customerObjId}`}
              // rightSide={<BlackButton title="Edit" />}
            />
            <div className="mt-5">
              <p className="text-slate-500 font-semibold">Information:</p>
              <div className="px-10 py-3">
                <div className="flex gap-4">
                  <div className="w-full">
                    <label className="text-sm text-slate-500">Name :</label>
                    <input
                      name="name"
                      value={formdata.name}
                      placeholder="Enter Name"
                      className="w-full border border-slate-200 focus:outline-none px-4 py-2 my-2 rounded-lg"
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="w-full">
                    <label className="text-sm text-slate-500">Phone :</label>
                    <input
                      name="phone"
                      value={formdata.phone}
                      placeholder="Enter Phone No."
                      className="w-full border border-slate-200 focus:outline-none px-4 py-2 my-2 rounded-lg"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="w-full">
                    <label className="text-sm text-slate-500">Email :</label>
                    <input
                      name="email"
                      value={formdata.email}
                      placeholder="Enter Email"
                      className="w-full border border-slate-200 focus:outline-none px-4 py-2 my-2 rounded-lg"
                      onChange={handleInputChange}
                    />
                  </div>
                  {/* <div className="w-full">
                    <label className="text-sm text-slate-500">Role :</label>
                    <select
                      name="role"
                      value={formdata.role}
                      className="w-full border border-slate-200 focus:outline-none px-4 py-2 my-2 rounded-lg"
                      defaultValue=""
                      onChange={handleInputChange}
                    >
                      <option value="" disabled>
                        Select Role
                      </option>
                      <option value="user">User</option>
                      <option value="admin">Admin</option>
                   </select>
                  </div> */}
                </div>
                {/* <p className="text-slate-500 py-2 font-semibold">Permissions:</p>
                        <div className='flex flex-col gap-5'>
                            <div className="flex gap-4">
                                <div className="w-full">
                                    <Toggle
                                        name="stores"
                                        checked={formdata.stores}
                                        onChange={() => handleToggleChange("stores")}
                                    />
                                </div>
                                <div className="w-full">
                                    <Toggle
                                        name="products"
                                        checked={formdata.products}
                                        onChange={() => handleToggleChange("products")}
                                    />
                                </div>
                            </div>
                            <div className="flex gap-4">
                                <div className="w-full">
                                    <Toggle
                                        name="orders"
                                        checked={formdata.orders}
                                        onChange={() => handleToggleChange("orders")}
                                    />
                                </div>
                                <div className="w-full">
                                    <Toggle
                                        name="settings"
                                        checked={formdata.settings}
                                        onChange={() => handleToggleChange("settings")}
                                    />
                                </div>
                            </div>
                            <div className="flex gap-4">
                                <div className="w-full">
                                    <Toggle
                                        name="reports"
                                        checked={formdata.reports}
                                        onChange={() => handleToggleChange("reports")}
                                    />
                                </div>
                                <div className="w-full">
                                    <Toggle
                                        name="users"
                                        checked={formdata.users}
                                        onChange={() => handleToggleChange("users")}
                                    />
                                </div>
                            </div>
                            <div className="flex gap-4">
                                <div className="w-[49.2%]">
                                    <Toggle
                                        name="analytics"
                                        checked={formdata.analytics}
                                        onChange={() => handleToggleChange("analytics")}
                                    />
                                </div>
                            </div>
                        </div> */}
                <div className="text-center">
                  {/* <button className="bg-slate-500 text-white px-20 py-2 rounded-md mt-4">
                  Save
                </button> */}
                  <BlackButton title="Save" />
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </Container>
    </Layout>
  );
}

export default EditCustomer