import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
  Link,
} from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import axios from "axios";
import "./createCatalog.css";

const styles = StyleSheet.create({
  page: {
    flexDirection: "col",
    backgroundColor: "#E4E4E4",
  },
  Topbarsection: {
    // display: "flex",
    margin: 10,
    padding: 10,
    // flexGrow: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    // alignItems: "top",
    height: 40,
  },
  brandtext: {
    color: "#3d2d17",
    // fontFamily: 'Montserrat,sans-serif',
    fontWeight: 500,
    fontSize: "20px",
    height: "30px",
  },
  logo: {
    height: 30,
  },

  // Bannersection: {
  //   display: "flex",
  //   margin: 10,
  //   padding: 10,
  //   flexGrow: 1,
  //   flexDirection: "row",
  //   justifyContent: "space-between",
  //   alignItems: "top",
  //   width: "100%",
  // },

  banner: {
    // width: 100,
    // height: 100,
    // marginBottom: 8,
    // marginTop: 2,
  },

  section: {
    display: "flex",
    // margin: 10,
    padding: 10,
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "top",
    height: "20px",
  },
  newArrivalsText: {
    padding: 10,
    margin: 10,
    height: "40px",
    textAlign: "center",
    backgroundColor: "#FFFFFF",
  },
  productCardWraper: {
    display: "flex",
    margin: 10,
    padding: 10,
    // flexGrow: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "top",
    flexWrap: "wrap",
  },

  productImage: {
    height: "300",
  },
  productCard: {
    width: "48%",
    // height: 100,
    // margin: 8,
    margin: 5,
    height: "350px",
    border: "1px solid black",
    borderRadius: "5px",
    overflow: "hidden",
  },
  prductName: {
    color: "#222",
    fontWeight: 700,
    fontSize: "17px",
    margin: 4,
    paddingLeft: "10px",
  },
  button: {
    display: "flex",
    // margin: 10,
    // padding: 10,
    // flexGrow: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "top",
  },
  price: {
    width: "50%",
    height: 30,
    backgroundColor: "#ecebeb",
    textAlign: "center",
    color: "#000",
    paddingTopTop: 2,
    textDecorationStyle: "none",
  },
  link: {
    width: "50%",
    height: 30,
    backgroundColor: "#a2703b",
    textAlign: "center",
    color: "#fff",
  },
});

const MyPDF = ({ jsonData }) => {
  const [Data, setData] = useState();
  // const [checkedItems, setCheckedItems] = useState([]);
  // const [SeletedItems, setSeletedItems] = useState([]);

  // const submitIDs = () => {
  //   console.log(Input);
  //   console.log("Input", Input);
  // };

  useEffect(() => {
    setData(jsonData);
  }, [jsonData]);

  // console.log("jsonData 152", jsonData);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.Topbarsection}>
          <Image
            // src="https://i.ibb.co/ng7v340/Screenshot-2024-09-26-123622-removebg-preview.png?v=1629974004"
            style={styles.logo}
          />
          <Text style={styles.brandtext}>Product Catalogue</Text>
        </View>

        <View style={styles.Bannersection}>
          <Image
            style={styles.banner}
            src="https://blackberrys.com/cdn/shop/files/ABOUTUS-MAIN_IMAGE-jpeg__1.jpg?v=1632448576"
          />
        </View>

        <View style={styles.Bannersection}>
          <Image
            style={styles.Secbanner}
            source={
              "https://blackberrys.com/cdn/shop/files/lookbook3rd_banner_img1300px.jpg?v=1677052840"
            }
          />
        </View>
        <Text style={styles.newArrivalsText}>New Arrivals Collection</Text>

        {/* <View style={styles.Bannersection}>
          <Image  style={styles.Secbanner} source={'https://blackberrys.com/cdn/shop/articles/men-s-zipper-jacket-letting-you-show-off-a-bit-more-blackberrys-clothing_1024x1024.jpg?v=1685947396'}/>
        </View> */}

        <View style={styles.productCardWraper}>
          {Data === undefined ? (
            <Text>Data Not Available</Text>
          ) : (
            Data.map((item) => (
              <View style={styles.productCard}>
                <Image
                  style={styles.productImage}
                  src={
                    item?.images[0]?.src ??
                    "https://blackberrys.com/cdn/shop/files/logo_155x52.png"
                  }
                />
                <Text style={styles.prductName}>{item.title}</Text>
                <View style={styles.button}>
                  <Link style={styles.price}>{item.variants[0].price}</Link>
                  <Link
                    src={`https://theblackberrys.myshopify.com/products/${item.handle}`}
                    style={styles.link}
                  >
                    Quick Buy
                  </Link>
                </View>
              </View>
            ))
          )}
        </View>

        {/* <View style={styles.Bannersection}>
          <Image
            style={styles.Secbanner}
            source={
              "https://media.licdn.com/dms/image/D563DAQFiAIq9gsjFUA/image-scale_191_1128/0/1682674157730?e=1689498000&v=beta&t=vTNtCyNn-MoFdsgeDLTSiGCZZProWFn6GhKkXdKg4RQ"
            }
          />
        </View> */}
        <View style={styles.Bannersection}>
          <Image
            style={styles.Secbanner}
            source={
              "https://cdn.shopify.com/s/files/1/0569/0284/4581/files/book_an_appointment.jpg?v=1677138473"
            }
          />
        </View>
      </Page>
    </Document>
  );
};

const PDFComponent = ({ data }) => {
  const handleDownload = () => {
    const blob = new Blob([<MyPDF />], { type: "application/pdf" });
    saveAs(blob, "products.pdf");
  };
  // console.log("261", data);
  return (
    <div>
      <PDFViewer width={600} height={800}>
        <MyPDF jsonData={data} />
      </PDFViewer>

      <PDFDownloadLink
        document={<MyPDF jsonData={data} />}
        fileName="products.pdf"
        className="bg-slate-800 text-white px-20 py-2 rounded-md mt-4"
      >
        {({ blob, url, loading, error }) =>
          loading ? "Download now!" : "Download now!"
        }
      </PDFDownloadLink>
      {/* <button onClick={handleDownload}>Download PDF</button> */}

      {/* <PDFDownloadLink document={<MyPDF />} fileName="example.pdf">
        {({ loading }) => (loading ? "Loading document..." : "Download PDF")}
      </PDFDownloadLink> */}
      {/* <button  onClick={handleDownload}>Download PDF</button> */}
    </div>
  );
};

export default PDFComponent;
