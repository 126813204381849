import React, { useState, useEffect } from 'react'
import Layout, { Container } from '../components/layout'
import { useParams, useNavigate } from 'react-router-dom';
import { IoMdArrowRoundBack } from "react-icons/io";
import axios from 'axios';
import { FiSearch } from "react-icons/fi";
import { apiurl } from '../config/config';
import { useUser } from '../config/userProvider';
import SearchContainer from '../components/searchContainer';

import BackHeader from "../components/backHeader";



function StoreDetails() {
    const { storeId } = useParams();
    const navigate = useNavigate();
    const { userData } = useUser();


    const [loading, setLoading] = useState(false);
    const [managers, setManagers] = useState([]);
    const [selectedManager, setSelectedManager] = useState("");
    const [searchInput, setSearchInput] = useState("");

    const [storeDetails, setStoreDetails] = useState({
      // name: 'Ens Store',
      // email: 'ens.store@gmail.com',
      // phone: '9876543262',
      // address: 'tsgfads',
      // managerName: 'Shubham Sharma',
      // managerEmail: 'shubham.sharma@ens.enterprises',
      // managerPhone: '+1234567890',
    });
    const [showAssignManagerPopup, setShowAssignManagerPopup] = useState(false);

    const handleSelectUser = (user) => {
      // If the user is already selected, deselect it. Otherwise, set the selected user.
      setSelectedManager(selectedManager?._id === user._id ? null : user);
    };

    const assignManagerPopup = () => {
      setShowAssignManagerPopup(!showAssignManagerPopup);
    };

    const handleBack = () => {
      navigate("/stores");
    };


    const fetchUserData = async (vendorObjId) => {
      setLoading(true);
      const payload = JSON.stringify({
        vendorObjId: vendorObjId,
      });
     let config = {
       method: "get",
       maxBodyLength: Infinity,
       url: `${apiurl}/admin/v1/store/get/${storeId}`,
       headers: {},
     };

     axios
       .request(config)
       .then((response) => {
         console.log(JSON.stringify("response.data",response.data));
         setStoreDetails(response.data?.store);
       })
       .catch((error) => {
         console.log(error);
       });
    };

    useEffect(() => {
      // Make sure vendorObjId is provided before fetching the data
      if (userData?.vendorDetails?.vendorObjId) {
        fetchUserData(userData.vendorDetails.vendorObjId);
      }
    }, []); // Re-run when vendorObjId changes

    const editStoreDetails = (e) => {
      e.preventDefault();
      navigate(`/stores/${storeId}/edit`);
    };

    console.log("Store Details", storeDetails);

    return (
      <Layout>
        <Container>
          <div className="flex flex-wrap justify-center items-center w-full h-full">
            <div className="flex flex-col  py-6 md:px-8 w-full h-full">
              <BackHeader
                backButton={true}
                link="/stores"
                title="Store Details"
                rightSide={
                  <div>
                    <button
                      type="submit"
                      className="bg-slate-500 text-white text-xs md:text-base px-2 md:px-20 py-2 rounded-md"
                      onClick={editStoreDetails}
                    >
                      Edit Details
                    </button>
                  </div>
                }
              />

              <div className="h-[90%] overflow-scroll flex flex-col gap-5 mt-5">
                <div className="py-2 px-7  rounded ">
                  {/* <div className="flex flex-col mt-2">
                    <div>
                      <p className="text-xl pb-2 text-slate-500 font-medium">
                        Store Details :
                      </p>
                    </div>
                    <div className="h-[1px] bg-slate-300 mb-2"></div>
                  </div> */}

                  <div className="mb-4 md:flex md:space-x-4">
                    <div className="flex-1">
                      <h3 className="block text-gray-700 font-semibold">
                        Store Name
                      </h3>
                      <p className="mt-1 p-2 bg-white rounded border border-slate-300">
                        {storeDetails?.storeDetails?.name || '-'}
                      </p>
                    </div>
                    <div className="flex-1">
                      <h3 className="block text-gray-700 font-semibold">
                        Email
                      </h3>
                      <p className="mt-1 p-2 bg-white rounded border border-slate-300">
                        {storeDetails?.storeDetails?.email || '-'} 
                      </p>
                    </div>
                  </div>

                  <div className="mb-4 md:flex md:space-x-4">
                    <div className="flex-1">
                      <h3 className="block text-gray-700 font-semibold">
                        Phone
                      </h3>
                      <p className="mt-1 p-2 bg-white rounded border border-slate-300">
                        {storeDetails?.storeDetails?.phone || '-'}
                      </p>
                    </div>
                    {storeDetails?.storeDetails?.addressDetails?.address && (
                      <div className="flex-1">
                        <h3 className="block text-gray-700 font-semibold">
                          Address
                        </h3>
                        <p className="mt-1 p-2 bg-white rounded border border-slate-300">
                          {storeDetails?.storeDetails?.addressDetails?.address}, {' '}
                          {storeDetails?.storeDetails?.addressDetails?.city}, {' '}
                          {storeDetails?.storeDetails?.addressDetails?.province}, {' '}
                          {storeDetails?.storeDetails?.addressDetails?.country}
                        </p>
                      </div>
                    )}
                  </div>
                </div>

                <div className="py-2 px-7  rounded">
                  <div className="flex flex-col mt-2">
                    <div>
                      <p className="text-xl pb-2 text-slate-500 font-medium">
                        Manager Details :
                      </p>
                    </div>
                    <div className="h-[1px] bg-slate-300 mb-2"></div>
                    {storeDetails.managerName ||
                    storeDetails.managerEmail ||
                    storeDetails.managerPhone ? (
                      <>
                        <div className="mb-4 flex space-x-4">
                          <div className="flex-1">
                            <h3 className="block text-gray-700 font-semibold">
                              Manager Name
                            </h3>
                            <p className="mt-1 p-2 bg-white rounded border border-slate-300">
                              {storeDetails.managerName}
                            </p>
                          </div>
                          <div className="flex-1">
                            <h3 className="block text-gray-700 font-semibold">
                              Manager Email
                            </h3>
                            <p className="mt-1 p-2 bg-white rounded border border-slate-300">
                              {storeDetails.managerEmail}
                            </p>
                          </div>
                        </div>

                        <div className="mb-4">
                          <h3 className="block text-gray-700 font-semibold">
                            Manager Phone
                          </h3>
                          <p className="mt-1 p-2 bg-white rounded border border-slate-300">
                            {storeDetails.managerPhone}
                          </p>
                        </div>
                      </>
                    ) : (
                      <div className="flex flex-col gap-5 justify-center align-middle items-center py-10">
                        <p className="text-slate-500 text-lg">
                          There are no details available
                        </p>
                        <button
                          className="px-5 py-2  bg-slate-500 hover:bg-zinc-800 rounded-lg text-white"
                          onClick={assignManagerPopup}
                        >
                          Assign Manager
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="py-2 px-7  rounded ">
                  <div className="flex flex-col mt-2">
                    <div>
                      <p className="text-xl pb-2 text-slate-500 font-medium">
                        Staff Details :
                      </p>
                    </div>
                    <div className="h-[1px] bg-slate-300 mb-2"></div>

                    {storeDetails.staffName ||
                    storeDetails.staffEmail ||
                    storeDetails.staffPhone ? (
                      <div>
                        <div className="mb-4 flex space-x-4">
                          <div className="flex-1">
                            <h3 className="block text-gray-700 font-semibold">
                              Manager Name
                            </h3>
                            <p className="mt-1 p-2 bg-white rounded border border-slate-300">
                              {storeDetails.staffName}
                            </p>
                          </div>
                          <div className="flex-1">
                            <h3 className="block text-gray-700 font-semibold">
                              Manager Email
                            </h3>
                            <p className="mt-1 p-2 bg-white rounded border border-slate-300">
                              {storeDetails.staffEmail}
                            </p>
                          </div>
                        </div>
                        <div className="mb-4">
                          <h3 className="block text-gray-700 font-semibold">
                            Manager Phone
                          </h3>
                          <p className="mt-1 p-2 bg-white rounded border border-slate-300">
                            {storeDetails.staffPhone}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="flex justify-center align-middle items-center py-10">
                        <p className="text-slate-500 text-lg">
                          There are no details available
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {showAssignManagerPopup && (
              <>
                <div className="fixed top-0 left-0 w-full h-full opacity-30 bg-black"></div>
                <div
                  className={`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg w-11/12 md:w-[50rem] pl-6 pr-6 pt-8 pb-8 md:pl-8 md:pr-8 md:pt-10 md:pb-10`}
                >
                  <div className="md:flex space-y-2 md:space-y-0  justify-between my-3 h-[10%] items-center">
                    <div className="">
                      <h2 className="text-2xl font-bold text-slate-500">
                        Select Manager
                      </h2>
                    </div>
                    <div className="flex gap-5 items-center">
                      {/* <div className="flex items-center px-3 rounded-full bg-white/100 border w-60 h-10">
                                            <FiSearch />
                                            <input
                                                type="text"
                                                placeholder="search Manager Name..."
                                                className="outline-none w-full px-2 bg-white/100"
                                                value={searchInput}
                                                onChange={(e) => setSearchInput(e.target.value)}
                                            />
                                        </div> */}
                      <SearchContainer
                        value={searchInput}
                        placeholder={"Search Manager Name"}
                        onChange={(e) => setSearchInput(e.target.value)}
                      />
                      <div>
                        {/* <button className="text-3xl md:text-4xl" onClick={assignManagerPopup}>&times;</button> */}
                        <div
                          className="flex items-center justify-center border border-slate-400 rounded-sm  md:rounded-md w-4 h-4 md:w-8 md:h-8 cursor-pointer"
                          onClick={assignManagerPopup}
                        >
                          <span className="text-slate-500 text-md md:text-xl">
                            &times;
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="h-[80%] overflow-hidden relative overflow-x-auto border-[1px] border-slate-200 sm:rounded-lg">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500  max-h-96">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50   sticky top-0">
                        <tr className="border-b border-gray-200">
                          <th scope="col" className="px-6 py-3">
                            Name
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Phone
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Email
                          </th>
                          <th scope="col" className="px-6 py-3 text-center">
                            Select
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {managers &&
                          managers
                            .filter((user) =>
                              user.name
                                .toLowerCase()
                                .includes(searchInput.toLowerCase())
                            )
                            .map((item, index) => (
                              <tr
                                key={index}
                                className={`${
                                  index % 2 === 0
                                    ? "odd:bg-white"
                                    : "even:bg-gray-50 "
                                } border-b`}
                              >
                                <td className="px-6 py-4">{item?.name}</td>
                                <td className="px-6 py-4">{item?.phone}</td>
                                <td className="px-6 py-4">{item?.email}</td>
                                <td className="px-6 py-4 text-center">
                                  <button
                                    className={`px-3 py-1 rounded text-sm ${
                                      selectedManager?._id === item._id
                                        ? "bg-slate-700 text-white"
                                        : "bg-slate-300 text-gray-700"
                                    }`}
                                    onClick={() => handleSelectUser(item)}
                                  >
                                    {selectedManager?._id === item._id
                                      ? "Selected"
                                      : "Select"}
                                  </button>
                                </td>
                              </tr>
                            ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="text-center h-[10%]">
                    <button className="bg-slate-500 text-white px-20 py-2 rounded-md mt-4">
                      Assign
                    </button>
                  </div>
                </div>
                <div></div>
              </>
            )}
          </div>
        </Container>
      </Layout>
    );
}

export default StoreDetails