import React, { useEffect, useState } from "react";
import Layout, { Container } from "../components/layout";
import { AiTwotoneEdit } from "react-icons/ai";
import { RiDeleteBin6Fill } from "react-icons/ri";
import axios from "axios";
import { apiurl } from "../config/config";
import { useUser } from "../config/userProvider";
import { FiSearch } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { DynamicLoader } from "../components/loader";
import { IoMdAddCircle } from "react-icons/io";
import ButtonContainer from "../components/buttonContainer";
import { IoAddOutline } from "react-icons/io5";
import SearchContainer from "../components/searchContainer";
import { PopModel } from "../components/popupModels";

function Customers() {
  const { userData } = useUser();
  const navigate = useNavigate();
  const [showImportContainer, setShowImportContainer] = useState(false);

  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  console.log("CUSTOMERS IS :========>>>>>>", customers);
  const [searchInput, setSearchInput] = useState("");

  const fetchCustomerData = async (vendorObjId) => {
    setLoading(true);
    let payload = JSON.stringify({
      vendorObjId: vendorObjId,
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${apiurl}/admin/v1/customer/get`,
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Customer Data:", response.data.data);
        // setAppData(response.data.data);
        // setLoading(false);
        setCustomers(response?.data?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  const handleEditCustomer = (id) => {
    navigate(`/customers/${id}`);
  };

  useEffect(() => {
    if (userData?.vendorDetails?.vendorObjId) {
      fetchCustomerData(userData.vendorDetails.vendorObjId);
    }
  }, []);

  const filterdata = customers.filter(
    (customer) =>
      customer.name.toLowerCase().includes(searchInput.toLowerCase()) ||
      customer.phone.toLowerCase().includes(searchInput.toLowerCase())
  );

  return (
    <Layout>
      <Container>
        {loading === true ? (
          <DynamicLoader maintext='wait' subtext='Fetching Customer Data' />
        ) : (
          // <div>
          //     <div className="border border-2 border-slate-200 md:rounded-xl w-full px-5 py-3">
          //         <div className="flex justify-between my-4">
          //             <div>
          //                 <h1 className="text-3xl font-medium text-center mb-3 text-slate-500">
          //                     Our Customers
          //                 </h1>
          //             </div>
          //             <div className="flex gap-5 items-center">
          //                 <div>
          //                     <Link to="/customers/add">
          //                         <button className="bg-slate-500 hover:bg-slate-600 text-white font-semibold py-2 px-4 rounded-lg">
          //                             Add Customer
          //                         </button>
          //                     </Link>
          //                 </div>

          //                 <div className="flex items-center py-1 px-3 rounded-full bg-gray-50 border focus-within:border-gray-300 max-w-xl">
          //                     <FiSearch className="text-gray-400 text-xl" />
          //                     <input
          //                         type="text"
          //                         placeholder="search Customer..."
          //                         className="bg-transparent w-full focus:outline-none pr-4 font-semibold border-0 focus:ring-0 px-2"
          //                         value={searchInput}
          //                         onChange={(e) => setSearchInput(e.target.value)}
          //                     />
          //                     <button className="flex items-center justify-center min-w-[130px] px-4 rounded-full bg-slate-500 text-white font-medium border-transparent py-1.5 h-[38px] -mr-3">
          //                         Search
          //                     </button>
          //                 </div>
          //             </div>
          //         </div>
          //         <div className="flex flex-col">
          //             <div className="overflow-x-auto sm:rounded-lg h-[540px]">
          //                 <table className="w-full text-sm text-left rtl:text-right text-gray-500  max-h-96">
          //                     <thead className="text-xs text-gray-700 uppercase bg-gray-50   sticky top-0">
          //                         <tr>
          //                             {/* <th scope="col" className="px-6 py-3">
          //                             SR No.
          //                         </th> */}
          //                             <th scope="col" className="px-6 py-3">
          //                                 Name
          //                             </th>
          //                             <th scope="col" className="px-6 py-3 ">
          //                                 Email
          //                             </th>
          //                             <th scope="col" className="px-6 py-3 ">
          //                                 Phone No.
          //                             </th>

          //                             {/* <th scope="col" className="px-6 py-3 flex justify-center">
          //                             Action
          //                         </th> */}
          //                         </tr>
          //                     </thead>

          //                     <tbody className="h-[100%]">
          //                         {customers.map((customer, index) => (
          //                             <tr
          //                                 key={index}
          //                                 className={`${index % 2 === 0
          //                                         ? "odd:bg-white "
          //                                         : "even:bg-gray-50 "
          //                                     } border-b   max-h-5 cursor-pointer`}
          //                                 onClick={() => handleEditCustomer(customer._id)}
          //                             >
          //                                 {/* <th
          //                                 scope="row"
          //                                 className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
          //                             >
          //                                 {customer.srNo}
          //                             </th> */}
          //                                 <th
          //                                     scope="row"
          //                                     className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
          //                                 >
          //                                     {customer.name}
          //                                 </th>

          //                                 <td className="px-6 py-4">{customer.email}</td>
          //                                 <td className="px-6 py-4">{customer.phone}</td>
          //                                 {/* <td className="px-6 py-4">{product.price}</td> */}
          //                                 {/* <td className="px-6 py-4 flex justify-center">
          //                                 <div className='flex align-middle gap-3'>
          //                                     <div className='h-8 w-8 flex justify-center align-middle rounded-md text-lg pt-2 text-slate-500 hover:text-red-700 hover:bg-red-200 ' ><RiDeleteBin6Fill /></div>
          //                                 </div>
          //                             </td> */}
          //                             </tr>
          //                         ))}
          //                     </tbody>
          //                 </table>
          //             </div>
          //         </div>
          //     </div>
          // </div>

          <div className='border  border-slate-200 rounded-lg h-full w-full px-5 py-3'>
            {/* <div className="flex flex-col md:flex-row justify-between my-4">
                            <div>
                                <h1 className="text-3xl font-medium text-center mb-3 text-slate-500">Our Customers</h1>
                            </div>
                            <div className="flex flex-col md:flex-row gap-4 items-center">
                                <Link to="/customers/add">
                                    <button className="bg-slate-500 hover:bg-slate-600 text-white font-semibold py-2 px-4 rounded-lg">
                                        Add Customer
                                    </button>
                                </Link>
                                <div className="flex items-center py-1 px-3 rounded-full bg-gray-50 border focus-within:border-gray-300 max-w-xl w-full md:w-auto">
                                    <FiSearch className="text-gray-400 text-xl" />
                                    <input
                                        type="text"
                                        placeholder="Search Customer..."
                                        className="bg-transparent w-full focus:outline-none pr-4 font-semibold border-0 focus:ring-0 px-2"
                                        value={searchInput}
                                        onChange={(e) => setSearchInput(e.target.value)}
                                    />
                                    <button className="flex items-center justify-center min-w-[130px] px-4 rounded-full bg-slate-500 text-white font-medium border-transparent py-1.5 h-[38px] -mr-3">
                                        Search
                                    </button>
                                </div>
                            </div>
                        </div> */}

            <div className='flex flex-col md:flex-row justify-between items-center my-2 md:mx-2 md:my-2 md:p-2 gap-2 md:gap-0'>
              <div className='flex justify-between w-full items-center'>
                <div>
                  <h1 className='text-2xl md:text-3xl font-medium text-center md:mb-3 text-slate-500'>
                    Our Customers
                  </h1>
                </div>
                <div className='flex gap-2 md:hidden'>
                  <button
                    className='border text-xs sm:text-base  sm:p-2 px-3 rounded-md border-zinc-900 hover:bg-zinc-900  hover:text-white'
                    onClick={() => setShowImportContainer(true)}
                  >
                    Import
                  </button>
                  <Link to='/customers/add'>
                    <ButtonContainer icon={<IoAddOutline />}></ButtonContainer>
                  </Link>
                </div>
                {/* <div className="block md:hidden">
                                    <Link to="/customers/add">
                                        <button className="flex justify-center items-center px-1 py-1 md:py-2 md:px-4 bg-slate-500 text-white rounded-md">
                                            <span className="text-xl"><IoMdAddCircle /></span>
                                            <span className="hidden md:block">Add</span>
                                        </button>
                                    </Link>
                                </div> */}
              </div>

              <div className='flex md:gap-2 items-center w-full md:w-[40%]'>
                <div className='hidden md:flex gap-2'>
                  <button
                    className='border  p-2 px-3 rounded-md border-zinc-900 hover:bg-zinc-900  hover:text-white'
                    onClick={() => setShowImportContainer(true)}
                  >
                    Import
                  </button>
                  <Link to='/customers/add'>
                    <ButtonContainer icon={<IoAddOutline />}>
                      Create
                    </ButtonContainer>
                  </Link>
                </div>

                {/* <div className="flex items-center py-1 px-1 rounded-full bg-gray-50 border focus-within:border-gray-300 w-full"> */}
                {/* <input
                                        type="text"
                                        placeholder="Search Customer..."
                                        className="bg-transparent w-full focus:outline-none pr-4 font-semibold border-0 focus:ring-0 px-2"
                                        value={searchInput}
                                        onChange={(e) => setSearchInput(e.target.value)}
                                    />
                                    <button className="flex items-center justify-center px-1 py-1 md:px-2 md:py-2 rounded-full bg-slate-500 text-white font-medium border-transparent">
                                        <FiSearch />
                                    </button> */}
                {/* </div> */}
                <SearchContainer
                  value={searchInput}
                  placeholder={"Search Customer..."}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </div>
            </div>

            <div className='flex flex-col'>
              <div className='overflow-x-auto sm:rounded-lg h-[540px]'>
                <table className='w-full text-sm text-left rtl:text-right text-gray-500 max-h-96'>
                  <thead className='text-xs text-gray-700 uppercase bg-gray-50 sticky top-0'>
                    <tr>
                      <th scope='col' className='px-6 py-3'>
                        Name
                      </th>
                      <th scope='col' className='px-6 py-3'>
                        Email
                      </th>
                      <th scope='col' className='px-6 py-3'>
                        Phone No.
                      </th>
                    </tr>
                  </thead>
                  <tbody className='h-[100%]'>
                    {filterdata &&
                      filterdata.length > 0 &&
                      filterdata.map((customer, index) => (
                        <tr
                          key={index}
                          className={`${
                            index % 2 === 0 ? "odd:bg-white" : "even:bg-gray-50"
                          } border-b cursor-pointer`}
                          onClick={() => handleEditCustomer(customer._id)}
                        >
                          <th
                            scope='row'
                            className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap'
                          >
                            {customer.name}
                          </th>
                          <td className='px-6 py-4'>{customer.email}</td>
                          <td className='px-6 py-4'>{customer.phone}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </Container>
      {showImportContainer && (
        <PopModel setShowImportContainer={setShowImportContainer}></PopModel>
      )}
    </Layout>
  );
}

export default Customers;
