import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import CreateCatalog from "../pages/createCatalog";
import Home from "../pages/home";
// import Dashboard from "../pages/dashboard";
import Createuser from "../pages/createuser.js";
import Dashboard from "../pages/dashboard.js";
import Viewuser from "../pages/viewuser.js";
import Viewstore from "../pages/stores.js";
import { useContext, useEffect, useState } from "react";
import CreateCatalog2 from "../pages/createCatlog2";
import Analytics from "../pages/analytics.js";
import StoreDateWise from "../components/storeDateWise";
import Files from "../pages/files.js";
import Products from "../pages/products.js";
import PrivateRoute from "../config/protectedRoute.js";
import Login from "../pages/home";
import { validateToken } from "../config/validateToken.js";
import { useUser } from "../config/userProvider.js";
import { getCookie } from "../config/webStorage.js";
import ForgetPassword from "../pages/forgetPassword.js";
import UserLogin from "../pages/userLogin.js";
import Account from "../pages/account.js";
import PdfTemplate from "../pages/catalogues.js";
import Celebration from "../pages/celebration.js";
import ImageEditor from "../pages/ImageEditor.js";
import Integration from "../pages/integration.js";
import Offers from "../pages/offers.js";
import Stores from "../pages/stores.js";
import Team from "../pages/teams.js";
import Broadcasts from "../pages/broadcasts.js";
import Teams from "../pages/teams.js";
import Catalogues from "../pages/catalogues.js";
import Customers from "../pages/customers.js";
import OfferDetails from "../pages/offerDetails.js";
import UserPermissions from "../config/userPermissions.js";
import Apps from "../pages/apps.js";
import AppDetails from "../pages/appDetails.js";
import AddTeam from "../pages/addTeam.js";
import AddStore from "../pages/addStore.js";
import EditTeam from "../pages/editTeam.js";
import NotFound from "../pages/notFound.js";
import EditStore from "../pages/editStore.js";
import StoreDetails from "../pages/storeDetails.js";
import TeamDetails from "../pages/teamDetails.js";
import AddCustomer from "../pages/addCustomer.js";
import EditCustomer from "../pages/editCustomer.js";
import CustomerDetails from "../pages/customerDetails.js";
import ProductDetails from "../pages/productDetails.js";
import CreateApp from "../pages/createApp.js";
import AddProduct from "../pages/addProduct.js";
import Orders from "../pages/orders.js";
import CreateBroadcast from "../pages/createBroadcast.js";
import CreateOffer from "../pages/createOffer.js";
import Leaderboard from "../pages/leaderboard";
import UpdatePassword from "../pages/updatePassword.js";

const Router = () => {
  const { userData } = useUser();
  console.log("userData 33 routes page", userData);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<UserLogin />} />
        <Route path="/forgetpassword" element={<ForgetPassword />} />
        <Route path="/updatepassword/:userObjId/:updatePasswordToken" element={<UpdatePassword />} />

        <Route
          path="/account"
          element={<PrivateRoute element={<Account />} />}
        />
        {/* <Route
          path="/admin"
          element={<PrivateRoute element={<AdminLogin />} />}
        /> */}

        <Route
          path="/dashboard"
          element={<PrivateRoute element={<Dashboard />} />}
        />

        <Route
          path="/createcatalog"
          element={<PrivateRoute element={<CreateCatalog />} />}
        />
        <Route
          path="/imageeditor"
          element={<PrivateRoute element={<ImageEditor />} />}
        />
        {/* <Route
                path="/dashboard"
                element={<PrivateRoute element={<Dashboard />} />}
              /> */}
        <Route
          path="/createuser"
          element={<PrivateRoute element={<Createuser />} />}
        />
        {/* <Route
          path="/viewuser"
          element={<PrivateRoute element={<Viewuser />} />}
        /> */}
        <Route path="/stores" element={<PrivateRoute element={<Stores />} />} />
        <Route
          path="/stores/add"
          element={<PrivateRoute element={<AddStore />} />}
        />
        <Route
          path="/stores/:storeId/edit"
          element={<PrivateRoute element={<EditStore />} />}
        />
        <Route
          path="/stores/:storeId"
          element={<PrivateRoute element={<StoreDetails />} />}
        />
        <Route
          path="/analytics"
          element={<PrivateRoute element={<Analytics />} />}
        />
        <Route
          path="/storedatewise"
          element={<PrivateRoute element={<StoreDateWise />} />}
        />
        <Route path="/files" element={<PrivateRoute element={<Files />} />} />
        <Route
          path="/products"
          element={<PrivateRoute element={<Products />} />}
        />
        <Route
          path="/products/add"
          element={<PrivateRoute element={<AddProduct />} />}
        />
        <Route
          path="/products/:productId"
          element={<PrivateRoute element={<ProductDetails />} />}
        />
        <Route
          path="/catalogues"
          element={<PrivateRoute element={<Catalogues />} />}
        />
        <Route
          path="/celebration"
          element={<PrivateRoute element={<Celebration />} />}
        />
        <Route path="/apps" element={<PrivateRoute element={<Apps />} />} />
        <Route
          path="/integration"
          element={<PrivateRoute element={<Integration />} />}
        />
        <Route path="/teams" element={<PrivateRoute element={<Teams />} />} />
        <Route
          path="/teams/add"
          element={<PrivateRoute element={<AddTeam />} />}
        />
        <Route
          path="/teams/:userObjId"
          element={<PrivateRoute element={<TeamDetails />} />}
        />
        <Route
          path="/teams/:userObjId/edit"
          element={<PrivateRoute element={<EditTeam />} />}
        />
        <Route path="/offers" element={<PrivateRoute element={<Offers />} />} />
        <Route
          path="/offers/create"
          element={<PrivateRoute element={<CreateOffer />} />}
        />
        <Route
          path="/offers/:id"
          element={<PrivateRoute element={<OfferDetails />} />}
        />
        <Route
          path="/broadcasts"
          element={<PrivateRoute element={<Broadcasts />} />}
        />
        <Route
          path="/broadcasts/create"
          element={<PrivateRoute element={<CreateBroadcast />} />}
        />
        <Route
          path="/customers"
          element={<PrivateRoute element={<Customers />} />}
        />
        <Route
          path="/customers/add"
          element={<PrivateRoute element={<AddCustomer />} />}
        />
        <Route
          path="/customers/:customerObjId"
          element={<PrivateRoute element={<CustomerDetails />} />}
        />
        <Route
          path="/customers/:customerObjId/edit"
          element={<PrivateRoute element={<EditCustomer />} />}
        />

        <Route
          path="/apps/create"
          element={<PrivateRoute element={<CreateApp />} />}
        />
        <Route
          path="/apps/:id"
          element={<PrivateRoute element={<AppDetails />} />}
        />
        <Route path="/orders" element={<PrivateRoute element={<Orders />} />} />
        <Route
          path="/leaderboard"
          element={<PrivateRoute element={<Leaderboard />} />}
        />
        {/* <Route
          path="/stores"
          element={<PrivateRoute element={<Stores />} />}
        /> */}
        {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
