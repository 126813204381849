import React, { useState, useEffect } from "react";
import Layout, { Container } from "../components/layout";
import axios from "axios";
// import Loader from "../../../public/loader.svg"
import Loader from "../loader.svg";
import { useUser } from "../config/userProvider";
import ReactPaginate from "react-paginate";
import { FiSearch } from "react-icons/fi";
import { apiurl } from "../config/config";
import { Link, useNavigate } from "react-router-dom";
import { DynamicLoader } from "../components/loader";
import { IoMdAddCircle } from "react-icons/io";
import ButtonContainer from "../components/buttonContainer";
import { IoAddOutline } from "react-icons/io5";
import SearchContainer from "../components/searchContainer";
import { PopModel } from "../components/popupModels";
import { TbFileUpload } from "react-icons/tb";

const Stores = () => {
  const [showImportContainer, setShowImportContainer] = useState(false);

  let { userData } = useUser();
  let vendorObjId = userData?.vendorDetails?.vendorObjId;
  console.log("userData", userData);
  const navigate = useNavigate();

  const { permissions } = useUser();

  const [loading, setLoading] = useState(false);
  const [storeData, setStoreData] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const storeDetails = (id) => {
    //  console.log("STORE ID IS:", id)
    navigate(`/stores/${id}`);
  };

  useEffect(() => {
    setLoading(true);
    let data = JSON.stringify({
      vendorObjId: vendorObjId,
    });

    const config = {
      method: "post",
      url: `${apiurl}/admin/v1/store/get`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log("response.data", response.data);
        setStoreData(response?.data?.stores);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [vendorObjId]);

  const filterdata = storeData.filter(
    (store) =>
      store.storeDetails?.name
        .toLowerCase()
        .includes(searchInput.toLowerCase()) ||
      store.storeCode.toLowerCase().includes(searchInput.toLowerCase())
  );

  return (
    <Layout>
      <Container>
        {loading === true ? (
          <DynamicLoader maintext="wait" subtext="Fetching Store Data" />
        ) : (
          // <div className="bg-white/100  relative  overflow-hidden rounded-2xl px-2  h-full">
          //   <div className="flex justify-between items-center m-2 p-2">
          //     <div>
          //       <h1 className="text-3xl font-medium text-center mb-3 text-slate-500">Stores</h1>
          //     </div>
          //     <div className="flex gap-5">
          //       <Link
          //         to='/stores/add'
          //         className="flex justify-center items-center px-4 py-1 border-[1px] border-slate-200 bg-slate-500 text-white rounded-md"
          //       >
          //         Add Store
          //       </Link>

          //       <div className="flex items-center py-1 px-3 rounded-full bg-gray-50 border focus-within:border-gray-300 max-w-xl">
          //         <FiSearch className="text-gray-400 text-xl" />
          //         <input
          //           type="text"
          //           placeholder="search Store..."
          //           className="bg-transparent w-full focus:outline-none pr-4 font-semibold border-0 focus:ring-0 px-2"
          //           value={searchInput}
          //           onChange={(e) => setSearchInput(e.target.value)}
          //         />
          //         <button className="flex items-center justify-center min-w-[130px] px-4 rounded-full bg-slate-500 text-white font-medium border-transparent py-1.5 h-[38px] -mr-3">
          //           Search
          //         </button>
          //       </div>
          //     </div>
          //   </div>

          //   <div className=" pb-2 overflow-scroll relative overflow-x-auto border-2 border-slate-100 sm:rounded-lg  h-[75%] ">
          //     <table class=" w-full text-sm text-left rtl:text-right text-gray-500  ">
          //       <thead className="text-xs text-gray-700 uppercase sticky top-0">
          //         <tr class="border-b border-gray-200  bg-gray-50 ">
          //           <th scope="col" className="px-6 py-3">
          //             Sn.
          //           </th>
          //           <th scope="col" className="px-6 py-3 bg-gray-50 ">
          //             Store Name
          //           </th>
          //           <th scope="col" className="px-6 py-3">
          //             Store Code
          //           </th>
          //           <th scope="col" className="px-6 py-3 bg-gray-50 ">
          //             Store Manager Name
          //           </th>
          //           <th scope="col" className="px-6 py-3">
          //             Store Phone
          //           </th>
          //           <th scope="col" className="px-6 py-3">
          //             Store Address
          //           </th>
          //         </tr>
          //       </thead>
          //       <tbody>
          //         {storeData &&
          //           storeData?.map((item, index) => {
          //             return (
          //               <tr className="border-b last-of-type:border-none border-gray-200 cursor-pointer" onClick={() => storeDetails(item?._id)}>
          //                 <td scope="row" class="px-6 py-3">
          //                   {index + 1}
          //                 </td>
          //                 <td
          //                   scope="row"
          //                   class="px-6 py-3 font-medium text-gray-900 whitespace-nowrap bg-gray-50 "
          //                 >
          //                   {item?.storeDetails.name}
          //                 </td>
          //                 <td scope="row" class="px-6 py-3">
          //                   {item?.storeCode}
          //                 </td>
          //                 <td scope="row" class="px-6 py-3 bg-gray-50 ">
          //                   {item?.managerDetails?.name}
          //                 </td>
          //                 <td scope="row" class="px-6 py-3">
          //                   {item?.storeDetails?.phone}
          //                 </td>
          //                 <td scope="row" class="px-6 py-3">
          //                   {item?.storeDetails?.address}
          //                 </td>
          //               </tr>
          //             );
          //           })}
          //       </tbody>
          //     </table>
          //   </div>
          // </div>

          <div className="bg-white/100 relative overflow-hidden rounded-2xl px-2  h-full">
            {/* <div className="flex flex-col md:flex-row justify-between items-center m-2 p-2">
              <div>
                <h1 className="text-3xl font-medium text-center mb-3 text-slate-500">Stores</h1>
              </div>
              <div className="flex flex-col md:flex-row gap-2 md:gap-5 w-full md:w-auto">
                <Link
                  to='/stores/add'
                  className="flex justify-center items-center px-4 py-1 border-[1px] border-slate-200 bg-slate-500 text-white rounded-md"
                >
                  Add Store
                </Link>
                <div className="flex items-center py-1 px-3 rounded-full bg-gray-50 border focus-within:border-gray-300 max-w-xl w-full">
                  <FiSearch className="text-gray-400 text-xl" />
                  <input
                    type="text"
                    placeholder="search Store..."
                    className="bg-transparent w-full focus:outline-none pr-4 font-semibold border-0 focus:ring-0 px-2"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                  <button className="flex items-center justify-center min-w-[130px] px-4 rounded-full bg-slate-500 text-white font-medium border-transparent py-1.5 h-[38px] -mr-3">
                    Search
                  </button>
                </div>
              </div>
            </div> */}
            <div className="flex flex-col md:flex-row justify-between items-center my-2 md:mx-2 md:my-2 md:p-2 gap-2 md:gap-0">
              <div className="flex justify-between w-full items-center">
                <div>
                  <h1 className="text-2xl md:text-3xl font-medium text-center md:mb-3 text-slate-500">
                    Stores
                  </h1>
                </div>
                <div className="flex gap-2 md:hidden">
                  <button
                    className="border text-xs sm:text-base  sm:p-2 px-3 rounded-md border-zinc-900 hover:bg-zinc-900  hover:text-white"
                    onClick={() => setShowImportContainer(true)}
                  >
                    Import
                  </button>
                  <Link to="/stores/add" className="">
                    <ButtonContainer icon={<IoAddOutline />}></ButtonContainer>
                  </Link>
                </div>
              </div>
              <div className="flex md:gap-2 items-center w-full md:w-[40%]">
                <div className="hidden md:flex gap-2">
                  <button
                    className="border  p-2 px-3 rounded-md border-zinc-900 hover:bg-zinc-900  hover:text-white"
                    onClick={() => setShowImportContainer(true)}
                  >
                    Import
                  </button>
                  <Link to="/stores/add">
                    <ButtonContainer icon={<IoAddOutline />}>
                      Create
                    </ButtonContainer>
                  </Link>
                </div>
                <SearchContainer
                  value={searchInput}
                  placeholder={"Search Store..."}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </div>
            </div>

            <div className="pb-2 overflow-scroll relative overflow-x-auto border-2 border-slate-100 sm:rounded-lg h-[100%] md:h-[75%]">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                <thead className="text-xs text-gray-700 uppercase sticky top-0 bg-gray-50">
                  <tr className="border-b border-gray-200">
                    <th scope="col" className="px-4 py-3 sm:px-6">
                      Sn.
                    </th>
                    <th scope="col" className="px-4 py-3 sm:px-6">
                      Store Name
                    </th>
                    <th scope="col" className="px-4 py-3 sm:px-6">
                      Store Code
                    </th>
                    <th scope="col" className="px-4 py-3 sm:px-6">
                      Store Manager Name
                    </th>
                    <th scope="col" className="px-4 py-3 sm:px-6">
                      Store Phone
                    </th>
                    <th scope="col" className="px-4 py-3 sm:px-6">
                      Store Address
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filterdata &&
                    filterdata.length > 0 &&
                    filterdata.map((item, index) => (
                      <tr
                        key={item?._id}
                        className="border-b last-of-type:border-none border-gray-200 cursor-pointer"
                        onClick={() => storeDetails(item?._id)}
                      >
                        <td className="px-4 py-3 sm:px-6">{index + 1}</td>
                        <td className="px-4 py-3 sm:px-6 font-medium text-gray-900 whitespace-nowrap bg-gray-50">
                          {item?.storeDetails?.name}
                        </td>
                        <td className="px-4 py-3 sm:px-6">{item?.storeCode}</td>
                        <td className="px-4 py-3 sm:px-6 bg-gray-50">
                          {item?.managerDetails?.name}
                        </td>
                        <td className="px-4 py-3 sm:px-6">
                          {item?.storeDetails?.phone}
                        </td>
                        <td className="px-4 py-3 sm:px-6">
                          {item?.storeDetails?.address}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </Container>

      {showImportContainer && (
        <PopModel setShowImportContainer={setShowImportContainer}></PopModel>
      )}
    </Layout>
  );
};

export default Stores;
