import React, { createContext, useState } from "react";

// Create the context
export const PdfContext = createContext();

// Context provider component
export const PdfProvider = ({ children }) => {
  const [choosedPdf, setchoosedPdf] = useState("");
    // const [selectedPdfs, setSelectedPdfs] = useState(["Modern-Muse","Casual-Classics","Luxe-Looks"]);


  return (
    <PdfContext.Provider value={{ choosedPdf, setchoosedPdf}}>
      {children}
    </PdfContext.Provider>
  );
};
