import React, { useEffect, useState } from "react";
import Layout from "../components/layout.js";
import { Link, NavLink } from "react-router-dom";
import Chart from "chart.js/auto";
import { LiaLongArrowAltRightSolid } from "react-icons/lia";
import { Doughnut, Line, Pie } from "react-chartjs-2";
import { BsClockHistory } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import { CiUser } from "react-icons/ci";
import { PiFilePdfLight } from "react-icons/pi";
import { FiSearch } from "react-icons/fi";
import { useUser } from "../config/userProvider.js";
import { BiCloset } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import axios from "axios";
import { getCookie } from "../config/webStorage.js";
import { apiurl } from "../config/config.js";
import NoInternetConnection from "../components/noInternetConnection";

const Dashboard = () => {
  const {
    userData,
    storeData,
    getstoresLoading,
    setCloseProfile,
    permissions,
  } = useUser();
  let vendorObjId = userData?.vendorDetails?.vendorObjId;

  const token = getCookie("bb_token");
  const [searchInput, setSearchInput] = useState("");
  const [pieData, setPieData] = useState("");
  const [dashboardData, setDashboardData] = useState("");
  const [analyticsData, setAnalyticsData] = useState("");

  const [loadingData, setLoadingData] = useState(false);
  const data = {
    labels: ["week 1", "week 2", "week 3", "week 4"],
    datasets: [
      {
        label: "First dataset",
        data: "",
        fill: true,
        tension: 0.2,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };
  const dummyGraph = {
    labels: ["week 1", "week 2", "week 3", "week 4"],

    datasets: [
      {
        label: "loading dataset",
        data: [600, 300, 900, 1400],
        fill: true,
        tension: 0.2,
        // backgroundColor: "rgba(235, 235, 235,0.2)",
        // borderColor: "rgba(235, 235, 235,1)",
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };

  const [SearchParam] = ["storecode,Address,storename"];
  const [isOpenSearch, setIsOpenSearch] = useState(false);

  const PieData = {
    labels: ["Active User", "Total Users"],
    datasets: [
      // loadingData
      // ? {
      //     label: "loading data",
      //     data: [
      //       74, 200,
      //       // dashboardData?,
      //     ],
      //     backgroundColor: ["rgb(255,225,225,1)", "rgb(225,225,225,0.3)"],
      //     hoverOffset: 2,
      //   }
      {
        label: "user data",
        data: [
          74, 200,
          // dashboardData?,
        ],
        backgroundColor: ["rgb(255, 99, 132)", "rgb(54, 162, 235)"],
        hoverOffset: 2,
      },
    ],
  };

  useEffect(() => {
    setLoadingData(true);
    if (userData?.userType === "superadmin") {
      let data = JSON.stringify({
        vendorObjId: vendorObjId,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${apiurl}/admin/v1/analytic/report/dashboard`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          if (response.data.success === true) {
            setLoadingData(false);
            setAnalyticsData(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoadingData(false);
        });
    } else if (userData?.userType === "admin") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${apiurl}/api/analytic/get-pdf-statistics-by-storecode/M-MODTJLD2`,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          if (response.data.success === true) {
            setLoadingData(false);
            setAnalyticsData(response.data.data);
            console.log(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoadingData(false);
        });
    }
  }, []);

  // console.warn("NoInternetConnection", NoInternetConnection());
  console.log("analyticsData", analyticsData);

  return (
    <Layout>
      <div className="relative overflow-y-scroll h-full">
        <div className="flex flex-col items-start justify-between gap-1 w-full p-1 rounded-lg">
          <div className="w-full">
            <div className="flex w-full">
              <Dashboard1
                dummyGraph={dummyGraph}
                PieData={PieData}
                permissions={permissions}
                analyticsData={analyticsData}
              />
            </div>
          </div>
        </div>
      </div>
      {isOpenSearch && (
        <div className="w-full md:w-2/3 h-[80%] shadow z-30 top-1/2 left-1/2 transform translate-x-[-50%] translate-y-[-50%] pb-2 bg-white/100 absolute rounded-xl flex flex-col gap-3 overflow-hidden">
          <div className="flex w-full items-center justify-between gap-5 px-4 md:px-10 mt-3">
            <div className="flex items-center py-1 px-2 rounded-xl w-full bg-white/100 border">
              <FiSearch />
              <input
                type="text"
                placeholder="search here..."
                className="outline-none w-full px-2"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </div>
            <div
              className="border p-1 rounded-lg cursor-pointer"
              onClick={() => setIsOpenSearch(false)}
            >
              <IoClose />
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Dashboard;

const CardContainer = ({ children, imgSrc, to }) => (
  <NavLink
    className="shadow-sm rounded-lg p-2 lg:p-3 flex gap-0.5 lg:gap-2 items-center bg-white/100"
    to={to}
  >
    <img className="h-4 lg:h-7" src={imgSrc} alt="" />
    <h2 className="text-xs lg:text-base">{children}</h2>
  </NavLink>
);

const AnalyticsCardContainer = ({
  label,
  value,
  icon,
  IconBackgroundColor,
  className,
  loadingData,
}) => (
  <div
    className={`shadow-sm bg-white/100 m-1 rounded-lg md:p-4 p-3 py-3 flex flex-col w-[46.5%] md:w-[24%] ${className}`}
  >
    <div className="flex gap-1 items-center whitespace-pre">
      <div
        className={`${IconBackgroundColor} p-1 rounded-lg md:text-base text-xs`}
      >
        {icon}
      </div>
      <p className="font-medium text-[0.65rem] md:text-sm">{label}</p>
    </div>
    {loadingData ? (
      <Loading_Card />
    ) : (
      <h1 className="font-semibold text-2xl lg:text-5xl text-end">{value}</h1>
    )}
  </div>
);

const Loading_Card = ({ lines, className, ContainerclassName }) => (
  <div
    role="status"
    className={`max-w-full animate-pulse mt-2 ${ContainerclassName}`}
  >
    <div
      className={`h-full bg-gray-200 rounded-lg w-full mb-4 ${className}`}
    ></div>
  </div>
);

const Portfolio = () => {
  const portfolioData = [
    {
      asset: "Bitcoin",
      symbol: "BTC",
      icon: "🟠",
      balance: "8,323.75",
      avgBuyPrice: "14,834.25",
      profitLoss: "-8.43%",
      value: "120,224,725",
      isProfit: false,
    },
    {
      asset: "Ethereum",
      symbol: "ETH",
      icon: "⬛",
      balance: "24,323.00",
      avgBuyPrice: "2,530.79",
      profitLoss: "64.75%",
      value: "16,356,32",
      isProfit: true,
    },
    {
      asset: "Solana",
      symbol: "SOL",
      icon: "🟪",
      balance: "120,043.33",
      avgBuyPrice: "101.12",
      profitLoss: "-16.43%",
      value: "8,224,725",
      isProfit: false,
    },
    {
      asset: "Cardano",
      symbol: "ADA",
      icon: "🔵",
      balance: "12,213.434",
      avgBuyPrice: "0.5262",
      profitLoss: "834.21%",
      value: "4,434,643",
      isProfit: true,
    },
    {
      asset: "Binance",
      symbol: "BNB",
      icon: "🟡",
      balance: "8,213,434",
      avgBuyPrice: "2,223.89",
      profitLoss: "34.34%",
      value: "2,465,344",
      isProfit: true,
    },
  ];

  return (
    <div className="w-full mx-auto p-4 md:p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-lg md:text-xl font-semibold mb-4">My Portfolio</h2>
      <div className="hidden md:grid grid-cols-5 gap-4 text-gray-500 font-semibold text-sm border-b pb-2">
        <div>Asset</div>
        <div>Balance</div>
        <div>Avg. Buy Price</div>
        <div>Profit/Loss</div>
        <div>Value</div>
      </div>
      {portfolioData.map((item, index) => (
        <div
          key={index}
          className="flex flex-col md:grid md:grid-cols-5 gap-2 md:gap-4 py-4 border-b last:border-b-0 items-center"
        >
          <div className="flex items-center space-x-3">
            <span className="text-xl">{item.icon}</span>
            <div>
              <p className="font-semibold text-gray-700">{item.asset}</p>
              <p className="text-xs text-gray-400">{item.symbol}</p>
            </div>
          </div>
          <div className="text-gray-700">{item.balance}</div>
          <div className="text-gray-700">{item.avgBuyPrice}</div>
          <div
            className={`font-semibold ${
              item.isProfit ? "text-green-500" : "text-red-500"
            }`}
          >
            {item.profitLoss}
          </div>
          <div className="text-gray-700">{item.value}</div>
        </div>
      ))}
    </div>
  );
};

const Dashboard1 = ({ dummyGraph, PieData, permissions, analyticsData }) => {
  return (
    <div className="p-4 md:p-6 w-full">
      <div className="flex flex-col md:flex-row w-full gap-4">
        {/* Total Profit Section */}
        <div className="bg-white rounded-lg shadow p-4 md:p-6 w-full md:w-3/5">
          <h2 className="text-lg md:text-xl font-semibold">Today's Orders</h2>
          {/* <p className='text-xs md:text-sm text-gray-500'>
            12 January 2023 - 12 January 2024
          </p> */}
          <div className="flex items-center space-x-4 mt-4">
            <h3 className="text-2xl md:text-3xl font-bold">200</h3>
            <p className="text-base md:text-lg text-green-500 font-semibold">
              24.21%
            </p>
          </div>
          <p className="mt-2 text-sm text-gray-500">
            You have a great performance 👍
          </p>
          <div className="mt-6 grid grid-cols-2 md:grid-cols-4 gap-4">
            <StatCard
              label="Catalog Orders"
              value={analyticsData?.todayReport?.catalog_generated}
            />
            <StatCard
              label="catalog download"
              value={analyticsData?.todayReport?.catalog_downloaded}
            />
            <StatCard
              label="catalog sent"
              value={analyticsData?.todayReport?.catalog_sent}
            />
            <StatCard
              label="send on whatsapp"
              value={analyticsData?.todayReport?.catalog_sent_whatsapp}
            />
            <StatCard
              label="send on mail"
              value={analyticsData?.todayReport?.catalog_sent_email}
            />
            <StatCard
              label="Order placed"
              value={analyticsData?.todayReport?.order_placed}
            />
          </div>
        </div>

        {/* Token Allocation Section */}
        <div className="bg-white rounded-lg shadow p-4 md:p-6 w-full md:w-2/5 ">
          <h2 className="text-lg font-semibold mb-4">Store Data Details</h2>
          <div className="grid grid-cols-2 gap-3">
            <div className="bg-orange-100 rounded-lg p-3 md:p-4 col-span-1 row-span-2">
              <div className="flex justify-between items-center">
                <span className="font-medium">Catalog Orders</span>
                <span>↓</span>
              </div>
              <div className="text-lg md:text-xl font-semibold mt-2">
                {analyticsData?.todayReport?.catalog_generated}
              </div>
            </div>
            <div className="bg-purple-100 rounded-lg p-3 md:p-4">
              <div className="flex justify-between items-center">
                <span className="font-medium"> Users</span>
                <span>↓</span>
              </div>
              <div className="text-base md:text-lg font-semibold mt-2">
                {analyticsData?.todayReport?.totalUsers}
              </div>
            </div>
            <div className="bg-green-100 rounded-lg p-3 md:p-4">
              <div className="flex justify-between items-center">
                <span className="font-medium">Active Users</span>
                <span>↑</span>
              </div>
              <div className="text-base md:text-lg font-semibold mt-2">
                {analyticsData?.activeUsers}
              </div>
            </div>
            <div className="bg-blue-100 rounded-lg p-3 md:p-4">
              <div className="flex justify-between items-center">
                <span className="font-medium"> Stores</span>
                <span>↑</span>
              </div>
              <div className="text-base md:text-lg font-semibold mt-2">
                {analyticsData?.totalStores}
              </div>
            </div>
            <div className="bg-yellow-100 rounded-lg p-3 md:p-4">
              <div className="flex justify-between items-center">
                <span className="font-medium"> Products</span>
                <span>↓</span>
              </div>
              <div className="text-base md:text-lg font-semibold mt-2">
                {analyticsData?.totalProducts}
              </div>
            </div>
            <div className="bg-purple-100 rounded-lg p-3 md:p-4">
              <div className="flex justify-between items-center">
                <span className="font-medium">Catalog Download</span>
                <span>↓</span>
              </div>
              <div className="text-base md:text-lg font-semibold mt-2">
                {analyticsData?.allTimeReport?.catalog_downloaded}
              </div>
            </div>

            <div className="bg-green-100 rounded-lg p-3 md:p-4">
              <div className="flex justify-between items-center">
                <span className="font-medium">Catalog Sent</span>
                <span>↑</span>
              </div>
              <div className="text-base md:text-lg font-semibold mt-2">
                {analyticsData?.allTimeReport?.catalog_sent}
              </div>
            </div>
            <div className="bg-green-100 rounded-lg p-3 md:p-4">
              <div className="flex justify-between items-center">
                <span className="font-medium capitalize">email</span>
                <span>↑</span>
              </div>
              <div className="text-base md:text-lg font-semibold mt-2">
                {analyticsData?.allTimeReport?.catalog_sent_email}
              </div>
            </div>
            <div className="bg-green-100 rounded-lg p-3 md:p-4">
              <div className="flex justify-between items-center">
                <span className="font-medium capitalize">whatsapp</span>
                <span>↑</span>
              </div>
              <div className="text-base md:text-lg font-semibold mt-2">
                {analyticsData?.allTimeReport?.catalog_sent_whatsapp}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex w-full lg:flex-row flex-col gap-3 lg:gap-3 mt-3 ">
        <div
          className={`shadow-sm  rounded-lg lg:w-2/3 ${
            permissions?.analytics === true && "lg:w-2/3"
          } w-full  bg-white/100  p-3`}
        >
          <Line data={dummyGraph} style={{ height: "", width: "" }} />
        </div>

        <div className="shadow-sm  rounded-lg h-auto lg:w-1/3 p-2   bg-white/100 ">
          <Doughnut data={PieData} />
        </div>

        {/* <div className="shadow-sm my-2 rounded-lg lg:w-1/3 h-[30rem] w-full  bg-white/100 lg:hidden block overflow-hidden">
                
                <div className="flex items-center py-1 px-2  rounded-xl  w-full bg-white/100 border ">
                  <FiSearch />
                  <input
                    type="text"
                    placeholder="search here..."
                    className="outline-none w-full px-2  "
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                </div>
                
                <div className=" overflow-y-scroll h-4/5  flex flex-col p-2 gap-2 w-full">
                  {filteredStores.length > 0 && searchInput
                    ? filteredStores.map((item, index) => (
                        <div className=" bg-zinc-50 shadow-sm rounded-lg p-2 border w-full">
                          <div className="flex gap-2 text-sm">
                            <p className="font-medium ">Store Name:</p>
                            <p>{item.storename}</p>
                          </div>
                          <div className="flex gap-2 text-sm">
                            <p className="font-medium ">Store Code:</p>
                            <p>{item.storecode}</p>
                          </div>
                          <div className="flex gap-2 text-sm">
                            <p className="font-medium ">Address:</p>
                            <p>{item.Address}</p>
                          </div>
                        </div>
                      ))
                    : storeData &&
                      storeData.map(
                        (item, index) =>
                          index < 5 && (
                            <div className=" bg-zinc-50 shadow-sm rounded-lg p-2 border">
                              <div className="flex gap-2 text-sm">
                                <p className="font-medium ">Store Name:</p>
                                <p>{item.storename}</p>
                              </div>
                              <div className="flex gap-2 text-sm">
                                <p className="font-medium ">Store Code:</p>
                                <p>{item.storecode}</p>
                              </div>
                            </div>
                          )
                      )}
                </div>
                {storeData && (
                  <div className="flex justify-end">
                    <NavLink
                      to="/admin/viewstore"
                      className="bg-black text-white px-3 py-2 rounded-lg mt-3 lg:text-base text-xs m-2"
                    >
                      View store data
                    </NavLink>
                  </div>
                )}
              </div> */}
      </div>

      {/* Portfolio Section */}
      {/* <div className="flex flex-col md:flex-row mt-4 gap-4">
        <div className="bg-white rounded-lg shadow p-4 md:p-6 w-full md:w-3/5">
          <h2 className="text-lg md:text-xl font-semibold mb-4">
            My portfolio
          </h2>
          <div className="hidden md:grid grid-cols-5 gap-4 text-gray-500 font-semibold text-sm border-b pb-2 ">
            <div>Asset</div>
            <div>Balance</div>
            <div>Avg. Buy Price</div>
            <div>Profit/Loss</div>
            <div>Value</div>
          </div>
          <PortfolioRow
            asset="Bitcoin"
            symbol="BTC"
            balance="8,323.75"
            avgBuyPrice="14,834.25"
            profitLoss="-8.43%"
            value="120,224,725"
            isProfit={false}
          />
          <PortfolioRow
            asset="Ethereum"
            symbol="ETH"
            balance="24,323.00"
            avgBuyPrice="2,530.79"
            profitLoss="64.75%"
            value="16,356,32"
            isProfit={true}
          />
          <PortfolioRow
            asset="Solana"
            symbol="SOL"
            balance="120,043.33"
            avgBuyPrice="101.12"
            profitLoss="-16.43%"
            value="
            8,224,725"
            isProfit={false}
          />
        </div>
        <div className="w-full md:w-2/5 bg-white rounded-lg shadow p-4 md:p-6">
          <h2 className="text-lg md:text-xl font-semibold mb-4">Calendar</h2>
          <div className="flex items-center justify-between mb-4">
            <button className="text-gray-600 hover:text-gray-800">
              <svg
                className="w-4 h-4 md:w-5 md:h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </button>
            <h3 className="text-base md:text-lg font-medium text-gray-800">
              March 2024
            </h3>
            <button className="text-gray-600 hover:text-gray-800">
              <svg
                className="w-4 h-4 md:w-5 md:h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </button>
          </div>
          <div className="grid grid-cols-7 gap-2 text-xs md:text-sm font-medium text-gray-500 mb-2">
            <div>Sun</div>
            <div>Mon</div>
            <div>Tue</div>
            <div>Wed</div>
            <div>Thu</div>
            <div>Fri</div>
            <div>Sat</div>
          </div>
          <div className="grid grid-cols-7 gap-2">
            {[...Array(31)].map((_, i) => (
              <div
                key={i}
                className="aspect-square flex items-center justify-center text-xs md:text-sm rounded-full hover:bg-gray-100 cursor-pointer"
              >
                {i + 1}
              </div>
            ))}
          </div>
        </div>
      </div> */}
    </div>
  );
};

// Helper Components
const StatCard = ({ label, value }) => (
  <div className="text-center w-full shadow rounded-md">
    <p className="text-xs md:text-sm text-gray-500">{label}</p>
    <p className="text-base md:text-lg font-semibold text-gray-800">{value}</p>
  </div>
);

const TokenAllocation = ({ name, value }) => (
  <div className='p-3 md:p-4 bg-gray-100 rounded-md text-center'>
    <p className='text-base md:text-lg font-semibold text-gray-800'>{name}</p>
    <p className='text-sm md:text-base text-gray-500'>{value}</p>
  </div>
);

const PortfolioRow = ({
  asset,
  symbol,
  balance,
  avgBuyPrice,
  profitLoss,
  value,
  isProfit,
}) => (
  <div className='flex flex-col md:grid md:grid-cols-5 gap-2 md:gap-4 py-4 border-b last:border-b-0  overflow-auto'>
    <div className='flex items-center space-x-3'>
      <div className='font-bold text-sm md:text-base'>{asset}</div>
      <p className='text-xs text-gray-400'>{symbol}</p>
    </div>
    <div className='text-sm md:text-base text-gray-700'>{balance}</div>
    <div className='text-sm md:text-base text-gray-700'>{avgBuyPrice}</div>
    <div
      className={`text-sm md:text-base font-semibold ${
        isProfit ? "text-green-500" : "text-red-500"
      }`}
    >
      {profitLoss}
    </div>
    <div className='text-sm md:text-base text-gray-700'>{value}</div>
  </div>
);

const RetentionGrid = () => (
  <div className='grid grid-cols-3 md:grid-cols-7 gap-2 mt-4 text-center'>
    {["Jan", "Feb", "Mar", "Apr", "May", "Jun"].map((month, index) => (
      <div
        key={index}
        className='p-2 bg-blue-100 text-blue-800 rounded-lg text-xs md:text-sm'
      >
        {month}
      </div>
    ))}
  </div>
);

// export default Dashboard;
