import React, { useEffect, useState } from 'react';
import { Document, Page, Text, View, Image, StyleSheet, Font } from '@react-pdf/renderer';
import { PDFDownloadLink } from '@react-pdf/renderer';
// import fpageimage from "../assets/fpageimage.jpg";


// Register fonts
Font.register({
    family: 'DM Serif Text',
    fonts: [
        {
            src: 'https://fonts.gstatic.com/s/dmseriftext/v11/rnCu-xZa_krGokaujeo4DLUX0A5UdkfTBk9TMtkS.woff2',
            fontStyle: 'normal',
            fontWeight: '400',
        },
        {
            src: 'https://fonts.gstatic.com/s/dmseriftext/v11/rnCu-xZa_krGokaujeo4DLUX0A5UdkfTBk9SMtkS.woff2',
            fontStyle: 'italic',
            fontWeight: '400',
        },
    ],
});

// Define styles
const styles = StyleSheet.create({
    firstPage: {
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        position: 'relative',
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: '30px 40px',
    },
    title: {
        fontSize: 48,
        fontWeight: 'bold',
        color: '#b3b3b3'
    },
    titleColored: {
        color: '#b08d83',
    },
    date: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontSize: 24,
        fontWeight: 'bold',
        color: '#b3b3b3'
    },
    mainImageContainer: {
        marginBottom: 20,
        position: 'relative',
        margin: '0px 40px',
        border: '7px solid white',
    },
    mainImage: {
        width: '100%',
        height: '500px',
        objectFit: 'cover'
    },
    tiltedLine: {
        position: 'absolute',
        width: '200%',
        height: 2,
        backgroundColor: '#c67792',
        transform: 'rotate(-10deg)',
    },
    topLine: {
        bottom: '38%',
        left: '-50%',
    },
    bottomLine: {
        bottom: '36%',
        left: '-50%',
    },
    pinkBox: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        left: '-50%',
        backgroundColor: '#c67792',
        transform: 'rotate(-10deg)',
        transformOrigin: 'top right',
        height: '40%',
    },



    footer: {
        flexDirection: 'row',
        alignItems: 'center',
        color: 'white',
        margin: '40px 40px 0px 40px',
        justifyContent: 'space-between'
    },
    footerHeadingContainer: {
        maxWidth: '70%',
    },
    footerHeading: {
        fontSize: 24,
        marginBottom: 20,
    },
    footerDescription: {
        fontSize: 15,
    },
    number: {
        fontSize: 40,
        fontWeight: 'bold',
        marginRight: 50,
        display: 'flex',
        alignItems: 'flex-start',
    },
    feature: {
        position: 'absolute',
        bottom: 300,
        right: 15,
        fontSize: 10,
        textAlign: 'center',
        transform: 'rotate(-90deg)',
        transformOrigin: 'bottom right',
        color: 'white'
    },
    // For Second page
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        position: 'relative',
    },
    secondPage: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
    },
    secondPageLine: {
        // position: 'absolute',
        width: '70%',
        height: 2,
        backgroundColor: '#c67792',
    },
    upperLine: {
        top: '30%',
        left: '15%',
    },
    lowerLine: {
        top: '40%',
        left: '15%',
    },
    contentHeadingContainer: {
        top: '32%',
        left: '15%',
        color: '#b3b3b3',
    },
    contentName: {
        fontSize: 30,
        fontWeight: 'bold',
    },
    secondPageImageContainer: {
        top: '40%',
        left: '15%',
        width: '70%',
        height: '100px',
    },
    secondPageImage: {
        width: '100%',
        height: '100%',
        // objectFit: 'cover'
    },

    // For Third page
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        position: 'relative',
    },

    backgroundImageContainer: {
        top: '5%',
        left: '5%',
    },
    backgroundImage: {
        width: '90%',
        height: '95%',
        objectFit: 'cover'
    },
    thirdPageUpperContainer: {
        position: 'absolute',
        top: '0%',
        left: '10%',
        width: '80%',
        height: '100%',
        backgroundColor: '#c67792',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    contentContainer: {
        width: '90%',
        height: '90%',
        border: '2px solid white',
        display: 'flex',
        flexDirection: 'column',
        padding: '20% 10%',
        color: 'white',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
    },
    contentFirstContainer: {
        width: '10%',
        marginRight: '10%',
        display: 'flex',
        alignItems: 'center',
    },
    contentSecondContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '70%',
    },
    contentNumber: {
        fontSize: 30,
        fontWeight: 'bold',
    },
    contentHeading: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 15,
    },
    contentDescription: {
        fontSize: 11,
    },
});

export const MyDocument3 = ({ jsonData }) => {

    const [Data, setData] = useState();

    useEffect(() => {
        setData(jsonData);
    }, [jsonData]);

    const currentDate = new Date();
    const day = currentDate.getDate(); // Get current day (e.g., 10)
    const month = currentDate.toLocaleString('en-US', { month: 'short' }).toUpperCase(); // Get current month (e.g., DEC)

    console.log("This is the data of Casual-Classics :=>", Data);
    console.log("IMAGE ================================================================>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", Data?.Product?.[0]?.image)

    return (<Document>
        {/* <Page size="A4" style={styles.firstPage}>
            <View style={[styles.tiltedLine, styles.topLine]} />
            <View style={[styles.tiltedLine, styles.bottomLine]} />
            <View style={styles.pinkBox}></View>
            <View style={styles.header}>
                <Text style={styles.title}>
                    The{"\n"}
                    <Text style={styles.titleColored}>InfiConnect</Text>
                </Text>
                <View style={styles.date}>
                    <Text >{day}</Text>
                    <Text style={styles.titleColored}>{month}</Text>
                </View>

            </View>
            <View style={styles.mainImageContainer}>
                <Image
                    style={styles.mainImage}
                    src={Data?.Product?.[0]?.image}
                />
            </View>
            <View style={styles.footer}>
                <View>
                    <Text style={styles.number}>25</Text>
                </View>
                <View style={styles.footerHeadingContainer}>
                    <Text style={styles.footerHeading}>{Data?.Product?.[0]?.title}</Text>
                    <Text style={styles.footerDescription}>{Data?.Product?.[0]?.description}</Text>
                </View>
            </View>
            <View style={styles.feature}>
                <Text>Lorem ipsum dolor sit amet,</Text>
                <Text>consectetur adipiscing elit.</Text>
            </View>
        </Page> */}

        <Page size="A4" style={styles.page}>
            <View style={[styles.secondPageLine, styles.upperLine]} />
            <View style={[styles.secondPageLine, styles.lowerLine]} />

            <View style={styles.contentHeadingContainer}>
                <Text>Let's Connect by</Text>
                <Text style={[styles.titleColored, styles.contentName]}>INFI CONNECT</Text>
            </View>

            <View style={styles.secondPageImageContainer}>
                <Image
                    style={styles.secondPageImage}
                    // src={Data?.Product?.[1]?.image}
                    src="logo.png"
                />
            </View>
        </Page>

        {Data?.Product?.map((product, index) => (
            <Page key={index} size="A4" style={styles.firstPage}>
                <View style={[styles.tiltedLine, styles.topLine]} />
                <View style={[styles.tiltedLine, styles.bottomLine]} />
                <View style={styles.pinkBox} />
                <View style={styles.header}>
                    <Text style={styles.title}>
                        The{"\n"}
                        <Text style={styles.titleColored}>InfiConnect</Text>
                    </Text>
                    <View style={styles.date}>
                        <Text>{day}</Text>
                        <Text style={styles.titleColored}>{month}</Text>
                    </View>
                </View>
                <View style={styles.mainImageContainer}>
                    <Image style={styles.mainImage} src={product.image} />
                </View>
                <View style={styles.footer}>
                    <View>
                        <Text style={styles.number}>{product?.variants?.[0]?.price} rs.</Text>
                    </View>
                    <View style={styles.footerHeadingContainer}>
                        <Text style={styles.footerHeading}>{product.title}</Text>
                        {/* <Text style={styles.footerDescription}>{product.description}</Text> */}
                    </View>
                </View>
                <View style={styles.feature}>
                    <Text>Lorem ipsum dolor sit amet,</Text>
                    <Text>consectetur adipiscing elit.</Text>
                </View>
            </Page>
        ))}





        <Page size="A4" style={styles.page}>
            <View style={styles.backgroundImageContainer}>
                <Image
                    style={styles.backgroundImage}
                    src={Data?.Product?.[2]?.image}
                />
            </View>
            <View style={styles.thirdPageUpperContainer}>
                <View style={styles.contentContainer}>
                    <View style={styles.content}>
                        <View style={styles.contentFirstContainer}>
                            <Text style={styles.contentNumber}>01</Text>
                        </View>
                        <View style={styles.contentSecondContainer}>
                            <Text style={styles.contentHeading}>Content One</Text>
                            <Text style={styles.contentDescription}>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC.</Text>
                        </View>
                    </View>
                    <View style={styles.content}>
                        <View style={styles.contentFirstContainer}>
                            <Text style={styles.contentNumber}>02</Text>
                        </View>
                        <View style={styles.contentSecondContainer}>
                            <Text style={styles.contentHeading}>Content Two</Text>
                            <Text style={styles.contentDescription}>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC.</Text>
                        </View>
                    </View>
                    <View style={styles.content}>
                        <View style={styles.contentFirstContainer}>
                            <Text style={styles.contentNumber}>03</Text>
                        </View>
                        <View style={styles.contentSecondContainer}>
                            <Text style={styles.contentHeading}>Content Three</Text>
                            <Text style={styles.contentDescription}>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC.</Text>
                        </View>
                    </View>
                    <View style={styles.content}>
                        <View style={styles.contentFirstContainer}>
                            <Text style={styles.contentNumber}>04</Text>
                        </View>
                        <View style={styles.contentSecondContainer}>
                            <Text style={styles.contentHeading}>Content Four</Text>
                            <Text style={styles.contentDescription}>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC.</Text>
                        </View>
                    </View>

                </View>
            </View>
            {/* <View style={[styles.secondPageLine, styles.upperLine]} />
        <View style={[styles.secondPageLine, styles.lowerLine]} />

        <View style={styles.contentHeadingContainer}>
            <Text>Table Of</Text>
            <Text style={[styles.titleColored, styles.contentHeading]}>Content</Text>
        </View> */}

        </Page>
    </Document>);


};


// const PdfGenerator = () => (
//     <div>

//         {/* <Viewer>
//       <MyDocument />
//     </Viewer> */}
//         <PDFDownloadLink document={<MyDocument />} fileName="anything.pdf">
//             {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
//         </PDFDownloadLink>
//     </div>
// );

export default MyDocument3;
