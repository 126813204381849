import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";

// Register fonts
Font.register({
  family: "DM Serif Text",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/dmseriftext/v11/rnCu-xZa_krGokaujeo4DLUX0A5UdkfTBk9TMtkS.woff2",
      fontStyle: "normal",
      fontWeight: "400",
    },
    {
      src: "https://fonts.gstatic.com/s/dmseriftext/v11/rnCu-xZa_krGokaujeo4DLUX0A5UdkfTBk9SMtkS.woff2",
      fontStyle: "italic",
      fontWeight: "400",
    },
  ],
});

// Define styles
const styles = StyleSheet.create({
  page: {
    // flexDirection: 'column',
    backgroundColor: "#FFFFFF",
    position: "relative",
  },
  upperFirstBox: {
    height: "10%",
    width: "15%",
    position: "absolute",
    top: "0",
    right: "0",
    backgroundColor: "#f48e57",
    // background: 'linear-gradient(to bottom, #f48e57, #e67241)',
  },
  upperSecondBox: {
    height: "5%",
    width: "7.5%",
    position: "absolute",
    top: "10%",
    right: "15%",
    backgroundColor: "#2d2d2d",
  },
  firstPageHeadingContainer: {
    position: "absolute",
    top: "15%",
    left: "20%",
    width: "50%",
    color: "#010101",
  },
  heading: {
    fontSize: 70,
    // fontWeight:'ultrabold',
    fontWeight: "1000",
  },
  subheading: {
    fontSize: 40,
    fontWeight: "bold",
  },
  description: {
    fontSize: 15,
    // fontWeight: 'bold',
  },
  firstPageImageContainer: {
    position: "relative",
    top: "45%",
    left: "20%",
  },
  firstPageImage: {
    width: "60%",
    height: "60%",
    objectFit: "cover",
  },
  bottomBox: {
    position: "absolute",
    top: "45%",
    left: "0%",
    width: "5%",
    height: "36.5%",
    backgroundColor: "#2d2d2d",
  },
  // bottomLineContainer: {
  //     position: 'absolute',
  //     bottom: '5%',
  //     left: '0',
  //     width: '100%',
  //     display: 'flex',
  //     flexDirection: 'row',
  // },
  // bottomLine: {
  //     width: '70%',
  //     height: 2,
  //     backgroundColor: '#cbcbcb',
  // },
  // bottomLineDescription: {
  //     fontSize: 10,
  //     marginLeft: '15%',
  //     paddingBottom: '5%',
  //     color: '#cbcbcb',
  // },
  bottomLine: {
    position: "absolute",
    bottom: "5%",
    left: "0",
    width: "70%",
    height: 2,
    backgroundColor: "#cbcbcb",
  },
  bottomLineDescription: {
    position: "absolute",
    bottom: "4.6%",
    left: "75%",
    fontSize: 10,
    // marginLeft: '15%',
    // paddingBottom: '5%',
    color: "#cbcbcb",
  },
  //For second Page ==>>
  secondPageUpperFirstBox: {
    height: "2.5%",
    width: "25%",
    position: "absolute",
    top: "7.5%",
    left: "10%",
    backgroundColor: "#f48e57",
    // background: 'linear-gradient(to bottom, #f48e57, #e67241)',
  },
  secondPageUpperSecondBox: {
    height: "10%",
    width: "25%",
    position: "absolute",
    top: "0%",
    right: "10%",
    backgroundColor: "#2d2d2d",
  },
  secondPageHeadingContainer: {
    position: "absolute",
    top: "15%",
    left: "10%",
    width: "50%",
    color: "#010101",
  },
  secondPageImageContainer: {
    position: "relative",
    top: "35%",
    left: "10%",
  },
  secondPageImage: {
    width: "55%",
    height: "60%",
    objectFit: "cover",
  },
  secondPageRotatedDescriptionContainer: {
    position: "absolute",
    top: "54%",
    left: "40%",
    fontSize: 15,
    textAlign: "center",
    transform: "rotate(90deg)",
    transformOrigin: "bottom right",
    color: "#010101",
  },
  secondPageBottomBox: {
    position: "absolute",
    bottom: "0",
    right: "10%",
    height: "15%",
    width: "25%",
    backgroundColor: "#2d2d2d",
  },
  secondPageDescriptionContainer: {
    position: "absolute",
    top: "75%",
    left: "10%",
    width: "50%",
    color: "#010101",
  },
  //For third Page ==>>
  thirdPageFrontImageContainer: {
    position: "relative",
    top: "-10%",
    left: "0%",
  },
  thirdPageFrontImage: {
    width: "30%",
    height: "70%",
    objectFit: "cover",
  },
  thirdPageBackImageContainer: {
    position: "relative",
    top: "7%",
    left: "10%",
  },
  thirdPageBackImage: {
    width: "70%",
    height: "80%",
    objectFit: "cover",
  },
  thirdPageBottomBox: {
    position: "absolute",
    bottom: "0",
    right: "20%",
    height: "15%",
    width: "40%",
    backgroundColor: "#f48e57",
  },
  thirdPageHeadingContainer: {
    position: "absolute",
    top: "55%",
    right: "20%",
    width: "40%",
    color: "#010101",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  thirdPageHeading: {
    fontSize: 70,
    fontWeight: "1000",
  },
  thirdPageSubheading: {
    fontSize: 40,
    fontWeight: "bold",
  },
  thirdPageDescriptionContainer: {
    position: "absolute",
    top: "69%",
    right: "20%",
    width: "40%",
    color: "#010101",
  },
  thirdPageDescription: {
    fontSize: 15,
    textAlign: "right",
  },
  //For fourth Page ==>>
  fourthPageMainHeadingContainer: {
    position: "absolute",
    top: "5%",
    left: "10%",
    width: "40%",
    height: "31%",
    color: "#010101",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  fourthPageHeading: {
    fontSize: 70,
    fontWeight: "1000",
  },
  fourthPageSubheading: {
    fontSize: 40,
    fontWeight: "bold",
  },
  fourthPageDescriptionContainer: {
    color: "#010101",
  },
  fourthPageDescription: {
    fontSize: 15,
    textAlign: "left",
  },
  fourthPageSecondDescriptionContainer: {
    color: "#010101",
  },
  fourthPageSecondDescription: {
    fontSize: 15,
    fontWeight: "ultrabold",
  },
  fourthPageUpperImageContainer: {
    position: "relative",
    top: "0%",
    right: "-60%",
  },
  fourthPageUpperImage: {
    width: "30%",
    height: "62%",
    objectFit: "cover",
  },
  fourthPageBottomImageContainer: {
    position: "relative",
    top: "-3%",
    left: "10%",
  },
  fourthPageBottomImage: {
    width: "65%",
    height: "65%",
    objectFit: "cover",
  },
  fourthPageBottomBox: {
    position: "absolute",
    top: "45.5%",
    right: "0%",
    width: "5%",
    height: "38.7%",
    backgroundColor: "#f48e57",
  },

  //For fifth Page ==>>
  fifthPageUpperBox: {
    height: "70%",
    width: "70%",
    position: "absolute",
    top: "0",
    left: "10%",
    backgroundColor: "#f48e57",
  },
  fifthPageImageContainer: {
    position: "relative",
    top: "10%",
    left: "0",
  },
  fifthPageImage: {
    width: "85%",
    height: "75%",
    objectFit: "cover",
  },
  fifthPageUpperHeadingContainer: {
    position: "absolute",
    top: "70%",
    left: "10%",
    width: "70%",
    color: "white",
  },
  fifthPageUpperHeading: {
    fontSize: 40,
    // fontWeight:'ultrabold',
    fontWeight: "1000",
  },
  fifthPageUpperDescription: {
    fontSize: 15,
    // fontWeight: 'bold',
  },
  fifthPageLowerHeadingContainer: {
    position: "absolute",
    top: "75%",
    left: "16.5%",
    width: "30%",
    color: "#010101",
  },

  fifthPageLowerSubheading: {
    fontSize: 20,
    fontWeight: "bold",
  },
  fifthPageLowerDescription: {
    fontSize: 13,
    // fontWeight: 'bold',
    marginTop: 10,
  },
  fifthPageBottomBox: {
    position: "absolute",
    bottom: "5%",
    right: "10%",
    height: "25%",
    width: "40%",
    backgroundColor: "#2d2d2d",
  },

  //For fifth Page ==>>
  sixthPageUpperContainer: {},
  sixthPageFirstBox: {
    height: "2.5%",
    // height: '5%',
    width: "40%",
    position: "absolute",
    top: "10%",
    left: "10%",
    backgroundColor: "#f48e57",
    // background: 'linear-gradient(to bottom, #f48e57, #e67241)',
  },
  sixthPageHeadingContainer: {
    position: "absolute",
    top: "17%",
    left: "10%",
    width: "40%",
  },
  sixthPageHeading: {
    fontSize: 30,
    // fontWeight:'ultrabold',
    fontWeight: "1000",
  },
  sixthPageDescription: {
    fontSize: 12,
    // fontWeight: 'bold',
    marginTop: 15,
  },
  SixthPageUpperImageContainer: {
    position: "relative",
    top: "10%",
    left: "60%",
  },
  SixthPageUpperImage: {
    width: "30%",
    height: "50%",
    objectFit: "cover",
  },
  SixthPageBottomImageContainer: {
    position: "relative",
    top: "0%",
    left: "10%",
  },
  SixthPageBottomImage: {
    width: "80%",
    height: "60%",
    objectFit: "cover",
  },
  sixthPageBottomBoxContainer: {
    position: "absolute",
    top: "55%",
    right: "0%",
    width: "70%",
    height: "35%",
  },
  sixthPageBottomBoxUpperBox: {
    // width: '100%',
    height: "90%",
    backgroundColor: "#c1c1c1",
  },
  sixthPageBottomBoxLowerBox: {
    // width: '100%',
    height: "10%",
    backgroundColor: "#f48e57",
  },

  //For Seventh Page ==>>
  seventhPageUpperBox: {
    position: "absolute",
    top: "0%",
    left: "0%",
    width: "5%",
    height: "40%",
    backgroundColor: "#2d2d2d",
  },
  seventhPageHeadingContainer: {
    position: "absolute",
    top: "10%",
    left: "20%",
    width: "50%",
    height: "40%",
  },
  seventhPageHeading: {
    fontSize: 40,
    // fontWeight:'ultrabold',
    fontWeight: "1000",
  },
  seventhPageDescription: {
    fontSize: 15,
    // fontWeight: 'bold',
    marginTop: 10,
  },
  seventhPageSecondDescription: {
    fontSize: 15,
    marginTop: 30,
  },
  seventhPageRotatedDescriptionContainer: {
    position: "absolute",
    top: "75%",
    left: "-20%",
    // height: '70%',
    // width:'20%',
    fontSize: 15,
    // display:'flex',
    // justifyContent:'center',
    // alignItems:'center',
    textAlign: "center",
    transform: "rotate(90deg)",
    transformOrigin: "bottom right",
    color: "#010101",
  },
  seventhPageRotatedDescription: {
    fontWeight: "bold",
  },
  seventhPageImageContainer: {
    position: "relative",
    top: "45%",
    left: "20%",
    width: "416.5px",
    height: "547.3px",
  },
  seventhPageImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  seventhPageBottomBox: {
    position: "absolute",
    bottom: "5%",
    left: "10%",
    height: "5%",
    width: "20%",
    backgroundColor: "#f48e57",
  },

  //For Eight Page ==>>
  eightPageFrontImageContainer: {
    position: "relative",
    top: "-10%",
    left: "0%",
  },
  eightPageFrontImage: {
    width: "40%",
    height: "80%",
    objectFit: "cover",
  },
  eightPageBackImageContainer: {
    position: "relative",
    top: "7%",
    left: "10%",
  },
  eightPageBackImage: {
    width: "70%",
    height: "70%",
    objectFit: "cover",
  },
  eightPageBottomBox: {
    position: "absolute",
    bottom: "0%",
    left: "0%",
    height: "5%",
    width: "40%",
    backgroundColor: "#f48e57",
  },
  eightPageHeadingContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "40%",
    color: "#010101",
    display: "flex",
    flexDirection: "column",
    // alignItems: 'flex-start',
  },
  eightPageHeading: {
    fontSize: 40,
    fontWeight: "1000",
    marginTop: 60,
  },
  eightPageFirstDescription: {
    fontSize: 15,
    // textAlign: 'right'
    fontWeight: "bold",
  },
  eightPageSecondDescription: {
    fontSize: 15,
    // textAlign: 'right'
    marginTop: 20,
  },
  priceText: {
    marginTop: 2,
  }
});

export const MyDocument2 = ({ jsonData }) => {

  const [Data, setData] = useState();

  useEffect(() => {
    setData(jsonData);
  }, [jsonData]);

  console.log("This is the data of Modern-Muse :=>", Data);
  console.log("IMAGE ================================================================>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", Data?.Product?.[0]?.image)

  return (
    <Document>

      {Data?.Product?.length > 0 && (
        <Page size="A4" style={styles.page}>
          <View style={[styles.upperFirstBox]} />
          <View style={[styles.upperSecondBox]} />
          <View style={styles.firstPageHeadingContainer}>
            <Text style={styles.subheading}>PHOTO</Text>
            <Text style={styles.heading}>Book</Text>
            {/* <Text>{Data?.Store?.managername}</Text> */}
            <Text>{Data?.Product?.[0]?.title}</Text>
            <Text style={styles.priceText}>{Data?.Product?.[0]?.variants?.[0]?.price} rs.</Text>
          </View>

          <View style={styles.firstPageImageContainer}>
            <Image style={styles.firstPageImage} src={Data?.Product?.[0]?.image} />
          </View>
          <View style={[styles.bottomBox]} />
          <View style={[styles.bottomLine]} />
          <View style={styles.bottomLineDescription}>
            <Text>consectetur adipiscing elit.</Text>
          </View>
        </Page>
      )}
      {/* Second Page => */}
      {Data?.Product?.length > 1 && (
        <Page size="A4" style={styles.page}>
          <View style={[styles.secondPageUpperFirstBox]} />
          <View style={[styles.secondPageUpperSecondBox]} />
          <View style={styles.secondPageHeadingContainer}>
            <Text style={styles.heading}>Fashion</Text>
            <Text style={styles.subheading}>DESIGN</Text>
          </View>
          <View style={styles.secondPageImageContainer}>
            <Image
              style={styles.secondPageImage}
              src={Data?.Product?.[1]?.image}
            />
          </View>

          <View style={styles.secondPageRotatedDescriptionContainer}>
            <Text style={styles.rotatedDescription}>
              {Data?.Product?.[1]?.title}
            </Text>
          </View>
          <View style={styles.secondPageDescriptionContainer}>
            <Text style={styles.description}>
              consectetur adipiscing elit.consectetur adipiscing elit.consectetur
              adipiscing elit.consectetur adipiscing elit.
            </Text>
            <Text style={styles.priceText}>{Data?.Product?.[1]?.variants?.[0]?.price} rs.</Text>
          </View>
          <View style={[styles.secondPageBottomBox]} />
          <View style={[styles.bottomLine]} />
          <View style={styles.bottomLineDescription}>
            <Text>consectetur adipiscing elit.</Text>
          </View>
        </Page>
      )}
      {/* Third Page => */}
      {Data?.Product?.length > 2 && (
        <Page size="A4" style={styles.page}>
          <View style={styles.thirdPageBackImageContainer}>
            <Image
              style={styles.thirdPageBackImage}
              src={Data?.Product?.[2]?.image}
            />
          </View>
          <View style={styles.thirdPageFrontImageContainer}>
            <Image
              style={styles.thirdPageFrontImage}
              src={Data?.Product?.[2]?.image}
            />
          </View>

          <View style={styles.thirdPageHeadingContainer}>
            <Text style={styles.thirdPageHeading}>Book</Text>
            {/* <Text style={styles.thirdPagesSubheading}>PHOTO</Text> */}
            <Text style={styles.thirdPagesSubheading}>{Data?.Product?.[2]?.title}</Text>
            <Text style={styles.priceText}>{Data?.Product?.[2]?.variants?.[0]?.price} rs.</Text>

          </View>
          <View style={styles.thirdPageDescriptionContainer}>
            <Text style={styles.thirdPageDescription}>
              consectetur adipiscing elit.consectetur adipiscing elit.consectetur
              adipiscing elit.consectetur adipiscing elit.
            </Text>
          </View>
          <View style={[styles.thirdPageBottomBox]} />
          <View style={[styles.bottomLine]} />
          <View style={styles.bottomLineDescription}>
            <Text>consectetur adipiscing elit.</Text>
          </View>
        </Page>
      )}
      {/* Fourth Page => */}
      {Data?.Product?.length > 3 && (
        <Page size="A4" style={styles.page}>
          <View style={styles.fourthPageUpperImageContainer}>
            <Image
              style={styles.fourthPageUpperImage}
              src={Data?.Product?.[3]?.image}
            />
          </View>
          <View style={styles.fourthPageMainHeadingContainer}>
            <View style={styles.fourthPageHeadingContainer}>
              <Text style={styles.fourthPageHeading}>Book</Text>
              {/* <Text style={styles.fourthPagesSubheading}>PHOTO</Text> */}
              <Text style={styles.fourthPagesSubheading}>{Data?.Product?.[3]?.title}</Text>
              <Text style={styles.priceText}>{Data?.Product?.[3]?.variants?.[0]?.price} rs.</Text>

            </View>
            <View style={styles.fourthPageDescriptionContainer}>
              <Text style={styles.fourthPageDescription}>
                consectetur adipiscing elit.consectetur adipiscing elit.consectetur
                adipiscing elit.consectetur adipiscing elit.
              </Text>
            </View>
            <View style={styles.fourthPageSecondDescriptionContainer}>
              <Text style={styles.fourthPageSecondDescription}>
                consectetur adipiscing elit.
              </Text>
            </View>
          </View>
          <View style={styles.fourthPageBottomImageContainer}>
            <Image
              style={styles.fourthPageBottomImage}
              src={Data?.Product?.[3]?.image}
            />
          </View>
          <View style={[styles.fourthPageBottomBox]} />
          <View style={[styles.bottomLine]} />
          <View style={styles.bottomLineDescription}>
            <Text>consectetur adipiscing elit.</Text>
          </View>
        </Page>
      )}

      {/* Fifth Page => */}
      {Data?.Product?.length > 4 && (
        <Page size="A4" style={styles.page}>
          <View style={[styles.fifthPageUpperBox]}>
            <View style={styles.fifthPageImageContainer}>
              <Image
                style={styles.fifthPageImage}
                src={Data?.Product?.[4]?.image}
              />
            </View>

            <View style={styles.fifthPageUpperHeadingContainer}>
              {/* <Text style={styles.fifthPageUpperHeading}>New Style</Text> */}
              <Text style={styles.fifthPageUpperHeading}>{Data?.Product?.[4]?.title}</Text>
              <Text style={styles.fifthPageUpperDescription}>
                consectetur adipiscing elit.consectetur adipiscing elit.consectetur
                adipiscing elit.consectetur adipiscing elit.consectetur adipiscing
                elit.
              </Text>
              <Text style={styles.priceText}>{Data?.Product?.[4]?.variants?.[0]?.price} rs.</Text>

            </View>
          </View>

          <View style={styles.fifthPageLowerHeadingContainer}>
            <Text style={styles.fifthPageLowerSubheading}>
              consectetur adipiscing elit.
            </Text>
            <Text style={styles.fifthPageLowerDescription}>
              consectetur adipiscing elit.consectetur adipiscing elit.consectetur
              adipiscing elit.consectetur adipiscing elit.consectetur adipiscing
              elit.
            </Text>
          </View>

          <View style={[styles.fifthPageBottomBox]} />

          <View style={[styles.bottomLine]} />
          <View style={styles.bottomLineDescription}>
            <Text>consectetur adipiscing elit.</Text>
          </View>
        </Page>
      )}

      {/* Sixth Page => */}
      {Data?.Product?.length > 5 && (
        <Page size="A4" style={styles.page}>
          <View style={[styles.sixthPageFirstBox]} />
          <View style={styles.sixthPageHeadingContainer}>
            {/* <Text style={styles.sixthPageHeading}>New Look</Text> */}
            <Text style={styles.sixthPageHeading}>{Data?.Product?.[5]?.title}</Text>
            <Text style={styles.sixthPageDescription}>
              consectetur adipiscing elit.consectetur adipiscing elit.consectetur
              adipiscing elit.consectetur adipiscing elit.consectetur adipiscing
              elit.
            </Text>
            <Text style={styles.priceText}>{Data?.Product?.[5]?.variants?.[0]?.price} rs.</Text>

          </View>
          <View style={styles.SixthPageUpperImageContainer}>
            <Image
              style={styles.SixthPageUpperImage}
              src={Data?.Product?.[5]?.image}
            />
          </View>
          <View style={[styles.sixthPageBottomBoxContainer]}>
            <View style={[styles.sixthPageBottomBoxUpperBox]} />
            <View style={[styles.sixthPageBottomBoxLowerBox]} />
          </View>
          <View style={styles.SixthPageBottomImageContainer}>
            <Image
              style={styles.SixthPageBottomImage}
              src={Data?.Product?.[5]?.image}
            />
          </View>

          <View style={[styles.bottomLine]} />
          <View style={styles.bottomLineDescription}>
            <Text>consectetur adipiscing elit.</Text>
          </View>
        </Page>
      )}

      {/*Seventh Page => */}
      {Data?.Product?.length > 6 && (
        <Page size="A4" style={styles.page}>
          <View style={[styles.seventhPageUpperBox]} />
          <View style={styles.seventhPageHeadingContainer}>
            <Text style={styles.seventhPageHeading}>{Data?.Product?.[6]?.title}</Text>
            {/* <Text style={styles.seventhPageHeading}>New Look</Text> */}
            <Text style={styles.seventhPageDescription}>
              consectetur adipiscing elit.consectetur adipiscing elit.consectetur
              adipiscing elit.consectetur adipiscing elit.consectetur adipiscing
              elit.
            </Text>
            <Text style={styles.seventhPageSecondDescription}>
              consectetur adipiscing elit.consectetur adipiscing elit.
            </Text>
          </View>
          <View style={styles.seventhPageRotatedDescriptionContainer}>
            <Text style={styles.seventhPageRotatedDescription}>
              {/* consectetur adipiscing elit. */}
              <Text style={styles.priceText}>{Data?.Product?.[6]?.variants?.[0]?.price} rs.</Text>

            </Text>
          </View>
          <View style={styles.seventhPageImageContainer}>
            <Image
              style={styles.seventhPageImage}
              src={Data?.Product?.[6]?.image}
            />
          </View>

          <View style={[styles.seventhPageBottomBox]} />
          <View style={[styles.bottomLine]} />
          <View style={styles.bottomLineDescription}>
            <Text>consectetur adipiscing elit.</Text>
          </View>
        </Page>
      )}

      {/* Eight Page => */}
      {/* {Data?.Product?.length > 7 && (
        <Page size="A4" style={styles.page}>
          <View style={styles.eightPageBackImageContainer}>
            <Image
              style={styles.eightPageBackImage}
              src={Data?.Product?.[7]?.image}
            />
          </View>
          <View style={styles.eightPageFrontImageContainer}>
            <Image
              style={styles.eightPageFrontImage}
              src={Data?.Product?.[7]?.image}
            />
          </View>

          <View style={styles.eightPageHeadingContainer}>
            <Text style={styles.eightPageFirstDescription}>
              {Data?.Product?.[7]?.title}
            </Text>
            <Text style={styles.eightPageHeading}>New Look</Text>
            <Text style={styles.eightPageSecondDescription}>
              consectetur adipiscing elit.consectetur adipiscing elit.consectetur
              adipiscing elit.consectetur adipiscing elit.
            </Text>
            <Text style={styles.priceText}>{Data?.Product?.[7]?.variants?.[0]?.price}</Text>

          </View>
  
          <View style={[styles.eightPageBottomBox]} />
          <View style={[styles.bottomLine]} />
          <View style={styles.bottomLineDescription}>
            <Text>consectetur adipiscing elit.</Text>
          </View>
        </Page>
      )} */}

      {Data?.Product?.slice(7).map((product, index) => (
        <Page key={index} size="A4" style={styles.page}>
          <View style={styles.eightPageBackImageContainer}>
            <Image style={styles.eightPageBackImage} src={product.image} />
          </View>
          <View style={styles.eightPageFrontImageContainer}>
            <Image style={styles.eightPageFrontImage} src={product.image} />
          </View>
          <View style={styles.eightPageHeadingContainer}>
            <Text style={styles.eightPageFirstDescription}>{product.title}</Text>
            <Text style={styles.eightPageHeading}>New Look</Text>
            <Text style={styles.eightPageSecondDescription}>
              consectetur adipiscing elit. consectetur adipiscing elit. consectetur
              adipiscing elit.
            </Text>
            <Text style={styles.priceText}>{product.variants?.[0]?.price} rs.</Text>
          </View>
          <View style={[styles.eightPageBottomBox]} />
          <View style={[styles.bottomLine]} />
          <View style={styles.bottomLineDescription}>
            <Text>consectetur adipiscing elit.</Text>
          </View>
        </Page>
      ))}

    </Document>
  );

};

// const PdfGenerator = () => (
//   <div>
//     <PDFDownloadLink document={<MyDocument2 />} fileName="any.pdf">
//       {({ blob, url, loading, error }) =>
//         loading ? "Loading document..." : "Download now!"
//       }
//     </PDFDownloadLink>
//   </div>                                                                              
// );

export default MyDocument2;
