import React from "react";
import Layout, { Container } from "../components/layout";

const Leaderboard = () => {
  return (
    <Layout>
      <Container>
        <div className="bg-gray-200 h-full p-6 overflow-auto">
          <div className="bg-white max-w-4xl mx-auto h-full rounded-xl shadow-lg p-4">
            <header className="flex justify-between items-center py-2">
              <h1 className="text-2xl font-bold">🏆 Leaderboard</h1>
              <div className="flex space-x-4">
                <button className="px-4 py-1 bg-gray-300 text-gray-700 rounded-lg">
                  Monthly
                </button>
                <button className="px-4 py-1 bg-gray-100 text-gray-700 rounded-lg">
                  Weekly
                </button>
              </div>
            </header>

            <div className="flex justify-center items-center my-8">
              {/* 1st Place */}
              <div className="text-center p-4">
                <img
                  src="https://via.placeholder.com/100"
                  alt="avatar"
                  className="w-20 h-20 mx-auto rounded-full border-4 border-yellow-500"
                />
                <h2 className="mt-2 font-bold text-lg">KingSize</h2>
                <p className="text-orange-600 text-xl font-semibold">
                  $21,573.98
                </p>
                <p className="text-gray-600">🥇 $20,000 Prize</p>
              </div>
              {/* 2nd Place */}
              <div className="text-center p-4">
                <img
                  src="https://via.placeholder.com/100"
                  alt="avatar"
                  className="w-20 h-20 mx-auto rounded-full border-4 border-yellow-400"
                />
                <h2 className="mt-2 font-bold text-lg">KingSize</h2>
                <p className="text-orange-600 text-xl font-semibold">
                  $17,792.11
                </p>
                <p className="text-gray-600">🥈 $20,000 Prize</p>
              </div>
              {/* 3rd Place */}
              <div className="text-center p-4">
                <img
                  src="https://via.placeholder.com/100"
                  alt="avatar"
                  className="w-20 h-20 mx-auto rounded-full border-4 border-yellow-600"
                />
                <h2 className="mt-2 font-bold text-lg">KingSize</h2>
                <p className="text-orange-600 text-xl font-semibold">
                  $3,862.10
                </p>
                <p className="text-gray-600">🥉 $20,000 Prize</p>
              </div>
            </div>

            <div className="bg-gray-100 rounded-lg p-4">
              {/* Leaderboard List */}
              {[
                { name: "Floyd Miles", prize: "$3,862.10" },
                { name: "Dianne Russell", prize: "$2,171.72" },
                { name: "Cameron Williamson", prize: "$2,096.06" },
                { name: "Jenny Wilson", prize: "$1,792.98" },
                { name: "Courtney Henry", prize: "$1,703.21" },
              ].map((player, index) => (
                <div
                  key={index}
                  className="flex justify-between items-center py-2 border-b border-gray-300"
                >
                  <div className="flex items-center space-x-3">
                    <span className="text-gray-500 font-bold text-lg">
                      #{index + 4}
                    </span>
                    <img
                      src="https://via.placeholder.com/40"
                      alt="avatar"
                      className="w-10 h-10 rounded-full"
                    />
                    <span className="font-semibold text-gray-700">
                      {player.name}
                    </span>
                  </div>
                  <span className="text-orange-600 font-semibold">
                    {player.prize}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default Leaderboard;
