/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import "./createCatalog.css";
import "react-toastify/dist/ReactToastify.css";
import PDFComponent from "./PDFComponent";
import Select from "react-select";
import Layout from "../../components/layout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchableSelect from "../../components/searchableSelect";
import Advanceloader from "../../advanceloader.gif";
import AllPdfs from "./allPdfs";
import { Page, pdfjs } from "react-pdf";
import anyPdf from "./anycopy.pdf";
import { useUser } from "../../config/userProvider";
import { RiCloseLargeFill, RiShoppingCartLine } from "react-icons/ri";
import { BiSearch } from "react-icons/bi";
import BarcodeScanner from "./barcode";
import { IoBarcodeOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import deleteIcon from "../../assets/delete.gif";
import { AiFillInfoCircle } from "react-icons/ai";
import { DynamicLoader } from "../../components/loader";
import { apiurl } from "../../config/config";
import Card from "../../components/card";
import Input from "../../components/inputContainer";
import { IoMdClose } from "react-icons/io";
import svg from "../../components/svg";

const CreateCatalog = () => {
  const { userData } = useUser();
  let vendorObjId = userData?.vendorDetails?.vendorObjId;
  const [inputValue, setInputValue] = useState("");
  const [data, setData] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [combinedData, setCombinedData] = useState([]);
  const [viewProducts, setViewProducts] = useState(false);
  const [viewLive, setViewLive] = useState(false);
  const [generatePdfPopup, setGeneratePdfPopup] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [storeData, setStoreData] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [storeManagerPhoneInput, setStoreManagerPhoneInput] = useState("");
  const [storeManagerInput, setStoreManagerInput] = useState("");
  const [selectedValue, setSelectedValue] = useState("Select Store");
  const [activeSection, setActiveSection] = useState("managerInformation");

  const inputRef = useRef(null);

  console.log("User Data Is :", userData);

  const handleSubmit = (event) => {
    event.preventDefault();
    setInputValue("");
  };

  const fetchUserList = async () => {
    setLoading(true);
    const data = {
      vendorObjId: vendorObjId,
    };
    try {
      const response = await axios.post(
        `${apiurl}/admin/v1/product/get`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response && response.data) {
        setData(response.data.products);
      }
    } catch (error) {
      console.error("Error fetching user list:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserList();
  }, []);

  const handleCheckboxChange = (itemId) => {
    setCheckedItems((prev) =>
      prev.includes(itemId)
        ? prev.filter((id) => id !== itemId)
        : [...prev, itemId]
    );
  };

  // useEffect(() => {
  //   const jsonData = data && data.filter((product) =>
  //     checkedItems.includes(product.id)
  //   );
  //   setSelectedItems(jsonData);
  // }, [data, checkedItems]);

  useEffect(() => {
    const jsonData = data
      ? data.filter((product) => checkedItems.includes(product.id))
      : [];
    setSelectedItems(jsonData);
  }, [data, checkedItems]);

  const handleSearch = (event) => {
    const value = event.target.value;
    setInputValue(value);

    const filteredData =
      data &&
      data.filter((product) =>
        product.variants.some((variant) => variant.barcode === value)
      );

    setSearchData(filteredData);
  };



  
  // useEffect(() => {
  //   setCombinedData([...(selectedItems || []), ...searchData]);
  // }, [selectedItems, searchData]);

  const removeHandle = (itemID) => {
    setSearchData((prev) => prev.filter((item) => item.barcode !== itemID));
  };

  const filteredProducts =
    data &&
    data.filter((item) => {
      const lowerCaseFilter = filterValue.toLowerCase();
      return (
        item._id.toString().includes(lowerCaseFilter) ||
        item.title.toLowerCase().includes(lowerCaseFilter)
      );
    });

  const [previousSize, setPreviousSize] = useState(combinedData.length);
  useEffect(() => {
    const currentSize = combinedData.length;
    if (currentSize > previousSize) {
      toast("Product added successfully!", {
        type: "success",
        autoClose: 200,
        hideProgressBar: true,
      });
    } else if (currentSize < previousSize) {
      toast("Product removed successfully!", { type: "info", autoClose: 200 });
    }
    setPreviousSize(currentSize);
  }, [combinedData, previousSize]);

  useEffect(() => {
    if (userData?.storeDetails) {
      setStoreManagerInput(userData?.name);
      setStoreManagerPhoneInput(userData?.phone);
    }
  }, [userData]);

  const handleStoreChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 50;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records =
    filteredProducts && filteredProducts.slice(firstIndex, lastIndex);
  // const npage = Math.ceil(filteredProducts.length / recordsPerPage);
  const npage = filteredProducts
    ? Math.ceil(filteredProducts.length / recordsPerPage)
    : 0;

  const prepage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const changeCpage = (id) => setCurrentPage(id);

  const nextpage = () => {
    if (currentPage < npage) setCurrentPage(currentPage + 1);
  };

  const buttonsToShow = 10;
  const startPage = Math.max(1, currentPage - Math.floor(buttonsToShow / 2));
  const endPage = Math.min(npage, startPage + buttonsToShow - 1);
  const numbers = Array.from(
    { length: endPage - startPage + 1 },
    (_, index) => startPage + index
  );

  const sendStoreData = {
    storename: selectedStore?.storename,
    managername: storeManagerInput,
    storePhone: storeManagerPhoneInput,
    storeAddress: selectedStore?.Address,
    storeCode: selectedStore?.storecode,
  };

  const sizeHandle = (code) => {
    const filteredData = data.filter((product) =>
      product.variants.some((variant) => variant.barcode === code)
    );
    setSearchData((prev) => [...prev, ...filteredData]);
    console.log("filteredData", filteredData);
  };

  const handleStoreSelect = (option) => {
    setSelectedValue(option);
  };

  const options =
    storeData &&
    storeData.map((item) => ({
      value: item.storename,
      label: `${item.id}- ${item.storename}`,
    }));

  const viewLiveHandle = () => {
    setViewLive(true);
    inputRef.current?.focus();
  };

  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);

  const [isOpenBarCode, setIsOpenBarCode] = useState(false);
  const [barCodeResult, setBarCodeResult] = useState("");
  const [barcodeData, setBarCodeData] = useState([]);
  const [isBarCodeProductList, setIsBarCodeProductList] = useState(false);
  const [isPersonalize, setIsPersonalize] = useState(true);

  const [changedUserData, setChangedUserData] = useState("");


  const [costumerInformation, setCostumerInformation] = useState("");
  // console.log("userData userData?.phone", userData?.phone);

  const CostumerInputHandler = (e) => {
    const { name, value } = e.target;
    setCostumerInformation((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const UpdateCatalogStatus = ({ status }) => {
    if (userData) {
      let data = JSON.stringify({
        vendorObjId: vendorObjId,
        storeObjId: userData?.storeDetails?.storeObjId || "",
        userObjId: userData?._id,
        actionDetails: {
          products: ["Product A", "Product B"],
          type: status,
          description: "",
          tags: ["catalog", "tag2", "tag3"],
          // articleSKU: "SKU12345",
          // category: "Winter Collection",
          // price: "150.00",
          // discount: "10.00",
        },
      });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${apiurl}/admin/v1/analytic/create`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };  
  console.log("userData", userData);
// console.log("viewProducts viewProducts", viewProducts);
console.log("viewProducts combinedData", combinedData);
  return (
    <Layout>
      {isOpenBarCode && (
        <div className="backdrop-blur-md bg-black/60 h-full w-full fixed top-0 left-0 right-0 bottom-0 z-20 flex justify-center items-start ">
          <button
            style={{ borderWidth: 1 }}
            className="absolute right-4 top-4 p-1 border-white text-white rounded-full"
            onClick={() => setIsOpenBarCode(false)}
          >
            <RiCloseLargeFill />
          </button>
          <div className="mt-[50%] md:mt-5 m-5 rounded-lg overflow-hidden p-4 relative shadow-lg">
            <div className="absolute top-0 left-0 w-16 h-16 border-t-4 border-l-4 border-gray-300"></div>
            <div className="absolute top-0 right-0 w-16 h-16 border-t-4 border-r-4 border-gray-300"></div>
            <div className="absolute bottom-0 left-0 w-16 h-16 border-b-4 border-l-4 border-gray-300"></div>
            <div className="absolute bottom-0 right-0 w-16 h-16 border-b-4 border-r-4 border-gray-300"></div>
            <div className="absolute inset-x-0 top-1/2 border-b-2 border-blue-100/50"></div>
            <BarcodeScanner
              barCodeResult={barCodeResult}
              setBarCodeResult={setBarCodeResult}
              barcodeData={barcodeData}
              setBarCodeData={setBarCodeData}
              combinedData={combinedData}
              setCombinedData={setCombinedData}
              data={data}
            />
          </div>

          {isBarCodeProductList && (
            <div className="absolute h-[80vh] bg-white rounded-xl w-10/12 p-5  top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ">
              {barcodeData && barcodeData.map((item, index) => item)}
            </div>
          )}

          <div
            className={`absolute bottom-5 w-full flex  items-center gap-2 px-3 ${
              barCodeResult ? "justify-center" : "justify-end"
            }`}
          >
            {barCodeResult && (
              <div className=" bg-white w-11/12 p-2 py-3 rounded-lg ">
                {barCodeResult} added successfully
              </div>
            )}

            <button
              className=" bg-blue-500 text-white  p-3 text-4xl rounded-full "
              onClick={() => setIsBarCodeProductList(!isBarCodeProductList)}
            >
              {isBarCodeProductList ? <IoCloseOutline /> : <IoBarcodeOutline />}
            </button>

            <button
              className="bg-black text-white  p-3 text-xl rounded-full "
              onClick={() => setViewLive(!viewLive)}
            >
              <RiShoppingCartLine />
            </button>
          </div>
        </div>
      )}
      <ToastContainer />
      <div className="bg-white/100 ">
        {loading ? (
          <DynamicLoader maintext="Wait" subtext="Fetching Catalog Data..." />
        ) : (
          <div className="p-2 h-full">
            <div className="bg-zinc-100 px-1.5 lg:px-4 py-2 grid grid-cols-2  md:flex justify-between  gap-2 rounded-lg shadow flex-wrap md:flex-nowrap">
              <button
                className="bg-zinc-950 px-2 text-white rounded lg:hidden text-2xl w-10 flex justify-center items-center lg:w-auto"
                onClick={() => setIsOpenBarCode(true)}
              >
                <IoBarcodeOutline />
              </button>
              <form
                onSubmit={handleSubmit}
                className="hidden md:flex items-center gap-2"
              >
                <Input.normal
                  autoFocus
                  value={inputValue}
                  className="lg:text-[0.9rem] text-xs py-2"
                  type="text"
                  placeholder="Scan Barcode"
                  onChange={handleSearch}
                  ref={inputRef}
                />
                <div className="relative group">
                  <AiFillInfoCircle className="text-gray-500 hover:text-gray-700 transition-colors duration-200 cursor-pointer" />
                  <div className="absolute text-nowrap z-20 hidden group-hover:block bg-white text-gray-800 text-sm rounded-md py-2 px-3 left-1/2 transform -translate-x-1/2 top-full mt-2 shadow-lg border border-gray-200 transition-opacity duration-200 opacity-0 group-hover:opacity-100">
                    <span className="block capitalize">
                      For scanning by a machine focus on this input
                    </span>
                    <span className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-3 h-3 bg-white border-l border-t border-gray-200 rotate-45"></span>
                  </div>
                </div>
              </form>

              <div className="w-full col-span-2  md:w-1/2 relative group">
                <Input.normal
                  type="text"
                  placeholder="Global Search...."
                  className="lg:text-[0.9rem] text-xs py-2 lg:w-full hover:border-gray-500 transition duration-200"
                  value={filterValue}
                  onChange={(e) => setFilterValue(e.target.value)}
                />
                <BiSearch className="text-xl absolute right-3 top-1/2 -translate-y-1/2 group-hover:text-black text-zinc-300 transition duration-200" />
              </div>

              {/* <div className="hidden md:flex bg-green-500 justify-center items-center m-auto border rounded">
                <p className="m-0 p-2 text-white">
                  Count: {combinedData?.length}
                </p>
              </div> */}
              <div className=" flex row-start-1 col-start-2 items-center justify-end gap-2 border-l lg:border-none  lg:gap-3 ">
                {/* <div className="mx-3 w-50 liveAddedBtn"> */}
                <button
                  className="p-[0.3rem] flex justify-center items-center text-xl  hover:bg-zinc-950 hover:text-white  rounded  "
                  onClick={viewLiveHandle}
                >
                  <RiShoppingCartLine />
                </button>
                {/* </div> */}
                <button
                  className={`w-full text-[0.7rem] lg:text-[1rem] lg:p-1 lg:px-3 p-1.5 px-4 rounded ${
                    combinedData?.length === 0
                      ? "bg-gray-300 text-gray-500"
                      : "bg-zinc-950 text-white"
                  } `}
                  disabled={combinedData?.length === 0}
                  onClick={() => {
                    setGeneratePdfPopup(true);
                    UpdateCatalogStatus("catalog_generated");
                  }}
                >
                  Generate
                </button>
              </div>
            </div>

            <div className="h-86 sm:h-full w-full">
              <div className="productSection h-full  w-full ">
                <div className=" h-full  w-full mt-3 md:mt-5   ">
                  <div className="flex  md:hidden flex-col gap-2">
                    {records &&
                      records.map((item, index) => (
                        <div className="flex items-start gap-1">
                          <label className="p-1 px-2 rounded-sm bg-zinc-50 font-medium">
                            {index + 1}
                          </label>

                          <div className="flex ">
                            {/* <div className="w-2/5  rounded bg-red-500 bg-center bg-cover" style={{backgroundSize: "cover",backgroundPosition: 'center' , backgroundImage: `url(${item.image ?? "https://blackberrys.com/cdn/shop/files/logo_155x52.png"})` }}>
                        </div> */}

                            <img
                              className="w-2/5 rounded"
                              src={
                                item?.image ??
                                "https://blackberrys.com/cdn/shop/files/logo_155x52.png"
                              }
                              alt={item?.title}
                              style={{ objectFit: "cover" }}
                            />
                            <div className="p-2 ">
                              <div className="text-sm">{item?.title}</div>
                              <div className="text-xs mt-2 bg-zinc-50 p-2 rounded">
                                <label className="font-medium">
                                  Product Size
                                </label>
                                <div className="text-xs mt-1">
                                  <VariantSku
                                    item={item}
                                    sizeHandle={sizeHandle}
                                    padding="px-2 p-1"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>

                  <div class=" md:block hidden relative w-full h-full overflow-y-auto shadow-md sm:rounded-lg ">
                    <table class="w-full text-sm text-left rtl:text-right text-gray-500 ">
                      <thead class="text-xs text-gray-700 uppercase bg-gray-50  ">
                        <tr className="">
                          <th scope="col" class="px-6 py-3">
                            #
                          </th>
                          <th scope="col" class="px-6 py-3">
                            Image
                          </th>
                          <th scope="col" class="px-6 py-3">
                            Title
                          </th>
                          <th scope="col" class="px-6 py-3">
                            Product Add By Size
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {records &&
                          records.map((item, index) => (
                            <tr key={item.id} className="  border-b ">
                              <th
                                scope="row"
                                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                              >
                                {index + 1}
                              </th>
                              <td class="px-6 py-4">
                                <label
                                  htmlFor={`${item.id}`}
                                  className="cursor-pointer"
                                >
                                  <img
                                    src={
                                      item.image ??
                                      "https://blackberrys.com/cdn/shop/files/logo_155x52.png"
                                    }
                                    alt=""
                                    className="w-24 rounded "
                                  />
                                </label>
                              </td>
                              <td class="px-6 py-4">
                                <label
                                  htmlFor={`${item.id}`}
                                  className="cursor-pointer"
                                >
                                  {item?.title}
                                </label>
                              </td>
                              <td class="px-6 py-4">
                                <VariantSku
                                  item={item}
                                  sizeHandle={sizeHandle}
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="neo_btn_container m-1 my-md-3 sticky bottom-0 backdrop-blur-md bg-white">
                  <button className="product_neo_btn" onClick={prepage}>
                    Previous
                  </button>
                  {numbers &&
                    numbers.map((n, i) => (
                      <div
                        className={`page-item ${
                          currentPage === n ? "active" : ""
                        }`}
                        key={i}
                      >
                        <button
                          className={`product_neo_btn ${
                            currentPage === n ? "active-page-button" : ""
                          }`}
                          onClick={() => changeCpage(n)}
                        >
                          {n}
                        </button>
                      </div>
                    ))}
                  <button className="product_neo_btn" onClick={nextpage}>
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* {viewLive && ( */}
      <div
        className={`fixed z-50 bg-white lg:w-1/4 w-11/12 h-[95%] lg:h-screen p-4 lg:p-6 transition-all duration-500 ease-in-out
          ${viewLive ? "opacity-100" : "opacity-0 pointer-events-none"}
          lg:left-0 lg:top-0 lg:rounded-r-3xl lg:drop-shadow-xl
          bottom-0 left-1/2 -translate-x-1/2 rounded-t-lg shadow-xl
          ${
            viewLive
              ? "lg:translate-x-0 translate-y-0 lg:translate-y-0"
              : "lg:-translate-x-full translate-y-full lg:-translate-y-0"
          }`}
      >
        <div className="flex justify-between items-center">
          <p>
            <span className="text-2xl font-semibold">Products</span>
            <span className="bg-blue-50 border border-blue-300 text-blue-600 px-2 ml-2 rounded">
              {combinedData.length}
            </span>
          </p>
          <button
            type="button"
            className="btn mb-3 btn-outline-secondary text-zinc-400 hover:text-black "
            onClick={() => setViewLive(false)}
          >
            <RiCloseLargeFill />
          </button>
        </div>
        {combinedData.length === 0 ? (
          <div className="w-full h-full flex justify-center  items-center">
            <div className="bg-zinc-50 text-zinc-600 rounded-md border border-zinc-300 p-10">
              <h2>Cart is empty!</h2>
            </div>
          </div>
        ) : (
          <div className=" viewliveContent flex flex-col gap-4">
            {combinedData &&
              combinedData.map((item) => {
                const compareAtPrice = item?.variants[0]?.compare_at_price;
                const price = item?.variants[0]?.price;

                const higherValue =
                  compareAtPrice &&
                  parseFloat(compareAtPrice) > parseFloat(price)
                    ? compareAtPrice
                    : price;
                return (
                  <div
                    className="border-b  flex gap-4  py-4"
                    id={`${item?._id}`}
                    key={item?._id}
                  >
                    <div className="">
                      <img
                        src={
                          item?.image ??
                          "https://blackberrys.com/cdn/shop/files/logo_155x52.png"
                        }
                        alt=""
                        // width={60}
                        // height={60}
                        className="rounded w-28"
                      />
                    </div>
                    <div className=" flex justify-between w-full">
                      <div className="flex flex-col gap-0.5 items-start">
                        <div className="flex flex-col items-start">
                          <p className="text-[0.6rem] md:text-xs bg-zinc-50 px-3 sm:px-2 py-0.5 rounded  text-zinc-400 hover:text-zinc-800">
                            <i>{item?.variants[0]?.barcode}</i>
                          </p>
                          <label className="text-sm lg:text-xl font-semibold">
                            {item?.title}
                          </label>
                        </div>

                        <p className="border p-1 px-2 rounded text-[0.6rem] lg:text-sm text-zinc-600">
                          {item?.variants[0]?.size}
                        </p>

                        <p className="text-sm lg:text-md font-medium leading-4 mt-2">
                          {higherValue}
                        </p>
                      </div>
                      <div className="flex items-start cursor-pointer">
                        <button
                          className="btn m-0 "
                          onClick={() => removeHandle(item.barcode)}
                          aria-label="Remove item"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 64 64"
                            fill="none"
                            stroke="currentColor" // Changed to currentColor for better compatibility
                            strokeWidth="2.04"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            width={25}
                          >
                            <g>
                              <path d="M37.444,19.092v-2.207c0-2.074-1.682-3.756-3.756-3.756h-3.377c-2.074,0-3.756,1.682-3.756,3.756v2.207" />
                            </g>
                            <g>
                              <path
                                fill="#FFFFFF"
                                d="M48.482,21.551c0,1.358-1.247,2.459-2.787,2.46l-27.39,0.006c-1.54,0-2.788-1.101-2.788-2.46v-0.006c0-1.358,1.248-2.46,2.787-2.46h27.39C47.235,19.092,48.482,20.193,48.482,21.551z"
                              />
                              <path
                                fill="#FFFFFF"
                                d="M18.427,42.902V24.017h27.147v18.884c0,4.401-3.568,7.969-7.969,7.969H26.396C21.995,50.871,18.427,47.303,18.427,42.902z"
                              />
                            </g>
                            <g>
                              <line
                                x1="26.556"
                                y1="29.461"
                                x2="28.371"
                                y2="43.979"
                              />
                              <line
                                x1="37.88"
                                y1="29.461"
                                x2="36.066"
                                y2="43.979"
                              />
                            </g>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
      {/* )} */}

      {generatePdfPopup && (
        <Card.AddPopUp removePopUp={() => setGeneratePdfPopup(false)}>
          {/* <div
            className="overlaydiv"
            onClick={() => setGeneratePdfPopup(false)}
          ></div> */}
          <div className=" p-4  rounded  text-xs md:text-base ">
            <div className="flex justify-between items-start sticky top-0 bg-white">
              <div className="flex flex-col text-zinc-800 ">
                <div className="font-semibold md:font-bold text-lg md:text-2xl capitalize">
                  {userData?.storeDetails?.storeName} (
                  {userData?.storeDetails?.storeCode})
                </div>
                <div className="text-[0.6rem] md:text-sm text-slate-400">
                  {userData?.storeDetails?.storeEmail}
                </div>
                <div className="text-[0.6rem] md:text-sm text-slate-400 w-9/12">
                  {userData?.storeDetails?.storeAddress}
                </div>
              </div>
              <div
                className=" border border-slate-400 p-1 rounded-md  md:rounded-md cursor-pointer  text-md md:text-lg text-slate-500"
                onClick={() => setGeneratePdfPopup(false)}
              >
                <IoMdClose />
              </div>
            </div>

            <div className="">
              <div className="text-sm md:text-base flex justify-between md:justify-center gap-2 md:gap-7 mb-4 h-[20%] w-[100%]">
                <button
                  className={`py-2  border-b-2 h-10 ${
                    activeSection === "managerInformation"
                      ? "border-slate-400 text-slate-500 font-semibold"
                      : " text-gray-500"
                  }`}
                  onClick={() => setActiveSection("managerInformation")}
                >
                  Manager Information
                </button>
                {/* <button
                  className={`py-2  border-b-2 h-10 ${
                    activeSection === "storeInformation"
                      ? "border-slate-400 text-slate-500 font-semibold"
                      : " text-gray-500"
                  }`}
                  onClick={() => setActiveSection("storeInformation")}
                >
                  Store Information
                </button> */}
              </div>

              {activeSection === "managerInformation" && (
                <>
                  <div className="mb-4 flex md:flex-row flex-col gap-2 md:gap-4">
                    <Input.normal
                      label=" Store Manager Name"
                      type="text"
                      id="storeManager"
                      className=""
                      placeholder="Store Manager Name"
                      aria-label="Username"
                      value={storeManagerInput}
                      onChange={(e) => setStoreManagerInput(e.target.value)}
                    />

                    <Input.normal
                      label="Phone Number"
                      type="text"
                      id="storeManagerPhone"
                      className=""
                      placeholder="Phone Number"
                      aria-label="phone"
                      value={storeManagerPhoneInput}
                      onChange={(e) =>
                        setStoreManagerPhoneInput(e.target.value)
                      }
                    />
                    {/* <label
                      htmlFor="storeManager"
                      className="block text-gray-500 font-medium mb-2"
                    >
                      Store Manager Name
                    </label>
                    <Input
                      type="text"
                      id="storeManager"
                      className=""
                      placeholder="Store Manager Name"
                      aria-label="Username"
                      value={storeManagerInput}
                      onChange={(e) => setStoreManagerInput(e.target.value)}
                    /> */}
                  </div>

                  {/* <div className="mb-4 w-50"> */}

                  {/* <label
                      htmlFor="storeManagerPhone"
                      className="block text-gray-500 font-medium mb-2"
                    >
                      Phone Number
                    </label>
                    <Input
                      type="text"
                      id="storeManagerPhone"
                      className=""
                      placeholder="Phone Number"
                      aria-label="phone"
                      value={storeManagerPhoneInput}
                      onChange={(e) =>
                        setStoreManagerPhoneInput(e.target.value)
                      }
                    /> */}
                  {/* </div> */}
                </>
              )}
              <div className="my-4 bg-white ">
                <div className="flex flex-col md:flex-row md:items-center justify-between">
                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      id="toggleCard"
                      className="cursor-pointer"
                      checked={isPersonalize}
                      onChange={(e) => {
                        setIsPersonalize(e.target.checked);
                      }}
                    />
                    <label
                      htmlFor="toggleCard"
                      className="block text-gray-500 font-medium "
                    >
                      Personalize
                    </label>
                  </div>
                  <div className="text-xs md:text-sm px-3 rounded bg-yellow-50 border border-yellow-400 text-yellow-700">
                    Fill Costumer's Information
                  </div>
                </div>
                {isPersonalize && (
                  <div
                    id="toggleCardTarget"
                    className=" flex flex-col gap-2 my-3 transition-all duration-500 ease-in-out"
                  >
                    <div className=" flex md:flex-row flex-col gap-2 md:gap-4">
                      <Input.normal
                        label="Costumer name"
                        type="text"
                        className=""
                        name="costumerName"
                        placeholder="name"
                        value={costumerInformation?.costumerName}
                        onChange={CostumerInputHandler}
                      />

                      <Input.normal
                        label="Costumer Phone Number"
                        type="text"
                        className=""
                        placeholder="Phone Number"
                        name="costumerPhone"
                        value={costumerInformation?.costumerPhone}
                        onChange={CostumerInputHandler}
                      />
                    </div>
                    <Input.normal
                      label="Costumer email"
                      type="text"
                      className=""
                      name="costumerEmail"
                      placeholder=" email"
                      value={costumerInformation?.costumerEmail}
                      onChange={CostumerInputHandler}
                    />
                    {/* <Input.textArea
                      label="Costumer address"
                      type="text"
                      className=""
                      rows="4"
                      name="costumerAddress"
                      placeholder=" address"
                      value={costumerInformation?.costumerAddress}
                      onChange={CostumerInputHandler}
                    /> */}
                  </div>
                )}
              </div>

              {activeSection === "storeInformation" && (
                <>
                  <div className="mb-4">
                    <label
                      htmlFor="storeName"
                      className="block text-gray-500 font-medium mb-2"
                    >
                      Store Name
                    </label>
                    <Input.normal
                      type="text"
                      id="storeName"
                      className=""
                      value={userData?.storeDetails?.storeName}
                      onChange={(e) =>
                        setChangedUserData((prev) => ({
                          ...prev,
                          storeDetails: {
                            ...prev.storeDetails,
                            storeName: e.target.value,
                          },
                        }))
                      }
                    />
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor="storeEmail"
                      className="block text-gray-500 font-medium mb-2"
                    >
                      Store Email
                    </label>
                    <Input.normal
                      type="email"
                      id="storeEmail"
                      className=""
                      value={userData?.storeDetails?.storeEmail}
                      onChange={(e) =>
                        setChangedUserData((prev) => ({
                          ...prev,
                          storeDetails: {
                            ...prev.storeDetails,
                            storeEmail: e.target.value,
                          },
                        }))
                      }
                    />
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor="storeAddress"
                      className="block text-gray-500 font-medium mb-2"
                    >
                      Store Address
                    </label>
                    <Input
                      type="text"
                      id="storeAddress"
                      className=""
                      value={userData?.storeDetails?.storeAddress}
                      onChange={(e) =>
                        setChangedUserData((prev) => ({
                          ...prev,
                          storeDetails: {
                            ...prev.storeDetails,
                            storeAddress: e.target.value,
                          },
                        }))
                      }
                    />
                  </div>
                </>
              )}
              <div className="bg-zinc-50 border rounded-md">
                <Input.normal
                  label="Choose Your Perfect PDF"
                  isInput={false}
                  labelClassName="mt-2 p-0"
                />
                <div className="flex justify-evenly gap-5 md:mt-1 ">
                  <AllPdfs
                    data={{ Product: combinedData, Store: sendStoreData }}
                  />
                  {/* {selectedStore && (
                  <div className="card text-left my-3">
                    <div className="card-body">
                      <h4 className="">
                        Store Name: {selectedStore?.storename}
                      </h4>
                      <h5 className="">
                        Store Code: {selectedStore?.storecode}
                      </h5>
                      <p className="card-text">
                        Address: {selectedStore?.Address}
                      </p>
                    </div>
                  </div>
                )} */}
                </div>
              </div>

              <div className="flex justify-between items-center gap-3">
                <div className="flex flex-col items-start gap-2  mt-3">
                  <button
                    className="px-3 py-1 border rounded-md flex items-center gap-2 hover:text-green-700 text-nowrap "
                    onClick={() => setGeneratePdfPopup(false)}
                  >
                    <svg.whatsapp className="cursor-pointer" />
                    <span>Send on whatsapp</span>
                  </button>
                  <button
                    className="px-3 py-1 border rounded-md flex items-center gap-2 hover:text-red-700 text-nowrap "
                    onClick={() => setGeneratePdfPopup(false)}
                  >
                    <svg.gmail className="cursor-pointer" />
                    <span>Send on gmail</span>
                  </button>
                </div>

                <PDFComponent
                  data={{ Product: combinedData, Store: sendStoreData }}
                />
              </div>
            </div>
          </div>
        </Card.AddPopUp>
      )}
    </Layout>
  );
};
export default CreateCatalog;

export const VariantSku = ({ item, sizeHandle, padding, margin }) => {
  console.log("item", item);
  return (
    <label htmlFor={`${item.id}`} className="cursor-pointer">
      {item?.variants?.map((variant) => (
        <button
          className={`bg-transparent border text-gray-700   rounded hover:bg-blue-100 hover:border-blue-200 ${
            padding ? padding : "p-2 px-3"
          } ${margin ? margin : "m-1"} `}
          onClick={() => sizeHandle(variant?.barcode)}
          key={variant?.barcode}
        >
          {variant.size}
        </button>
      ))}
    </label>
  );
};

// const Input = ({
//   id,
//   type,
//   placeholder,
//   onChange,
//   value,
//   ariadescribedby,
//   isDisable,
//   label,
//   className,
// }) => (
//   <div className="flex flex-col">
//     {label && (
//       <label className="font-medium text-sm" htmlFor="storeName">
//         {label}
//       </label>
//     )}
//     <input
//       type={type ? type : "text"}
//       style={{ borderWidth: 1 }}
//       className={`w-full py-2 px-2 rounded-md outline-none bg-white ${className} `}
//       id={id}
//       placeholder={placeholder ? placeholder : ""}
//       aria-describedby={ariadescribedby ? ariadescribedby : ""}
//       value={value}
//       onChange={onChange}
//       // {...props}
//     />
//   </div>
// );

// const Input = ({ onChange, value, className, ...props }) => (
//   <input
//     className={`bg-white border p-1 rounded outline-none text-base px-3 ${className}`}
//     onChange={onChange}
//     value={value}
//     {...props}
//   />
// );
