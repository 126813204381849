import React from "react";
import { Link, NavLink } from "react-router-dom";
import Hamburger from "./burger-menu-svgrepo-com.svg";
import Close from "./close-svgrepo-com.svg";
import { BiSolidDashboard } from "react-icons/bi";
import { FaRegFolder, FaUserPlus } from "react-icons/fa6";
import { TbViewfinder } from "react-icons/tb";
import { FaUsersViewfinder } from "react-icons/fa6";
import { IoAnalyticsOutline } from "react-icons/io5";
import { BiCategoryAlt } from "react-icons/bi";
import { GrSync } from "react-icons/gr";
import { PiFilePdfLight } from "react-icons/pi";
import { RiEdit2Line } from "react-icons/ri";
import { MdOutlineCelebration, MdLeaderboard } from "react-icons/md";
import { useUser } from "../config/userProvider";
import { IoIosAddCircleOutline } from "react-icons/io";
import { BiSolidOffer } from "react-icons/bi";
import { IoStorefrontOutline } from "react-icons/io5";
import { RiTeamFill } from "react-icons/ri";
import { VscBroadcast } from "react-icons/vsc";
import { IoIosPeople } from "react-icons/io";
import { IoAppsSharp } from "react-icons/io5";
// import "./sidebar.css";
import "../../src/App.css";

const Sidebar = () => {
  const { userData, isMenuOpen, setIsMenuOpen } = useUser();

  // console.log("userdata 23 sidebar", userData);

  const NavigationData = {
    master: [
      {
        title: "Dashboard",
        icon: <BiSolidDashboard />,
        location: "/dashboard",
      },
      {
        title: "create catalog",
        icon: <BiCategoryAlt />,
        location: "/createcatalog",
      },
      // {
      //   title: "Create User",
      //   icon: <FaUserPlus />,
      //   location: "/createuser",
      // },
      {
        title: "Stores",
        icon: <IoStorefrontOutline />,
        location: "/stores",
      },
      // {
      //   title: "View User",
      //   icon: <FaUsersViewfinder />,
      //   location: "/viewuser",
      // },
      {
        title: "Analytics",
        icon: <IoAnalyticsOutline />,
        location: "/analytics",
      },
      {
        title: "Files",
        icon: <FaRegFolder />,
        location: "/files",
      },

      {
        title: "sync products",
        icon: <GrSync />,
        location: "/syncproducts",
      },
      // {
      //   title: "catalogues",
      //   icon: <PiFilePdfLight />,
      //   location: "/catalogues",
      // },
      {
        title: "image editor",
        icon: <RiEdit2Line />,
        location: "/imageeditor",
      },
      // {
      //   title: "celebration",
      //   icon: <MdOutlineCelebration />,
      //   location: "/celebration",
      // },
      {
        title: "integration",
        icon: <IoIosAddCircleOutline />,
        location: "/integration",
      },
      {
        title: "teams",
        icon: <RiTeamFill />,
        location: "/teams",
      },
      {
        title: "customers",
        icon: <IoIosPeople />,
        location: "/customers",
      },
      {
        title: "offers",
        icon: <BiSolidOffer />,
        location: "/offers",
      },
      {
        title: "broadcasts",
        icon: <VscBroadcast />,
        location: "/broadcasts",
      },
      // {
      //   title: "stores",
      //   icon: <IoStorefrontOutline />,
      //   location: "/stores",
      // },
    ],
    superadmin: [
      {
        title: "Dashboard",
        icon: <BiSolidDashboard />,
        location: "/dashboard",
      },
      {
        title: "create catalog",
        icon: <BiCategoryAlt />,
        location: "/createcatalog",
      },
      // {
      //   title: "Create User",
      //   icon: <FaUserPlus />,
      //   location: "/createuser",
      // },

      // {
      //   title: "View User",
      //   icon: <FaUsersViewfinder />,
      //   location: "/viewuser",
      // },
      {
        title: "Analytics",
        icon: <IoAnalyticsOutline />,
        location: "/analytics",
      },
      {
        title: "Files",
        icon: <FaRegFolder />,
        location: "/files",
      },

      // {
      //   title: "catalogues",
      //   icon: <PiFilePdfLight />,
      //   location: "/catalogues",
      // },
      {
        title: "image editor",
        icon: <RiEdit2Line />,
        location: "/imageeditor",
      },
      // {
      //   title: "celebration",
      //   icon: <MdOutlineCelebration />,
      //   location: "/celebration",
      // },
      {
        title: "apps",
        icon: <IoAppsSharp />,
        location: "/apps",
      },
      {
        title: "integration",
        icon: <IoIosAddCircleOutline />,
        location: "/integration",
      },
      {
        title: "teams",
        icon: <RiTeamFill />,
        location: "/teams",
      },
      {
        title: "customers",
        icon: <IoIosPeople />,
        location: "/customers",
      },
      {
        title: "Stores",
        icon: <IoStorefrontOutline />,
        location: "/stores",
      },
      {
        title: "products",
        icon: <GrSync />,
        location: "/products",
      },
      {
        title: "offers",
        icon: <BiSolidOffer />,
        location: "/offers",
      },

      {
        title: "broadcasts",
        icon: <VscBroadcast />,
        location: "/broadcasts",
      },
      {
        title: "leaderboard",
        icon: <MdLeaderboard />,
        location: "/leaderboard",
      },
    ],
    admin: [
      {
        title: "Dashboard",
        icon: <BiSolidDashboard />,
        location: "/dashboard",
      },
      {
        title: "create catalog",
        icon: <BiCategoryAlt />,
        location: "/createcatalog",
      },

      {
        title: "Analytics",
        icon: <IoAnalyticsOutline />,
        location: "/analytics",
      },

      {
        title: "image editor",
        icon: <RiEdit2Line />,
        location: "/imageeditor",
      },

      {
        title: "teams",
        icon: <RiTeamFill />,
        location: "/teams",
      },
      {
        title: "customers",
        icon: <IoIosPeople />,
        location: "/customers",
      },
      {
        title: "offers",
        icon: <BiSolidOffer />,
        location: "/offers",
      },
    ],
    user: [
      // Store
      {
        title: "dashboard",
        icon: <BiSolidDashboard />,
        location: "/dashboard",
      },
      {
        title: "create catalog",
        icon: <BiCategoryAlt />,
        location: "/createcatalog",
      },
    ],
  };

  return (
    <div className="flex flex-col gap-1 items-center w-full h-full overflow-scroll scroll-smooth hide-scrollbar py-2">
      {NavigationData[userData?.userType].map((item, index) => {
        return (
          <NavLink
            key={index}
            style={{ borderWidth: 1 }} //due to bootstrap won't able to change border color;
            onClick={() => (isMenuOpen ? setIsMenuOpen(false) : "")} // close menu if the isMenuOpen is true;
            className={({ isActive }) => `
              px-2 py-2 rounded-lg w-full  flex items-center gap-1.5
                border-transparent hover:border-[#353a40] capitalize
                text-sm
             ${
               isActive
                 ? "bg-white/100  text-black/100  hover:text-inherit"
                 : "hover:border hover:border-white  hover:text-white  text-white/100 "
             }  
              `}
            to={item?.location}
          >
            <span className="text-xl"> {item?.icon}</span>
            <p className=" sm:hidden lg:block">{item?.title}</p>
          </NavLink>
        );
      })}
    </div>
  );
};

export default Sidebar;