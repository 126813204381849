import React, { useState } from "react";
import Layout, { Container } from "../components/layout";
import { AiTwotoneEdit } from "react-icons/ai";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { BlackButton } from "../components/buttonContainer";
import axios from "axios";
import Input from "../components/inputContainer"; // Import the InputContainer component
import { apiurl } from "../config/config";

function AddTeam() {
  const navigate = useNavigate();
  const token = localStorage.getItem("sctoken");
  const [error, setError] = useState("");
  const [formdata, setFormdata] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    role: "user",
    stores: false,
    products: false,
    orders: false,
    settings: false,
    reports: false,
    users: false,
    analytics: false,
    userAddress: {
      address: "",
      city: "",
      province: "",
      zip: "",
      country: "",
      province_code: "",
      country_code: "",
    },
  });

  const handleBack = () => {
    navigate("/teams");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name in formdata.userAddress) {
      setFormdata({
        ...formdata,
        userAddress: { ...formdata.userAddress, [name]: value },
      });
    } else {
      setFormdata({ ...formdata, [name]: value });
    }
  };
  console.log(formdata);

  const handleToggleChange = (key) => {
    setFormdata((prevFormData) => ({
      ...prevFormData,
      [key]: !prevFormData[key],
    }));
  };

  const CreateHandler = () => {
    let data = JSON.stringify({
      name: formdata.name,
      email: formdata.email,
      phone: formdata.phone,
      password: formdata.password,
      userType: formdata.role,
      vendorObjId: "672ca6c3bee5eadc508e7ccb",
      permissions: {
        stores: formdata.stores,
        products: formdata.products,
        orders: formdata.orders,
        settings: formdata.settings,
        reports: formdata.reports,
        users: formdata.users,
        analytics: formdata.analytics,
      },
      userAddress: {
        address: formdata.userAddress.address,
        city: formdata.userAddress.city,
        province: formdata.userAddress.province,
        zip: formdata.userAddress.zip,
        country: formdata.userAddress.country,
        province_code: formdata.userAddress.province_code,
        country_code: formdata.userAddress.country_code,
      },
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${apiurl}/admin/v1/user/create`,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        if (response.data.success === true) {
          navigate(`/teams`);
        } else {
          setError(response.data?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Custom toggle switch component
  const Toggle = ({ name, checked, onChange }) => (
    <div className="flex justify-between">
      <label className="mr-2 ">
        {name.charAt(0).toUpperCase() + name.slice(1)}
      </label>
      <div
        onClick={onChange}
        className={`w-12 h-6 flex items-center rounded-full p-1 cursor-pointer ${
          checked ? "bg-gray-500" : "bg-gray-300"
        }`}
      >
        <div
          className={`bg-white w-4 h-4 rounded-full shadow-md transform ${
            checked ? "translate-x-6" : ""
          } transition-transform`}
        />
      </div>
    </div>
  );
  return (
    <Layout>
      <Container>
        <div className="flex flex-wrap justify-between w-full h-full ">
          <div className="flex flex-col py-2 px-2 md:px-14  rounded-lg w-full h-full overflow-auto">
            {/* <h2 className="text-2xl font-bold mb-4 text-slate-500 text-center p-4">
                 ADD MEMBER
            </h2> */}
            <div className="flex items-center gap-5 w-full">
              <Link to="/teams">
                <div className="flex items-center justify-center border border-slate-300 rounded-md w-8 h-8 cursor-pointer">
                  <IoMdArrowRoundBack className="text-slate-500" />
                </div>
              </Link>
              <h2 className="text-lg md:text-2xl text-center text-slate-500 font-bold">
                ADD MEMBER
              </h2>
            </div>
            <div className="flex flex-col gap-5 mt-10  justify-between w-full   ">
              {/* Left side */}
              <div className="w-full shadow rounded-xl border bg-white p-3 md:p-10 border-r text-sm md:text-base">
                <p className="text-slate-500 font-semibold text-sm md:text-lg">
                  Information:
                </p>
                {/* <div className='flex gap-4'> */}
                <div className="md:p-5 p-3 flex flex-col gap-3">
                  <Input.normal
                    label="Enter Name"
                    name="name"
                    value={formdata.name}
                    placeholder="Enter Name"
                    onChange={handleInputChange}
                  />
                  <Input.normal
                    label="Enter Phone No."
                    name="phone"
                    value={formdata.phone}
                    placeholder="Enter Phone No."
                    onChange={handleInputChange}
                  />
                  <Input.normal
                    label="Enter Email"
                    name="email"
                    value={formdata.email}
                    placeholder="Enter Email"
                    onChange={handleInputChange}
                  />
                  <Input.normal
                    label="Enter Password"
                    name="password"
                    value={formdata.password}
                    placeholder="Enter Password"
                    onChange={handleInputChange}
                  />
                  <select
                    name="role"
                    value={formdata.role}
                    className="w-full border border-slate-200 focus:outline-none px-3 py-2 my-2 rounded-lg"
                    defaultValue=""
                    onChange={handleInputChange}
                  >
                    <option value="admin">Admin</option>
                    <option value="user">User</option>
                  </select>
                </div>
              </div>

              <div className="w-full shadow rounded-xl border bg-white p-3 md:p-10 border-r text-sm md:text-base">
                <p className="text-slate-500 font-semibold text-sm md:text-lg">
                  User Address:
                </p>
                <div className="md:p-5 p-3 flex flex-col gap-3">
                  <Input.normal
                    label="Enter Address"
                    name="address"
                    value={formdata.userAddress.address}
                    placeholder="Enter Address"
                    onChange={handleInputChange}
                  />
                  <Input.normal
                    label="Enter City"
                    name="city"
                    value={formdata.userAddress.city}
                    placeholder="Enter City"
                    onChange={handleInputChange}
                  />
                  <Input.normal
                    label="Enter Province"
                    name="province"
                    value={formdata.userAddress.province}
                    placeholder="Enter Province"
                    onChange={handleInputChange}
                  />
                  <Input.normal
                    label="Enter ZIP"
                    name="zip"
                    value={formdata.userAddress.zip}
                    placeholder="Enter ZIP"
                    onChange={handleInputChange}
                  />
                  <Input.normal
                    label="Enter Country"
                    name="country"
                    value={formdata.userAddress.country}
                    placeholder="Enter Country"
                    onChange={handleInputChange}
                  />
                  <Input.normal
                    label="Enter Province Code"
                    name="province_code"
                    value={formdata.userAddress.province_code}
                    placeholder="Enter Province Code"
                    onChange={handleInputChange}
                  />
                  <Input.normal
                    label="Enter Country Code"
                    name="country_code"
                    value={formdata.userAddress.country_code}
                    placeholder="Enter Country Code"
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              {/* Right Side */}
              <div className="w-full shadow rounded-xl border bg-white p-3 md:p-10  text-sm md:text-lg">
                <p className="text-slate-500 py-2  font-semibold">
                  Permissions:
                </p>
                <div className="flex flex-col gap-5 p-5 md:p-5">
                  <div className="w-full">
                    <Toggle
                      name="stores"
                      checked={formdata.stores}
                      onChange={() => handleToggleChange("stores")}
                    />
                  </div>
                  <div className="w-full">
                    <Toggle
                      name="products"
                      checked={formdata.products}
                      onChange={() => handleToggleChange("products")}
                    />
                  </div>
                  <div className="w-full">
                    <Toggle
                      name="orders"
                      checked={formdata.orders}
                      onChange={() => handleToggleChange("orders")}
                    />
                  </div>
                  <div className="w-full">
                    <Toggle
                      name="settings"
                      checked={formdata.settings}
                      onChange={() => handleToggleChange("settings")}
                    />
                  </div>
                  <div className="w-full">
                    <Toggle
                      name="reports"
                      checked={formdata.reports}
                      onChange={() => handleToggleChange("reports")}
                    />
                  </div>
                  <div className="w-full">
                    <Toggle
                      name="users"
                      checked={formdata.users}
                      onChange={() => handleToggleChange("users")}
                    />
                  </div>
                  <div className="w-full">
                    <Toggle
                      name="analytics"
                      checked={formdata.analytics}
                      onChange={() => handleToggleChange("analytics")}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="text-red-500 text-center mt-5">{error}</div>
            <div className="text-center">
              <BlackButton title="Add" handleSubmit={CreateHandler} />
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}

export default AddTeam;
