import React, { useState, useEffect, useContext } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import axios from "axios";
import { PdfContext } from "./pdfContext";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

const AllPdfs = ({ data }) => {
  const [products, setProducts] = useState([]);
  // const [choosedPdf, setchoosedPdf] = useState("");
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [loadingPdf, setLoadingPdf] = useState(true); // Loading state for PDFs

  useEffect(() => {
    // Fetch products data
    axios
      .get("https://fakestoreapi.com/products")
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);

  // Function to handle successful document load
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  // Function to download the PDF
  const downloadPdf = async (PdfComponent, data, fileName) => {
    const blob = await pdf(<PdfComponent data={data} />).toBlob();
    saveAs(blob, fileName);
  };
  console.log("Data", data);
  const { choosedPdf, setchoosedPdf } = useContext(PdfContext);
  // const { selectedPdfs, setSelectedPdfs } = useContext(PdfContext);
  useEffect(() => {
    setchoosedPdf("Modern-Muse");
  }, []);

  const selectedPdfs = ["Modern-Muse", "Casual-Classics", "Luxe-Looks"];

  // console.log("SELECTED PDF'S ",choosedPdf, selectedPdfs);

  return (
    <div className="flex justify-around gap-2 w-full my-4 flex-wrap">
      {/* <button
        onClick={() => setchoosedPdf("Modern-Muse")}
        className={`px-8  py-2 rounded-md ${choosedPdf === "Modern-Muse" && "bg-slate-500 text-white"}`}
      >
        Pdf 1
      </button>

      <button
        onClick={() => setchoosedPdf("Casual-Classics")}
        className={`px-8  py-2 rounded-md ${choosedPdf === "Casual-Classics" && "bg-slate-500 text-white"}`}
      >
        Pdf 2
      </button>

      <button
        onClick={() => setchoosedPdf("Luxe-Looks")}
        className={`px-8  py-2 rounded-md ${choosedPdf === "Luxe-Looks" && "bg-slate-500 text-white"}`}
      >
        Pdf 3
      </button> */}

      {selectedPdfs.map((item) => {
        return (
          <button
            key={item}
            onClick={() => setchoosedPdf(item)}
            className={`px-2 md:px-8 py-2 rounded-md border-[1px] border-slate-500 text-nowrap  ${
              choosedPdf === item && "bg-slate-500 text-white"
            }`}
          >
            {item}
          </button>
        );
      })}
    </div>
  );
};

export default AllPdfs;
