import React, { useEffect, useState } from 'react'
import Layout, { Container } from '../components/layout';
import axios from "axios";
import Loader, { DynamicLoader } from '../components/loader';
import { Link } from 'react-router-dom';
import { FiSearch } from "react-icons/fi";
import ButtonContainer from '../components/buttonContainer';
import SearchContainer from '../components/searchContainer';
import { useUser } from "../config/userProvider";
import { IoAddOutline } from "react-icons/io5";
import FormatDateTime from "../components/formatDateTime";
import { apiurl } from "../config/config";
import Input from "../components/inputContainer";

function Broadcasts() {
  const { userData } = useUser();
  let vendorObjId = userData?.vendorDetails?.vendorObjId;
  const [storeData, setStoreData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    setLoading(true);
    const data = JSON.stringify({
      vendorObjId: vendorObjId,
    });

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${apiurl}/admin/v1/broadcast/get`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.success === true) {
          setStoreData(response.data.broadcasts);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const filterData = storeData
    ? storeData.filter((product) =>
        product.broadcastsname.toLowerCase().includes(searchInput.toLowerCase())
      )
    : [];

  return (
    <Layout>
      <Container>
        {loading === true ? (
          <DynamicLoader maintext="wait" subtext="Fetching Broadcast Data" />
        ) : (
          <div className="border  border-slate-200 rounded-lg md:rounded-xl h-full w-full px-5 py-3">
            <div className="flex flex-col gap-3 h-full w-full">
              <div className="w-full h-full flex flex-col">
                <div className="flex flex-col md:flex-row bg-white w-full text-center justify-between items-center md:h-[10%] py-4 gap-2 md:gap-5">
                  <div className="flex justify-between items-center w-full md:w-[70%]">
                    <div>
                      <h1 className="text-xl md:text-3xl font-medium text-center text-slate-500">
                        Broadcasts
                      </h1>
                    </div>
                    <div>
                      <Link to="/broadcasts/create">
                        <ButtonContainer icon={<IoAddOutline />}>
                          <span className="hidden md:block">Create</span>
                        </ButtonContainer>
                      </Link>
                    </div>
                  </div>

                  <div className="w-full md:w-[30%]">
                    <Input.search
                      value={searchInput}
                      placeholder={"Search Offer..."}
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                  </div>
                </div>
                <div className="md:h-[90%] overflow-hidden w-full">
                  <div className="flex flex-col relative h-full">
                    <div className="overflow-x-auto sm:rounded-lg h-full">
                      <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50   sticky top-0">
                          <tr>
                            <th scope="col" className="px-6 py-3">
                              Broadcast Name
                            </th>
                            <th scope="col" className="px-6 py-3 ">
                              Created At
                            </th>
                            <th scope="col" className="px-6 py-3 ">
                              Launch Date
                            </th>
                            <th scope="col" className="px-6 py-3 ">
                              Status
                            </th>
                            <th scope="col" className="px-6 py-3 ">
                              Type
                            </th>
                            <th scope="col" className="px-6 py-3 ">
                              Description
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {filterData &&
                            filterData.length > 0 &&
                            filterData.map((product, index) => (
                              <tr
                                key={index}
                                className={`${
                                  index % 2 === 0
                                    ? "odd:bg-white "
                                    : "even:bg-gray-50 "
                                } border-b`}
                              >
                                <th
                                  scope="row"
                                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                                >
                                  {product.broadcastsname}
                                </th>
                                <td className="px-6 py-2">
                                  {FormatDateTime(product.createdAt)}
                                </td>
                                <td className="px-6 py-2">
                                  {FormatDateTime(product.launchDate)}
                                </td>
                                <td className="px-6 py-2">{product.status}</td>
                                <td className="px-6 py-2">
                                  {product?.mail === "true" ? (
                                    <span className="px-2 bg-red-100 rounded font-medium text-red-600">
                                      mail
                                    </span>
                                  ) : product?.whatsapp === "true" ? (
                                    <span className="px-2 bg-green-100 rounded font-medium text-green-600">
                                      whatsApp
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td className="px-6 py-2">
                                  {product.description}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Container>
    </Layout>
  );
}

export default Broadcasts