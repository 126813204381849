import React, { useState, useEffect } from "react";
import Layout, { Container } from "../components/layout";
import axios from "axios";
import Loader from "../loader.svg";
import { useUser } from "../config/userProvider";
import { MdOutlineFrontHand } from "react-icons/md";

const Createuser = () => {
  const [selectedStore, setSelectedStore] = useState(null);
  const [storeManagerPhoneInput, setstoreManagerPhoneInput] = useState("");
  const [storeManagerInput, setstoreManagerInput] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [storeCodeInput, setstoreCodeInput] = useState("");
  const [storeAddressInput, setstoreAddressInput] = useState("");
  const [selectedValue, setSelectedValue] = useState("Select Store");
  const [loading, setLoading] = useState(false);

  const { storeData } = useUser();
  useEffect(() => {
    if (selectedValue === "Select Store") {
      setSelectedStore(null); // No store selected, set to null or an empty object
    } else {
      const selectedStoreData = storeData.find(
        (item) => item.storename === selectedValue
      );
      setSelectedStore(selectedStoreData);
    }
    setstoreManagerPhoneInput(selectedStore?.store_phone);
    setstoreManagerInput(selectedStore?.managername);
    setstoreCodeInput(selectedStore?.storecode);
    setstoreAddressInput(selectedStore?.Address);
  }, [selectedValue, storeData, selectedStore]);

  const handleStoreChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedValue(selectedValue);
  };

  const CreateUserHandle = () => {
    setLoading(true);
    console.log("CreateUserHandle click");

    let payload = {
      phone: storeManagerPhoneInput,
      name: storeManagerInput,
      email: email,
      password: password,
      storeCode: storeCodeInput,
      storeAddress: storeAddressInput,
      storeName: selectedStore.storename,
    };

    const url =
      "https://blackberry-9ab311620e64.herokuapp.com/user/storeusersingup";

    // Send the POST request
    axios
      .post(url, payload)
      .then((response) => {
        // Handle the successful response here
        setLoading(false);
        console.log("POST request successful:", response.data);
      })
      .catch((error) => {
        // Handle any errors that occur during the request
        console.error("Error making POST request:", error);
      });

    console.log("CreateUserHandle", payload);
  };

  return (
    <Layout>
      <Container>
        <div className="flex justify-start">
          <h1 className="text-center text-2xl font-medium ">
            Create Store User
          </h1>
        </div>
        {loading === true ? (
          <>
            <div className="createloaderOverlay">
              <img src={Loader} alt="loader" />
            </div>
          </>
        ) : (
          <div className="lg:p-4 mt-10 mb-5   rounded d-flex justify-content-center flex-column gap-4 mx-auto ">
            <FlexContainer>
              <FormInputContainer label="Select Store" htmlFor="storeName">
                <select
                  className=" cursor-pointer border py-2 px-2 rounded-md outline-none bg-white/100  "
                  id="storeName"
                  aria-label="Default select example"
                  autoFocus="true"
                  onChange={handleStoreChange}
                  value={selectedValue}
                >
                  <option value="Select Store" selected>
                    Select Store
                  </option>
                  {storeData &&
                    storeData.map((item) => {
                      return (
                        <option value={item.storename}>
                          {item.id}- {item.storename}
                        </option>
                      );
                    })}
                </select>
              </FormInputContainer>

              <FormInputContainer
                label="Store Manager Name"
                htmlFor="StoreManagerName"
              >
                <Input
                  value={storeManagerInput}
                  onChange={(e) => setstoreManagerInput(e.target.value)}
                />
              </FormInputContainer>

              <FormInputContainer label=" Store Code" htmlFor="storecode">
                <Input
                  value={storeCodeInput}
                  onChange={(e) => setstoreCodeInput(e.target.value)}
                />
              </FormInputContainer>
            </FlexContainer>

            <FlexContainer>
              <FormInputContainer
                label=" Store Manager Phone"
                htmlFor="phoneNumber"
                className="w-[30%]"
              >
                <Input
                  value={storeManagerPhoneInput}
                  onChange={(e) => setstoreManagerPhoneInput(e.target.value)}
                />
              </FormInputContainer>

              <FormInputContainer label="Store Address" htmlFor="Address">
                <Input
                  value={storeAddressInput}
                  onChange={(e) => setstoreAddressInput(e.target.value)}
                />
              </FormInputContainer>
            </FlexContainer>

            <FlexContainer
              className="bg-slate-50  border-slate-200 p-2 lg:p-4 rounded-lg"
              style={{ borderWidth: 1 }} //due to bootstrap won't able to change border color
            >
              <FormInputContainer
                label="Store Manager Email"
                htmlFor="exampleInputEmail1"
              >
                <Input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Help>store user will login this email.</Help>
              </FormInputContainer>

              <FormInputContainer
                label="Store Manager Password."
                htmlFor="exampleInputEmail1"
              >
                <Input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Help>store user will login this password.</Help>
              </FormInputContainer>
            </FlexContainer>
            <div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  style={{ borderWidth: 1 }} //due to bootstrap won't able to change border color
                  className="bg-blue-500 border-transparent  p-2 px-4 text-white  rounded-lg"
                  onClick={CreateUserHandle}
                >
                  Create User
                </button>
              </div>
            </div>
          </div>
        )}
      </Container>
    </Layout>
  );
};

export default Createuser;

const FlexContainer = ({ children, className, style }) => (
  <div
    className={`flex lg:flex-row flex-col justify-between gap-3 w-full ${className}`}
    style={style}
  >
    {children}
  </div>
);

const FormInputContainer = ({ label, htmlFor, children, className }) => (
  <div className={`flex flex-col w-full ${className}`}>
    <label htmlFor={htmlFor} className="font-semibold text-sm  ">
      {label}
    </label>
    {children}
  </div>
);

const Input = ({ id, type, placeholder, onChange, value, ariadescribedby }) => (
  <input
    type={type ? type : "text"}
    style={{ borderWidth: 1 }}
    className="w-full py-2 px-2 rounded-md outline-none bg-white/100 "
    id={id}
    placeholder={placeholder ? placeholder : ""}
    aria-describedby={ariadescribedby}
    value={value}
    onChange={onChange}
  />
);

const Help = ({ children }) => (
  <div className="text-red-500 text-sm flex gap-1 items-center">
    <MdOutlineFrontHand />
    {children}
  </div>
);
