import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Layout, { Container } from '../components/layout'
import { apiurl } from '../config/config';
import { getCookie } from '../config/webStorage';
import axios from 'axios';
import { IoEyeOff } from "react-icons/io5";
import { MdRemoveRedEye } from "react-icons/md";
import { IoMdArrowRoundBack } from "react-icons/io";
import { DynamicLoader } from '../components/loader';
import BackHeader from '../components/backHeader';
import { BsChevronDown } from "react-icons/bs";
import { GoCopy } from "react-icons/go";
import { PiCheckSquareLight } from "react-icons/pi";
import { ToastContainer, toast } from "react-toastify";






function AppDetails() {
    let sctoken = getCookie('sctoken');
    const navigate = useNavigate();
    const { id } = useParams(); // Extract the ID from the URL
    console.log("ID IS", id)
    const [loading, setLoading] = useState("");
    const [appData, setAppData] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [showAppDisablePopup, setShowAppDisablePopup] = useState(false);
    const [showToken, setShowToken] = useState(false);
    const [copied, setCopied] = useState(false);
    const [showPermissions, setShowPermissions] = useState(false);
    const [showAuthDetails, setShowAuthDetails] = useState(false);
    const [apiKeycopied, setApiKeyCopied] = useState(false);


    console.log('token is :', sctoken)

    console.log("APPDATA IS", appData)
    console.log("showPermissions", showPermissions)

    const [showApiKey, setShowApiKey] = useState(false);


    const toggleApiKeyVisibility = () => {
        setShowApiKey(!showApiKey);
    };
    const showPermissionsDropdown = () => {
        setShowPermissions(!showPermissions);
    };
    const showAuthDetailsDropdown = () => {
        setShowAuthDetails(!showAuthDetails);
    };

    const copyApiKeyToClipboard = () => {
        navigator.clipboard.writeText(appData?.apiKey || '');
        setApiKeyCopied(true);
        setTimeout(() => setApiKeyCopied(false), 2000);
    };

    const toggleTokenVisibility = () => {
        setShowToken((prev) => !prev);
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(appData.token)
            .then(() => {
                setCopied(true);
                setTimeout(() => {
                    setCopied(false);
                }, 3000);
            })
            .catch((err) => {
                console.error('Failed to copy: ', err);
            });
    };

    const handleDisableApp = async () => {

        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${apiurl}/admin/v1/application/disable/${id}`,
            headers: {
                'Authorization': sctoken
            },
        };

        try {
            const response = await axios.request(config);
            console.log("Response:", response.data);
            if (response?.data?.success) {
                setShowAppDisablePopup(false);
                toast.success("App Disabled successfully");
                navigate("/apps");
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error("Failed to disable the app. Please try again later.")
            setErrorMessage("Failed to disable the app. Please try again later.");
        }
    };


    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${apiurl}/admin/v1/application/get/${id}`, {
                headers: {
                    'Authorization': sctoken
                }
            });
            setAppData(response.data.data);
            setLoading(false);
        } catch (err) {
            // setError(err);
            console.error(err);
            setLoading(false);

        } finally {
            setLoading(false);
            setLoading(false);
        }
    };

    const disablePopup = () => {
        setShowAppDisablePopup(true)
    }
    const handleBack = () => {
        navigate("/apps");

    }

    useEffect(() => {
        fetchData();
    }, []);


    return (
        <Layout>
            <Container>
                {loading === true ?
                    <DynamicLoader maintext="wait" subtext="Fetching App Details" />
                    :
                    <div className=" w-full h-full">
                        <div className="flex flex-col justify-between gap-5 py-2 px-2 md:px-8 lg:px-28 w-[100%] h-[100%] overflow-scroll">
                            <div className='flex justify-between items-center my-4'>
                                <BackHeader backButton={true} link="/apps" title="App Details" rightSide={<button
                                    className={`px-1 md:px-8 py-1 rounded-lg text-white transition duration-200 border-[1px] 
                                            ${appData?.status === 'enabled' ?
                                            'bg-red-500 border-red-500 hover:bg-red-600 hover:border-red-600' :
                                            'bg-slate-500 border-slate-500 cursor-not-allowed'
                                        }`}
                                    onClick={appData?.status === 'enabled' ? disablePopup : undefined}
                                    disabled={appData?.status !== 'enabled'}
                                >
                                    Disable
                                </button>} />
                            </div>
                            <div className="px-2 py-2 border-slate-900 gap-2 rounded-lg">
                                <InfoContainer
                                    label="Name:"
                                    value={appData?.appName || 'N/A'}
                                />
                                <InfoContainer
                                    label="Vendor ID:"
                                    value={appData?.vendorId}
                                />
                                <InfoContainer
                                    label="Description:"
                                    value={appData?.description || 'N/A'}
                                />
                                <InfoContainer
                                    label="Created At:"
                                    value={new Date(appData?.createdAt).toLocaleString()}
                                />
                                <InfoContainer
                                    label="Updated At:"
                                    value={new Date(appData?.updatedAt).toLocaleString()}
                                />
                            </div>
                            <div className="border border-slate-900 px-3 md:px-12 py-4 rounded-lg">
                                <div className="flex justify-between items-center cursor-pointer" onClick={showAuthDetailsDropdown}>
                                    <p className=" font-semibold">Auth</p>
                                    <BsChevronDown className={`transition-transform duration-300 ${showAuthDetails ? 'rotate-180' : ''}`} />
                                </div>

                                <div className={`overflow-hidden transition-all duration-300 ease-in-out ${showAuthDetails ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'}`}>
                                    {showAuthDetails && (
                                        <div className="mt-4 space-y-4">
                                            <div className="flex gap-6">
                                                <div className="text-gray-700 flex flex-col md:flex-row justify-between flex-1  p-4 md:px-6 md:py-4 rounded-lg">
                                                    <div>
                                                        <p className="mb-2 md:mb-0 text-sm md:text-base text-slate-500 font-semibold">API Key:</p>
                                                    </div>
                                                    <div className="flex justify-between items-center md:ml-2">
                                                        <input
                                                            type={showApiKey ? 'text' : 'password'}
                                                            value={appData?.apiKey}
                                                            readOnly
                                                            className="border border-gray-300 rounded px-2 py-1 w-40 lg:w-96"
                                                        />
                                                        <button
                                                            onClick={toggleApiKeyVisibility}
                                                            className="md:ml-2 text-gray-500 hover:text-gray-700 transition duration-200"
                                                            aria-label={showApiKey ? 'Hide API key' : 'Show API key'}
                                                        >
                                                            {showApiKey ? <IoEyeOff size={20} /> : <MdRemoveRedEye size={20} />}
                                                        </button>
                                                        <button
                                                            onClick={copyApiKeyToClipboard}
                                                            className="md:ml-2 bg-slate-600 text-xs md:text-base text-white px-1 md:px-3 py-2 rounded hover:bg-slate-700 transition duration-200"
                                                        >
                                                            {apiKeycopied ? <PiCheckSquareLight /> : <GoCopy />}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex gap-6">
                                                <div className="text-gray-700 flex flex-col md:flex-row justify-between flex-1  p-4 md:px-6 md:py-4 rounded-lg">
                                                    <div>
                                                        <p className="mb-2 md:mb-0 text-sm md:text-base text-slate-500 font-semibold">Token:</p>
                                                    </div>
                                                    <div className="flex justify-between items-center md:ml-2">
                                                        <input
                                                            type={showToken ? 'text' : 'password'}
                                                            value={appData?.token}
                                                            readOnly
                                                            className="border border-gray-300 rounded px-2 py-1 w-40 lg:w-96"
                                                        />
                                                        <button
                                                            onClick={toggleTokenVisibility}
                                                            className="md:ml-2 text-gray-500 hover:text-gray-700 transition duration-200"
                                                            aria-label={showToken ? 'Hide token' : 'Show token'}
                                                        >
                                                            {showToken ? <IoEyeOff size={20} /> : <MdRemoveRedEye size={20} />}
                                                        </button>
                                                        <button
                                                            onClick={copyToClipboard}
                                                            className="md:ml-2 bg-slate-600 text-xs md:text-base text-white px-1 md:px-3 py-2 rounded hover:bg-slate-700 transition duration-200"
                                                        >
                                                            {copied ? <PiCheckSquareLight /> : <GoCopy />}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="border border-slate-900 px-3 md:px-12 py-4 rounded-lg">
                                <div className="flex justify-between items-center cursor-pointer" onClick={showPermissionsDropdown}>
                                    <p className=" font-semibold">Permissions</p>
                                    <BsChevronDown className={`transition-transform duration-300 ${showPermissions ? 'rotate-180' : ''}`} />
                                </div>

                                {showPermissions && (
                                    <div className="overflow-hidden transition-all duration-300 ease-in-out mt-4 space-y-4">
                                        <div className="flex flex-col md:flex-row md:gap-6">
                                            <div className="text-gray-700 flex-1 border border-slate-200 p-4 md:px-6 md:py-4 rounded-lg">
                                                <div className="flex justify-between">
                                                    <p className="text-slate-500 font-semibold">Products:</p>
                                                    <p>Read: {appData?.permissions?.products?.read ? 'Yes' : 'No'}, Write: {appData?.permissions?.products?.write ? 'Yes' : 'No'}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-col md:flex-row md:gap-6">
                                            <div className="text-gray-700 flex-1 border border-slate-200 p-4 md:px-6 md:py-4 rounded-lg">
                                                <div className="flex justify-between">
                                                    <p className="text-slate-500 font-semibold">Stores:</p>
                                                    <p>Read: {appData?.permissions?.stores?.read ? 'Yes' : 'No'}, Write: {appData?.permissions?.stores?.write ? 'Yes' : 'No'}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                        </div>

                        {showAppDisablePopup && (
                            <>
                                <div className="fixed top-0 left-0 w-full h-full opacity-30 bg-black"></div>
                                <div className={`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg w-11/12 md:w-[40rem] pl-6 pr-6 pt-8 pb-8 md:pl-8 md:pr-8 md:pt-10 md:pb-10 text-center`}>
                                    {/* <button
                                        className="text-3xl md:text-4xl absolute right-4 top-4"
                                        onClick={() => setShowAppDisablePopup(false)}
                                    >
                                        &times;
                                    </button> */}
                                    <h2 className="text-xl md:text-2xl font-bold md:mb-4 text-slate-500">
                                        Do you Really Want to Disable App
                                    </h2>
                                    <div className="flex justify-evenly">
                                        <div>
                                            <button className="bg-red-500 text-white px-5 md:px-20 py-2 rounded-md mt-4" onClick={handleDisableApp}>
                                                Yes
                                            </button>
                                        </div>
                                        <div>
                                            <button className="bg-slate-500 text-white px-5 md:px-20 py-2 rounded-md mt-4" onClick={() => setShowAppDisablePopup(false)}>
                                                No
                                            </button>
                                        </div>

                                    </div>
                                    {/* {errorMessage ? <p className='text-red-500'>{errorMessage}</p> : null} */}
                                </div>
                            </>
                        )}
                    </div>
                }
            </Container>
        </Layout>
    )
}

export default AppDetails



// const InfoContainer = ({ label, value, containerClass = "", textClass = "" }) => {
//     return (
//         <div className={`flex gap-6 ${containerClass}`}>
//             <div className={`text-gray-700 px-3 py-3 rounded-lg ${textClass}`}>
//                 <div className="grid grid-cols-2 w-full">
//                     <p className="text-slate-500 font-semibold">{label}</p>
//                     <p>{value}</p>
//                 </div>
//             </div>
//         </div>
//     );
// };

const InfoContainer = ({ label, value, containerClass = "", textClass = "" }) => {
    return (
        <div className={`${containerClass}`}>
            <div className={`text-gray-700 px-3 py-3 rounded-lg ${textClass}`}>
                <div className="flex w-full lg:w-1/2">
                    <p className="w-1/2 text-left text-slate-500 font-semibold">{label}</p> {/* Label takes 50% width */}
                    <p className="w-1/2 text-left">{value}</p> {/* Value takes 50% width */}
                </div>
            </div>
        </div>
    );
};

