import React, { useContext, useState } from 'react';
import Layout, { Container } from '../components/layout';
import { PDFViewer, PDFDownloadLink, pdf } from "@react-pdf/renderer";
import { PdfContext } from './createCatalog/pdfContext';


const Catalogues = () => {
  const pdfUrl1 = '/any.pdf';
  const pdfUrl2 = '/example.pdf';
  const pdfUrl3 = '/anycopy.pdf';

  const customizedUrl1 = `${pdfUrl1}#toolbar=0&navpanes=0&scrollbar=0`;
  const customizedUrl2 = `${pdfUrl2}#toolbar=0&navpanes=0&scrollbar=0`;
  const customizedUrl3 = `${pdfUrl3}#toolbar=0&navpanes=0&scrollbar=0`;

  // const {selectedPdfs, setSelectedPdfs} = useContext(PdfContext);
  const [selectedPdfs, setSelectedPdfs] = useContext(["Modern-Muse","Casual-Classics","Luxe-Looks"]);
  console.log(selectedPdfs);
  
  // Function to handle PDF selection
  const handleSelectPdf = (pdfName) => {
    setSelectedPdfs((prev) =>
      prev.includes(pdfName) ? prev : [...prev, pdfName]
    );
  };

  return (
    <Layout>
      <Container>
        <div className="flex flex-col md:flex-row justify-evenly items-center h-full p-2 space-y-2 md:space-x-2 md:space-y-0">
          {/* PDF Viewer 1 */}
          <div className="flex flex-col items-center flex-1 h-full max-w-full md:max-w-[32%]">
            <iframe
              src={customizedUrl1}
              title="PDF Viewer 1"
              className="flex-1 w-full h-full border-none"
            />                    
            <button
              className={`${
                selectedPdfs.includes('Modern-Muse') ? 'bg-green-500' : 'bg-slate-500'
              } text-white px-20 py-2 rounded-md mt-4 mb-3`}
              onClick={() => handleSelectPdf('Modern-Muse')}
            >
              {selectedPdfs.includes('Modern-Muse') ? 'Selected' : 'Select'}
            </button>
          </div>

          {/* PDF Viewer 2 */}
          <div className="flex flex-col items-center flex-1 h-full max-w-full md:max-w-[32%]">
            <iframe
              src={customizedUrl2}
              title="PDF Viewer 2"
              className="flex-1 w-full h-full border-none"
            />
            <button
              className={`${
                selectedPdfs.includes('Casual-Classics') ? 'bg-green-500' : 'bg-slate-500'
              } text-white px-20 py-2 rounded-md mt-4 mb-3`}
              onClick={() => handleSelectPdf('Casual-Classics')}
            >
              {selectedPdfs.includes('Casual-Classics') ? 'Selected' : 'Select'}
            </button>
          </div>

          {/* PDF Viewer 3 */}
          <div className="flex flex-col items-center flex-1 h-full max-w-full md:max-w-[32%]">
            <iframe
              src={customizedUrl3}
              title="PDF Viewer 3"
              className="flex-1 w-full h-full border-none"
            />
            <button
              className={`${
                selectedPdfs.includes('Luxe-Looks') ? 'bg-green-500' : 'bg-slate-500'
              } text-white px-20 py-2 rounded-md mt-4 mb-3`}
              onClick={() => handleSelectPdf('Luxe-Looks')}
            >
              {selectedPdfs.includes('Luxe-Looks') ? 'Selected' : 'Select'}
            </button>
          </div>
        </div>
      </Container>
    </Layout>
  );

};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '75.5vh',
    padding: '10px',
    gap: '10px',
  },
  pdfIframe: {
    flex: 1,
    height: '100%',
    border: 'none',
    maxWidth: '32%',
  },
};

export default Catalogues;
