import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { apiurl } from "./config";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [storeData, setStoreData] = useState("");
  const [openProfile, setCloseProfile] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [permissions, setPermissions] = useState(false);

  const setUser = (user) => {
    setUserData(user);
  };

  useEffect(() => {
    setPermissions(userData?.permission);

    console.log("userData?.user?.permission");
  }, [userData]);

  const [showInternetStatus, setShowInternetStatus] = useState(false);

  useEffect(() => {
    if (!navigator.onLine) {
      setShowInternetStatus(true);
    } else {
      setTimeout(() => {
        setShowInternetStatus(false);
      }, 10000);
    }
  }, [navigator.onLine]);

  return (
    <UserContext.Provider
      value={{
        userData,
        setUser,
        storeData,
        setStoreData,
        openProfile,
        setCloseProfile,
        isMenuOpen,
        setIsMenuOpen,
        permissions,
        setPermissions,
        showInternetStatus,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};
