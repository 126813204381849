import React, { useState, useEffect } from "react";
import axios from "axios";
import Layout, { Container } from "../components/layout";
import { AiTwotoneEdit } from "react-icons/ai";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { apiurl } from "../config/config";
import { useUser } from "../config/userProvider";
import { useParams, useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { DynamicLoader } from "../components/loader";
import BackHeader from "../components/backHeader";

function TeamDetails() {
  const { userData } = useUser();
  const { userObjId } = useParams(); // Extract the ID from the URL
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState([]);

  const deletePopup = () => {
    setShowDeleteMemberPopup(!showDeleteMemberPopup);
  };
  const handleDelete = () => {
    setShowDeleteMemberPopup(false);
  };
  const [showAddMemberPopup, setShowAddMemberPopup] = useState(false);
  const [showDeleteMemberPopup, setShowDeleteMemberPopup] = useState(false);
  // const [formdata, setFormdata] = useState({
  //     name: '',
  //     email: '',
  //     phone: '',
  //     role: '',
  //     stores: false,
  //     products: false,
  //     orders: false,
  //     settings: false,
  //     reports: false,
  //     users: false,
  //     analytics: false,
  // });
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    phone: "",
    role: "",
    stores: false,
    products: false,
    orders: false,
    settings: false,
    reports: false,
    users: false,
    analytics: false,
  });

  const fetchUserData = async (vendorObjId) => {
    setLoading(true);
    let payload = JSON.stringify({
      vendorObjId: vendorObjId,
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${apiurl}/admin/v1/user/get/${userObjId}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Customer Data:", response.data.data);
        // setAppData(response.data.data);
        // setLoading(false);
        // setUser(response?.data?.data)
        setUserDetails({
          name: response?.data?.data?.name || "",
          email: response?.data?.data?.email || "",
          phone: response?.data?.data?.phone || "",
          role: response?.data?.data?.userType || "",
          stores: response?.data?.data?.permissions?.stores || false,
          products: response?.data?.data?.permissions?.products || false,
          orders: response?.data?.data?.permissions?.orders || false,
          settings: response?.data?.data?.permissions?.settings || false,
          reports: response?.data?.data?.permissions?.reports || false,
          users: response?.data?.data?.permissions?.users || false,
          analytics: response?.data?.data?.permissions?.analytics || false,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (userData?.vendorDetails?.vendorObjId) {
      fetchUserData(userData.vendorDetails.vendorObjId);
    }
  }, []);
  const handleBack = () => {
    navigate("/teams");
  };

  // Custom toggle switch component
  const Toggle = ({ name, checked, onChange }) => (
    <div className="flex justify-between">
      <label className="mr-2 text-lg">
        {name.charAt(0).toUpperCase() + name.slice(1)}
      </label>
      <div
        onClick={onChange}
        className={`w-12 h-6 flex items-center rounded-full p-1 ${
          checked ? "bg-gray-500" : "bg-gray-300"
        }`}
      >
        <div
          className={`bg-white w-4 h-4 rounded-full shadow-md transform ${
            checked ? "translate-x-6" : ""
          } transition-transform`}
        />
      </div>
    </div>
  );
  const editTeamDetails = (e) => {
    e.preventDefault();
    // Logic to update store details via API or state
    // console.log('Updated Store Details:', storeDetails);
    navigate(`/teams/${userObjId}/edit`);
  };

  console.log("userDetails", userDetails);
  return (
    <Layout>
      <Container>
        {loading === true ? (
          <DynamicLoader maintext="wait" subtext="Fetching Team Details" />
        ) : (
          <>
            <div className="flex flex-wrap justify-between w-full h-full">
              <div className="flex flex-col py-2 px-2 md:px-14  w-[100%] h-[100%] overflow-scroll">
                <BackHeader
                  backButton={true}
                  link="/teams"
                  title="Details"
                  rightSide={
                    <button
                      className="bg-slate-500 text-white text-sm md:text-base px-2 md:px-20 py-2 rounded-md"
                      onClick={editTeamDetails}
                    >
                      Edit Details
                    </button>
                  }
                />
                <div className="flex flex-col md:flex-row justify-between w-full mt-10 md:mt-0  ">
                  {/* Left side */}
                  <div className="md:w-1/2 md:p-20 border-r text-sm md:text-base">
                    <p className="text-slate-500 font-semibold text-sm md:text-lg">
                      Information:
                    </p>
                    {/* <div className='flex gap-4'> */}
                    <div className="mt-5">
                      {/* <div className='md:flex gap-4'> */}
                      <div className="w-full">
                        <p className="text-sm text-slate-500">Name :</p>
                        <div className="w-full border bg-white border-slate-200 focus:outline-none px-4 py-2 my-2 rounded-lg">
                          {userDetails.name || "N/A"}
                        </div>
                      </div>
                      <div className="w-full">
                        <p className="text-sm text-slate-500">Phone :</p>
                        <div className="w-full border bg-white border-slate-200 focus:outline-none px-4 py-2 my-2 rounded-lg">
                          {userDetails.phone || "N/A"}
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                    {/* <div className='md:flex gap-4'> */}
                    <div className="w-full">
                      <p className="text-sm text-slate-500">Email :</p>
                      <div className="w-full border bg-white border-slate-200 focus:outline-none px-4 py-2 my-2 rounded-lg">
                        {userDetails.email || "N/A"}
                      </div>
                    </div>
                    <div className="w-full">
                      <p className="text-sm text-slate-500">Role :</p>
                      <div className="w-full border bg-white border-slate-200 focus:outline-none px-4 py-2 my-2 rounded-lg">
                        {userDetails.role || "Select Role"}
                      </div>
                    </div>
                  </div>

                  {/* </div> */}
                  {/* Right Side */}

                  <div className="md:w-1/2  md:p-20 rounded text-sm md:text-lg">
                    <p className="text-slate-500 py-2 font-semibold">
                      Permissions:
                    </p>
                    <div className="flex flex-col gap-5">
                      {/* <div className='flex gap-4'> */}
                      <div className="w-full">
                        <Toggle name="stores" checked={userDetails.stores} />
                      </div>
                      <div className="w-full">
                        <Toggle
                          name="products"
                          checked={userDetails.products}
                        />
                      </div>
                      {/* </div>
                    <div className='flex gap-4'> */}
                      <div className="w-full">
                        <Toggle name="orders" checked={userDetails.orders} />
                      </div>
                      <div className="w-full">
                        <Toggle
                          name="settings"
                          checked={userDetails.settings}
                        />
                      </div>
                      {/* </div>
                    <div className='flex gap-4'> */}
                      <div className="w-full ">
                        <Toggle name="reports" checked={userDetails.reports} />
                      </div>
                      <div className="w-full ">
                        <Toggle name="users" checked={userDetails.users} />
                      </div>
                      {/* </div>
                    <div className='flex gap-4'> */}
                      <div className="w-full">
                        <Toggle
                          name="analytics"
                          checked={userDetails.analytics}
                        />
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Container>
    </Layout>
  );
}

export default TeamDetails;
